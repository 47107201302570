@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800&display=swap');

@font-face {
    font-family: 'PoppinsFont';
    src: url('../src/assets/fonts/Poppins/Poppins-Regular.ttf');
}

:root {
    --blackBg: #131721;
    --blackPearl: #1d212d;
    --blackPearlTwo: #2f3651;
    --brightGreen: #4ac600;
    --grayBg: #d9d9d9;
    --lightGray: #cecece;
    --white: #ffffff;
    --whitishGray: #f0f1f3;
    --darkGrayTwo: #7d7d7d;
    --blackFont: #1c1c1c;
    --blueFont: #007aff;
    --lightgrayfour: #3c4769;
    --darkGray: #7d7d7d;
    --orange: #f96a32;
    --red: #e51a32;
    --purple: #805cf4;
    --green: #048900;
    --seperatorBg: #414143;
    --seperatorTwo: #3f4659;
    --posRightBg: #252a3d;
    --barclaysBlue: #00aeef;
    --mediumBlue: #e9f6fc;
    --seaGreen: #4db6ac;
    --mustardyellow: #f2bc1d;
    --extraLightGray: #f5f5f7;
    --lightGrayTwo: #a4a4a4;
    --topBarBG: #1b1f2b;
    --highlight: #f7fbfa;
    --outfitFont: 'Outfit', sans-serif;
    --PoppinsFont: 'Poppins', sans-serif;
    --inputBorder: #2f3651;
    --inputText: #91939b;
    --tableBorder: #191f2d;
    --borderColor: #33435c;
    --brightOrange: #f96a32;
    --darkSlateBlue: #232c42;
    --midnightBlue: #161b27;
    --newBorderColor: #3d4253;
    --newBorderColorTwo: #868993;
    --lightGreenNew: #ebf9e3;
    --lightGrayThree: #6e717e;
    --grayBgTwo: #555968;
    --newBg: #1d212f;
    --newposRightBg: #1f2432;
    --midGray: #676a72;
    --lightRed: #f9eced;
    --lightOrange: #ffe7dc;
    --lightGreen: #e3f2e3;
    --darkgrayBorderbg: #232736;
    --lightPurple: #e3dbfd;
    --textgray: #667297;
    --darkSlateBlue2: #161b28;
    --darkBluishGray: #232939;
    --brown: #b97a69;
    --iconBlue: #44a0fc;
    --iconGreen: #36aa71;
    --iconBrown: #a6878a;
    --greenshadeNew: #d1fab3;
    --peach: #ffe6c1;
    --blueOne: #005bea;
    --blueTwo: #00c6fb;
    --lightGreenTwo: #f1fcd2;
    --grayNew: #414042;
    --nonIdealText: #555968;
    --extralightYellow: #fff9e9;
    --blueGray: #1a2236;
    --mediumGray: #565656;
    --plainBlack: #000000;
    --textColor:#414143;
}

html,
body {
    height: 100%;
}

#root {
    height: 100%;
    background-color: var(--blackBg);
}

body {
    margin: 0;
    font-family: var(--outfitFont);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-y: hidden;
    scrollbar-width: 5px;
    scrollbar-color: var(--darkGrayTwo) var(--blackBg);
}

.body {
    --scrollbarBG: var(--blackBg);
    --thumbBG: var(--darkGrayTwo);
}

body::-webkit-scrollbar {
    width: 5px;
}

body::-webkit-scrollbar-track {
    background: var(--blackBg);
}

body::-webkit-scrollbar-thumb {
    background-color: var(--blackBg);
    border-radius: 6px;
    border: 3px solid var(--darkGrayTwo);
}

code {
    font-family: var(--outfitFont);
}

.poppinsFont {
    font-family: var(--PoppinsFont) !important;
}

/* Basic Style */
/* ================ Font Sizes ================ */
.fontSize9 {
    font-size: 9px;
}

.fontSize10 {
    font-size: 10px;
}

.fontSize12 {
    font-size: 12px;
}

.fontSize14 {
    font-size: 14px;
}

.fontSize15 {
    font-size: 15px;
}

.fontSize16 {
    font-size: 16px;
}

.fontSize18 {
    font-size: 18px;
}

.fontSize20 {
    font-size: 20px;
}

.fontSize22 {
    font-size: 22px;
}

.fontSize24 {
    font-size: 24px;
}

.fontSize26 {
    font-size: 26px;
}

.fontSize28 {
    font-size: 28px;
}

.fontSize30 {
    font-size: 30px;
}

.fontSize32 {
    font-size: 32px;
}

.fontSize34 {
    font-size: 34px;
}

.fontSize36 {
    font-size: 36px;
}

.fontSize38 {
    font-size: 38px;
}

.fontSize40 {
    font-size: 40px;
}

.fontSize42 {
    font-size: 42px;
}

.fontSize44 {
    font-size: 44px;
}

.fontSize46 {
    font-size: 46px;
}

.fontSize48 {
    font-size: 48px;
}

.fontSize50 {
    font-size: 50px;
}

.fontSize52 {
    font-size: 52px;
}

.fontSize54 {
    font-size: 54px;
}

.fontSize56 {
    font-size: 56px;
}

.fontSize58 {
    font-size: 58px;
}

.fontSize60 {
    font-size: 60px;
}

.fontSize62 {
    font-size: 62px;
}

.fontSize64 {
    font-size: 64px;
}

.fontSize68 {
    font-size: 68px;
}

.fontSize70 {
    font-size: 70px;
}

.fontSize72 {
    font-size: 72px;
}

.fontSize74 {
    font-size: 74px;
}

.fontSize76 {
    font-size: 76px;
}

.fontSize78 {
    font-size: 78px;
}

.fontSize80 {
    font-size: 80px;
}

.fontSize82 {
    font-size: 82px;
}

.fontSize84 {
    font-size: 84px;
}

/* ================ Font Sizes ================ */
/* ================ Font Weight ================ */
.fontWeight100 {
    font-weight: 100;
}

.fontWeight200 {
    font-weight: 200;
}

.fontWeight300 {
    font-weight: 300;
}

.fontWeight400 {
    font-weight: 400;
}

.fontWeight500 {
    font-weight: 500;
}

.fontWeight600 {
    font-weight: 600;
}

.fontWeight700 {
    font-weight: 700;
}

/* ================ Font Weight ================ */

/* ================ Text Decoration ================ */
.textUnderline {
    text-decoration: underline;
}

.textUnderlineNo {
    text-decoration: none;
}

/* ================ Text Decoration ================ */

/* ================ Text Center ================ */
.textCenter {
    text-align: center;
}

.textLeft {
    text-align: left;
}

.textRight {
    text-align: right;
}

.hide {
    visibility: hidden;
}
.min-width {
    width: min-content;
}
.max-width {
    width: max-content;
}

.flex {
    display: flex;
}

.flexwrap {
    flex-wrap: wrap;
}

.verticalCenter {
    align-items: center;
}

.horizontalCenter {
    justify-content: center;
}

.spaceBetweenCenter {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* ================ Text Center ================ */

/* ================ Color Theme ================ */
.mainColorRGB {
    color: var(--mainColor-rgb);
}

.orangeShade {
    color: var(--orangeShade);
}

.greenShade {
    color: var(--greenShade);
}

.greenshadeNew {
    color: var(--greenshadeNew);
}

.mainColor {
    color: var(--mainColor);
}

.separatorGray {
    color: var(--seperatorBg);
}

.orangeShade {
    color: var(--orangeShade);
}

.orange {
    color: var(--orange);
}

.greenShade {
    color: var(--greenShade);
}

.green {
    color: var(--green);
}

.mainColor {
    color: var(--mainColor);
}

.midNightBlue {
    color: var(--posRightBg);
}

.brightPurple {
    color: var(--brightPurple);
}

.extraBrightPurple {
    color: var(--extraBrightPurple);
}

.red {
    color: var(--red);
}

.skyBlue {
    color: var(--skyBlue);
}

.blueShade {
    color: var(--blueShade);
}

.mustardYellow {
    color: var(--mustardyellow);
}

.mustardYellowShade {
    color: var(--mustardYellowShade);
}

.extraLightGray {
    color: var(--extraLightGray);
}

.lightGray {
    color: var(--lightGray);
}

.lightGrayTwo {
    color: var(--lightGrayTwo);
}

.mediumGray {
    color: var(--mediumGray);
}

.darkGrayOld {
    color: var(--darkGrayOld);
}

.darkGray {
    color: var(--darkGray);
}

.darkGrayTwo {
    color: var(--darkGrayTwo);
}

.darkGreen {
    color: var(--darkGreen);
}

.darkOrange {
    color: var(--darkOrange);
}

.brightGreen {
    color: var(--brightGreen);
}

.brightOrange {
    color: var(--brightOrange);
}

.white {
    color: var(--white);
}

.backgroundShade {
    color: var(--backgroundShade);
}

.borderColor {
    color: var(--borderColor);
}

.bundlesBgColor {
    color: var(--bundlesBg);
}

.flashsaleBgColor {
    color: var(--flashsaleBg);
}

.newarrivalBgColor {
    color: var(--newarrivalBg);
}

.barclaysBlueColor {
    color: var(--barclaysBlue);
}

.blackColor {
    color: var(--black);
}

.grayishColor {
    color: var(--grayIcon);
}

.graybgColor {
    color: var(--grayBG);
}

.extraBrightRed {
    color: var(--extraBrightRed);
}

.lightRed {
    color: var(--lightRed);
}

.lightOrange {
    color: var(--lightOrange);
}

.lightGreen {
    color: var(--lightGreen);
}

.lightBlue {
    color: var(--lightBlue);
}

.bluishShade {
    color: var(--bluishShade);
}

.lightPurpleColor {
    color: var(--purple);
}

.lightGreenTwo {
    color: var(--lightGreenTwo);
}

.grayNew {
    color: var(--grayNew);
}

.blackpearlTwo {
    color: var(--blackpearlTwo);
}

.nonIdealText {
    color: var(--nonIdealText);
}

/* ================ Color Theme ================ */

/* ================ Background Color ================ */
.mainColorRGBBg {
    background: var(--mainColor-rgb);
}

.orangeShadeBg {
    background: var(--orangeShade);
}

.greenShadeBg {
    background: var(--greenShade);
}

.mainColorBg {
    background: var(--mainColor);
}

.brightPurpleBg {
    background: var(--brightPurple);
}

.extraBrightPurpleBg {
    background: var(--extraBrightPurple);
}

.lightPurpleBg {
    background: var(--lightPurple);
}

.purpleBg {
    background: var(--purple);
}

.redBg {
    background: var(--red);
}

.skyBlueBg {
    background: var(--skyBlue);
}

.blueShadeBg {
    background: var(--blueShade);
}

.mustardYellowBg {
    background: var(--mustardYellow);
}

.mustardYellowShadeBg {
    background: var(--mustardYellowShade);
}

.extraLightGrayBg {
    background: var(--extraLightGray);
}

.lightGrayBg {
    background: var(--lightGray);
}

.lightGrayTwoBg {
    background: var(--lightGrayTwo);
}

.mediumGrayBg {
    background: var(--mediumGray);
}

.darkGrayBg {
    background: var(--darkGray);
}

.darkGrayTwoBg {
    background: var(--darkGrayTwo);
}

.darkGreenBg {
    background: var(--darkGreen);
}

.darkOrangeBg {
    background: var(--darkOrange);
}

.lightGreenBg {
    background: var(--lightGreen);
}

.brightGreenBg {
    background: var(--brightGreen);
}

.brightOrangeBg {
    background: var(--brightOrange);
}

.whiteBg {
    background: var(--white);
}

.backgroundShadeBg {
    background: var(--backgroundShade);
}

.borderColorBg {
    background: var(--borderColor);
}

.bundlesBgBg {
    background: var(--bundlesBg);
}

.flashsaleBgBg {
    background: var(--flashsaleBg);
}

.newarrivalBgBg {
    background: var(--newarrivalBg);
}

.barclaysBlueBg {
    background: var(--barclaysBlue);
}

.blackBg {
    background: var(--black);
}

.grayishBg {
    background: var(--grayIcon);
}

.graybgBg {
    background: var(--grayBG);
}

.extraBrightRedBg {
    background: var(--extraBrightRed);
}

.lightRedBg {
    background: var(--lightRed);
}

.lightOrangeBg {
    background: var(--lightOrange);
}

.lightBlueBg {
    background: var(--lightBlue);
}

.bluishShadeBg {
    background: var(--bluishShade);
}

.transparentBg {
    background: 'transparent';
}

.lightGrayThreeBg {
    background: var(--lightGrayThree);
}

.greenBg {
    background: var(--green);
}

.redBg {
    background: var(--red);
}

.orangeBg {
    background: var(--orange);
}

.transparentBg {
    background: 'transparent';
}

.lightGreenTwoBg {
    background: var(--lightGreenTwo);
}

.nonIdealTextBg {
    background: var(--nonIdealText);
}

/* ================ Background Color ================ */

/* ================ Border Radius ================ */
.borderRadius5 {
    border-radius: 5px;
}

.borderRadius10 {
    border-radius: 10px;
}

.borderRadius15 {
    border-radius: 15px;
}

.borderRadius20 {
    border-radius: 20px;
}

.borderRadius25 {
    border-radius: 25px;
}

.borderRadius30 {
    border-radius: 30px;
}

.borderRadius50Percent {
    border-radius: 50%;
}

.borderRadiusLeftTopBottom {
    border-radius: 12px 0 0 12px;
}

.borderRadiusTopleftTopRigt {
    border-radius: 10px 10px 0 0px;
}

.borderRadiusBottomleftBottomRigt {
    border-radius: 0 0 10px 10px;
}

.borderRadiusRightTopBottom {
    border-radius: 0px 12px 12px 0px;
}

.noborder {
    border: 0px !important;
}

.garyBorderBottom {
    border-bottom: 1px solid var(--inputBorder);
}

/* ================ Border Radius ================ */

/* ================ Width ================ */
.width100 {
    width: 100%;
}

.width95 {
    width: 95%;
}

.width90 {
    width: 90%;
}

.width85 {
    width: 85%;
}

.width80 {
    width: 80%;
}

.width75 {
    width: 75%;
}

.width70 {
    width: 70%;
}

.width65 {
    width: 65%;
}

.width60 {
    width: 60%;
}

.width65 {
    width: 65%;
}

.width60 {
    width: 60%;
}

.width55 {
    width: 55%;
}

.width50 {
    width: 50%;
}

.width45 {
    width: 45%;
}

.width40 {
    width: 40%;
}

.width35 {
    width: 35%;
}

.width30 {
    width: 30%;
}

.width28 {
    width: 28%;
}

.width25 {
    width: 25%;
}

.width20 {
    width: 20%;
}

.width15 {
    width: 15%;
}

.width10 {
    width: 10%;
}

.normalWidth {
    min-width: 140px;
}

/* ================ Width ================ */

/* ================ Cursor Pointer ================ */
.cursorPointer {
    cursor: pointer;
}

.cursorDefault {
    cursor: default;
}

.cursorDisable {
    cursor: not-allowed;
}

/* ================ Cursor Pointer ================ */

/* ================ Margin Top ================ */
.marTop0 {
    margin-top: 0px;
}

.marTop2 {
    margin-top: 2px;
}

.marTop3 {
    margin-top: 3px;
}

.marTop4 {
    margin-top: 4px;
}

.marTop5 {
    margin-top: 5px;
}

.marTop10 {
    margin-top: 10px;
}

.marTop15 {
    margin-top: 15px;
}

.marTop20 {
    margin-top: 20px;
}

.marTop25 {
    margin-top: 25px;
}

.marTop30 {
    margin-top: 30px;
}

.marTop35 {
    margin-top: 35px;
}

.marTop40 {
    margin-top: 40px;
}

.marTop45 {
    margin-top: 45px;
}

.marTop50 {
    margin-top: 50px;
}

.marTop55 {
    margin-top: 55px;
}

.marTop60 {
    margin-top: 60px;
}

.marTop65 {
    margin-top: 65px;
}

.marTop70 {
    margin-top: 70px;
}

.marTop75 {
    margin-top: 75px;
}

.marTop80 {
    margin-top: 80px;
}

/* ================ Margin Top ================ */

/* ================ Margin Bottom ================ */
.marBot0 {
    margin-bottom: 0px;
}

.marBot5 {
    margin-bottom: 5px;
}

.marBot6 {
    margin-bottom: 6px;
}

.marBot7 {
    margin-bottom: 7px;
}

.marBot8 {
    margin-bottom: 8px;
}

.marBot9 {
    margin-bottom: 9px;
}

.marBot10 {
    margin-bottom: 10px;
}

.marBot15 {
    margin-bottom: 15px;
}

.marBot20 {
    margin-bottom: 20px;
}

.marBot25 {
    margin-bottom: 25px;
}

.marBot30 {
    margin-bottom: 30px;
}

.marBot35 {
    margin-bottom: 35px;
}

.marBot40 {
    margin-bottom: 40px;
}

.marBot45 {
    margin-bottom: 45px;
}

.marBot50 {
    margin-bottom: 50px;
}

.marBot55 {
    margin-bottom: 55px;
}

.marBot60 {
    margin-bottom: 60px;
}

.marBot65 {
    margin-bottom: 65px;
}

.marBot70 {
    margin-bottom: 70px;
}

.marBot75 {
    margin-bottom: 75px;
}

.marBot80 {
    margin-bottom: 80px;
}

/* ================ Margin Bottom ================ */

/* ================ Margin Left ================ */
.marLeft0 {
    margin-left: 0px;
}

.marLeft5 {
    margin-left: 5px;
}

.marLeft10 {
    margin-left: 10px;
}

.marLeft15 {
    margin-left: 15px;
}

.marLeft20 {
    margin-left: 20px;
}

.marLeft25 {
    margin-left: 25px;
}

.marLeft30 {
    margin-left: 30px;
}

.marLeft35 {
    margin-left: 35px;
}

.marLeft40 {
    margin-left: 40px;
}

.marLeft45 {
    margin-left: 45px;
}

.marLeft50 {
    margin-left: 50px;
}

/* ================ Margin Left ================ */

/* ================ Margin Right ================ */
.marRight0 {
    margin-right: 0px;
}

.marRight5 {
    margin-right: 5px;
}

.marRight10 {
    margin-right: 10px;
}

.marRight15 {
    margin-right: 15px;
}

.marRight20 {
    margin-right: 20px;
}

.marRight25 {
    margin-right: 25px;
}

.marRight30 {
    margin-right: 30px;
}

.marRight35 {
    margin-right: 35px;
}

.marRight40 {
    margin-right: 40px;
}

.marRight45 {
    margin-right: 45px;
}

.marRight50 {
    margin-right: 50px;
}

/* ================ Margin Right ================ */

/* ================ Gap ================ */

.gap5 {
    gap: 5px;
}

.gap10 {
    gap: 10px;
}

.gap15 {
    gap: 15px;
}

.gap20 {
    gap: 20px;
}

.gap30 {
    gap: 30px;
}

.gap40 {
    gap: 40px;
}

.gap50 {
    gap: 50px;
}

/* ================ Gap ================ */

/* Blur */
.blurred {
    filter: blur(0.5px);
}

/* Blur */

/* ================ Text Truncate ================ */
.OneLineTruncate {
    display: -webkit-box;
    /* max-width: 50%; */
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
    white-space: initial;
}

.twoLineTruncate {
    display: -webkit-box;
    /* max-width: 50%; */
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
    white-space: initial;
}

.threeLineTruncate {
    display: -webkit-box;
    /* max-width: 50%; */
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
    white-space: initial;
}

.fourLineTruncate {
    display: -webkit-box;
    /* max-width: 50%; */
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
    white-space: initial;
}

/* ================ Text Truncate ================ */

/* Animation */

.rotating {
    animation: rotate 2s linear infinite;
}

.paused {
    animation-play-state: paused;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

/* Animation */

/* ================ Divider ================ */

.divider {
    border: 1px solid var(--tableBorder);
}

.thinDivider {
    border-bottom: 1px solid var(--posRightBg);
}

/* ================ Divider ================ */

/* ================ Input Style ================ */

/* Hide the increment and decrement arrows in Webkit browsers (Chrome, Safari) */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Hide the increment and decrement arrows in Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
    border: none !important;
    transition: background-color 0s 9999s;
    font-size: 16px !important;
    -webkit-text-fill-color: #ffff;
    caret-color: #ffff;
}

/* Autofill styles for Firefox */
input:-moz-autofill,
textarea:-moz-autofill,
select:-moz-autofill {
    border: none !important;
    font-size: 16px !important;
    -webkit-text-fill-color: #ffff;
}

.form-group {}

.formGroupInner {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    margin: 5px 0px;
}

/* .formGroupWithprefix .MuiInputLabel-filled {
  padding-left: 40px;
} */

/* .formGroupWithprefix .MuiFilledInput-input {
  padding-left: 40px;
}

.formGroupWithprefix .MuiFilledInput-input {
  padding-left: 50px !important;
} */

/* Paginated scrollbar */
.infiniteScrollBar {
    min-height: calc(100vh - 290px);
}

#scrollableDiv::-webkit-scrollbar {
    width: 5px;
}

#scrollableDiv::-webkit-scrollbar-track {
    background-color: var(--posRightBg);
    border-radius: 6px;
}

#scrollableDiv::-webkit-scrollbar-thumb {
    background-color: var(--white);
    border-radius: 6px;
    border: 3px solid var(--white);
}

/* Paginated scrollbar */

.prefixIcon {
    position: absolute;
    left: 12px;
    cursor: pointer;
    z-index: 9;
}

.sufixIcon {
    z-index: 99;
    cursor: pointer;
}

.formGroupInner .form-control {
    box-shadow: none;
    background: transparent;
    font-size: 18px;
    color: var(--darkGray);
    border: none;
    padding: 12px 15px 12px 40px;
    border-radius: 0;
}

.formGroupInner .form-control::placeholder {
    color: var(--lightGray);
}

.inputError {
    color: var(--red);
    margin: 5px 0 0;
    font-size: 12px;
    min-height: 18px;
}

.formGroupInner .MuiFormControl-root {
    width: 100%;
}

.formGroupInner .MuiFilledInput-notchedOutline {
    border-color: var(--extraLightGray);
    border-radius: 10px;
}

.formError .formGroupInner .MuiFilledInput-notchedOutline {
    border-color: var(--red);
}

.formGroupInner .MuiFilledInput-input,
.formGroupInner .MuiOutlinedInput-input {
    padding: 17px 15px 0px 15px;
    font-size: 14px;
    color: var(--white);
    text-transform: none;
}

/* .formGroupInner .MuiFilledInput-input.MuiInputBase-inputMultiline,
.formGroupInner .MuiOutlinedInput-input.MuiInputBase-inputMultiline {
    padding: 20px 15px 0px 15px !important;
} */

.formGroupInner .MuiInputBase-inputMultiline {
    /* text-transform: capitalize; */
}

.formGroupInner .MuiFilledInput-root.MuiInputBase-multiline {
    padding: 0;
}

.formError .formGroupInner .MuiFilledInput-root.Mui-focused .MuiFilledInput-notchedOutline,
.formError .formGroupInner .MuiFilledInput-root.Mui-focused:hover .MuiFilledInput-notchedOutline {
    border-color: var(--red);
}

.formGroupInner .MuiFilledInput-root.Mui-focused .MuiFilledInput-notchedOutline,
.formGroupInner .MuiFilledInput-root.Mui-focused:hover .MuiFilledInput-notchedOutline {
    border-color: var(--lightBlue);
    border: none;
}

/* .css-10botns-MuiInputBase-input-MuiFilledInput-input.Mui-disabled,
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled,
.css-1gctnaj-MuiInputBase-input-MuiFilledInput-input.Mui-disabled {
    -webkit-text-fill-color: var(--white) !important;
} */

.MuiInputBase-input.MuiFilledInput-input.Mui-disabled,
.Mui-disabled.MuiInputBase-inputAdornedEnd,
.Mui-disabled.MuiInputBase-inputMultiline {
    -webkit-text-fill-color: var(--white) !important;
}

.formGroupInner .MuiFilledInput-root:hover .MuiFilledInput-notchedfilled {
    border-color: var(--inputBorder);
    border: none;
}

.formGroupInner .MuiFilledInput-underline::before,
.formGroupInner .MuiFilledInput-underline::after {
    display: none;
}

.formError .formGroupInner .MuiFilledInput-root:hover .MuiFilledInput-notchedfilled {
    border-color: var(--red);
}

.formGroupInner .MuiInputLabel-filled,
.formGroupInner .MuiInputLabel-outlined {
    transform: translate(15px, 20px) scale(1);
}

.formGroupInner .MuiInputLabel-filled.MuiInputLabel-shrink,
.formGroupInner .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(15px, 10px) scale(0.8);
    font-size: 14px;
}

.formGroupInner .prefixIcon {
    position: absolute;
    left: 15px;
    top: 15px;
    -moz-user-select: none;
}

.formGroupInner .MuiFormLabel-root {
    font-size: 14px;
    font-family: var(--outfitFont);
}

.formGroupInner .MuiFormLabel-root .MuiInputLabel-filled.MuiInputLabel-shrink {
    font-size: 13px;
}

.formGroupInner .MuiInputLabel-filled,
.formGroupInner .MuiInputLabel-outlined {
    color: var(--inputText);
}

.formGroupInner .MuiAutocomplete-root .MuiInputLabel-filled {
    color: var(--white);
}

.formGroupInner .MuiInputLabel-shrink.MuiFormLabel-filled,
.formGroupInner .MuiInputLabel-shrink.MuiFormLabel-outlined {
    color: var(--darkGray) !important;
}

.formGroupInner .MuiFormLabel-asterisk {
    color: var(--red) !important;
}

.formGroupInner .MuiFormLabel-root.Mui-focused {
    color: var(--darkGray);
}

.formGroupInner .MuiFilledInput-root.Mui-disabled .MuiFilledInput-notchedfilled,
.formGroupInner .MuiFilledInput-root.Mui-disabled .MuiFilledInput-notchedoutlind {
    border-color: var(--bgnewColor);
    background: var(--bgnewColor);
    z-index: -1;
}

.formGroupInner .PrivateNotchedOutline-root-1 {
    z-index: -1;
}

.errorField .formGroupInner .MuiFilledInput-root,
.errorField .formGroupInner .MuiOutlinedInput-root {
    border: 1px solid var(--red) !important;
    transition: border 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.formGroupInner .MuiFilledInput-root,
.formGroupInner .MuiOutlinedInput-root {
    min-height: 60px;
    background-color: var(--blackPearl);
    border-radius: 12px;
    border: 1px solid var(--inputBorder);
    transition: border 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.formGroupInner .MuiOutlinedInput-root {
    height: 60px;
}

.formGroupInner .MuiFilledInput-root.Mui-focused,
.formGroupInner .MuiOutlinedInput-root.Mui-focused {
    background-color: var(--blackPearl);
    border: 1px solid var(--barclaysBlue);
}

.formGroupInner .MuiFilledInput-root:hover,
.formGroupInner .MuiOutlinedInput-root:hover {
    background-color: var(--blackPearl);
}

.datePickerBox .MuiInputBase-root,
.MuiOutlinedInput-root {
    -webkit-text-fill-color: var(--white) !important;
}

.datePickerBox .Mui-disabled:hover {
    background-color: var(--seperatorTwo) !important;
}

.formGroupInner .MuiFilledInput-root.Mui-disabled,
.formGroupInner .MuioutlinedInput-root.Mui-disabled {
    min-height: 60px;
    background-color: var(--seperatorTwo);
    opacity: 0.8;
}

.datePickerBox .Mui-disabled {
    background-color: var(--seperatorTwo);
}

.Mui-disabled {
    color: var(--inputText) !important;
}

.formGroupInner .MuiAutocomplete-root {
    width: 100%;
}

.formGroupInner .MuiAutocomplete-popupIndicator {
    right: 0px;
    top: -1px;
}

.formGroupInner .MuiAutocomplete-inputRoot[class*='MuiFilledInput-root'] .MuiAutocomplete-input {
    padding: 17px 15px 0px 15px !important;
}

.formGroupInner .MuiAutocomplete-popupIndicator .MuiIconButton-label svg path,
.formGroupInner .MuiAutocomplete-endAdornment .MuiAutocomplete-popupIndicator svg path {
    /* display: none; */
}

.MuiAutocomplete-popper {
    margin-top: 10px !important;
}

.MuiAutocomplete-popper .MuiAutocomplete-paper {
    border-radius: 12px;
    /* margin-left: 1.2px; */
    border: 1px solid var(--inputBorder);
    margin-left: 0;
    width: 100%;
}

.MuiAutocomplete-popper {
    margin-top: 3px;
}

.MuiAutocomplete-popper .MuiAutocomplete-listbox {
    background-color: var(--blackPearl);
    /* border: 1px solid var(--inputBorder); */
    border-radius: 0px;
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.01) !important;
    font-size: 14px;
    color: var(--white);
    padding: 15px;
}

.MuiAutocomplete-popper .MuiAutocomplete-option {
    font-family: var(--outfitFont) !important;
    background-color: var(--blackPearl);
    margin-bottom: 5px;
    padding: 10px 10px;
}

.MuiAutocomplete-popper .MuiAutocomplete-noOptions {
    font-family: var(--outfitFont) !important;
    background-color: var(--blackPearl);
    margin-bottom: 5px;
    padding: 17.5px 30px;
    margin-top: 3px;
    /* margin-bottom: 5px; */
    color: var(--white);
    font-size: 14px;
}

.MuiPaper-elevation.MuiPaper-rounded {
    /* background: var(--red); */
    background-color: var(--blackPearl);
    /* border: 1px solid var(--inputBorder); */
}

.MuiAutocomplete-popper .MuiAutocomplete-option:last-child {
    margin-bottom: 0px;
}

.MuiAutocomplete-popper .MuiAutocomplete-option:hover {
    background-color: #272d40 !important;
    color: var(--white);
    border-radius: 5px;
}

.MuiAutocomplete-popper .MuiAutocomplete-option[data-focus='true'] {
    background-color: #272d40 !important;
    border-radius: 5px;
}

.MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true'] {
    background-color: #272d40 !important;
    color: var(--white);
    border-radius: 5px;
}

.MuiInputBase-root {
    font-family: var(--outfitFont) !important;
}

.MuiAutocomplete-popper .MuiAutocomplete-listbox::-webkit-scrollbar {
    width: 5px;
}

.MuiAutocomplete-popper .MuiAutocomplete-listbox::-webkit-scrollbar-track {
    background: var(--blackBg);
}

.MuiAutocomplete-popper .MuiAutocomplete-listbox::-webkit-scrollbar-thumb {
    background-color: var(--blackBg);
    border-radius: 6px;
    border: 3px solid var(--darkGrayTwo);
}

.formGroupInner textarea.MuiFilledInput-input.MuiInputBase-inputMultiline {
    height: 126px;
}

.formGroupInner .MuiAutocomplete-inputRoot[class*='MuiFilledInput-root'] {
    padding-top: 0;
    padding-left: 0;
}

.formGroupInner .MuiOutlinedInput-root fieldset.MuiOutlinedInput-notchedOutline {
    display: none;
}

.DropdowcustomSelector .MuiAutocomplete-popper .MuiAutocomplete-option:first-child {
    color: var(--barclaysBlue);
}

.DropdowcustomSelector .MuiAutocomplete-popper .MuiAutocomplete-option:first-child:hover {
    color: var(--blueFont);
}

/* ================ Input Style ================ */

/* ================ Select Input Style ================ */
.formGroupInner .MuiInputBase-root.MuiFilledInput-root.MuiInputBase-formControl .MuiSelect-icon {
    display: none;
}

.formGroupInner .sufixIcon {
    position: relative;
    left: 0px;
    -moz-user-select: none;
}

.formGroupInner .MuiSelect-select:focus {
    background: transparent;
}

.formGroupInner .MuiSelect-select option:hover {
    background-color: var(--red);
}

.formGroupInner select option {
    background: var(--red);
}

.MuiSelect-select:not([multiple]) option,
.MuiSelect-select:not([multiple]) optgroup {}

Select.testDropdown option,
Select.testDropdown optgroup {
    border-radius: 15px;
    background: red;
}

/* ================ Select Input Style ================ */

/* Check Box Styling */
.checkBoxMainBox {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 12px;
    margin-bottom: 25px;
}

.checkBoxMainBox label {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.checkBoxMainBox label p {
    margin-left: 15px;
}

.itemlistCheckBox {
    margin-top: 0;
    margin-bottom: 0;
}

.itemlistCheckBox input[type='checkbox'] {
    min-height: 20px;
    min-width: 20px;
}

.bgColor input {
    background-color: var(--barclaysBlue) !important;
}

.bgTransparent input {
    background-color: transparent !important;
}

.form-control--disabled {
    color: var(--form-control-disabled);
    cursor: not-allowed;
}

input[type='checkbox'] {
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    margin: 0;
    font: inherit;
    width: 20px;
    height: 20px;
    border: 1px solid var(--borderColor);
    border-radius: 5px;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
    cursor: pointer;
    outline: none;
}

input[type='checkbox']::before {
    content: '';
    width: 14px;
    height: 10px;
    clip-path: polygon(calc(50% - -6.5px) calc(50% - 4.6px),
            calc(50% - -6.5px) calc(50% - 4.6px),
            calc(50% - -6.5px) calc(50% - 4.6px),
            calc(50% - -6.3444px) calc(50% - 4.735px),
            calc(50% - -6.1792px) calc(50% - 4.84px),
            calc(50% - -6.0068px) calc(50% - 4.915px),
            calc(50% - -5.8296px) calc(50% - 4.96px),
            calc(50% - -5.65px) calc(50% - 4.975px),
            calc(50% - -5.4704px) calc(50% - 4.96px),
            calc(50% - -5.2932px) calc(50% - 4.915px),
            calc(50% - -5.1208px) calc(50% - 4.84px),
            calc(50% - -4.9556px) calc(50% - 4.735px),
            calc(50% - -4.8px) calc(50% - 4.6px),
            5.2px 7px,
            2.1px 3.9px,
            2.1px 3.9px,
            1.9444px 3.765px,
            1.7792px 3.66px,
            1.6068px 3.585px,
            1.4296px 3.54px,
            1.25px 3.525px,
            1.0704px 3.54px,
            0.8932px 3.585px,
            0.7208px 3.66px,
            0.5556px 3.765px,
            0.4px 3.9px,
            0.4px 3.9px,
            0.4px 3.9px,
            0.265px 4.0556px,
            0.16px 4.2208px,
            0.085px 4.3932px,
            0.04px 4.5704px,
            0.025px 4.75px,
            0.04px 4.9296px,
            0.085px 5.1068px,
            0.16px 5.2792px,
            0.265px 5.4444px,
            0.4px 5.6px,
            4.4px 9.6px,
            4.4px 9.6px,
            4.5556px 9.735px,
            4.7208px 9.84px,
            4.8932px 9.915px,
            5.0704px 9.96px,
            5.25px 9.975px,
            5.4296px 9.96px,
            5.6068px 9.915px,
            5.7792px 9.84px,
            5.9444px 9.735px,
            6.1px 9.6px,
            13.6px 2.1px,
            13.6px 2.1px,
            13.7079px 1.9444px,
            13.7912px 1.7792px,
            13.8493px 1.6068px,
            13.8816px 1.4296px,
            13.8875px 1.25px,
            13.8664px 1.0704px,
            13.8177px 0.8932px,
            13.7408px 0.7208px,
            13.6351px 0.5556px,
            13.5px 0.4px);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em #33435c;
    background-color: #33435c;
}

input[type='checkbox']:checked::before {
    background-color: var(--barclaysBlue);
    transform: scale(1);
    position: relative;
    left: 0px;
    top: 0px;
}

input[type='checkbox']:disabled {
    --form-control-color: var(--form-control-disabled);
    color: var(--form-control-disabled);
    cursor: not-allowed;
}

/* Check Box Styling */

/* Basic Style */

/* Button Basic Style */
.buttonStyle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: 1px solid;
    border-color: transparent;
    border-radius: 10px;
    height: 52px;
    box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.07);
    -webkit-transition: background 1s;
    transition: background 1s;
    outline: none;
}

.borderButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 48px;
    border: 1px solid;
    border-radius: 12px;
    box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.07);
    outline: none;
}

.buttonVertical {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 48px;
    border: 1px solid;
    border-color: transparent;
    border-radius: 12px;
    box-shadow: none;
}

.verticalButtonIcon {
    width: 32px;
    height: 32px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    margin-bottom: 5px;
}

.verticalButtonIcon svg {
    position: relative;
    top: -1px;
    left: 1px;
}

.countBox {
    background: var(--red);
    margin: 0;
    width: 18px;
    height: 18px;
    border-radius: 9px;
    font-size: 11px;
    color: var(--white);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.buttonIcon {
    position: relative;
    top: -2px;
}

.buttonIconStyle {
    background: transparent;
    padding: 0 0;
    margin: 0;
    border: none;
    outline: none;
    height: 25px;
    box-shadow: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: auto;
    margin: 0 0 0 15px;
}

/* Button Basic Style */

/* Height */
.height10 {
    height: 10px;
}

.height12 {
    height: 12px;
}

.height14 {
    height: 14px;
}

.height16 {
    height: 16px;
}

.height18 {
    height: 18px;
}

.height20 {
    height: 20px;
}

.height22 {
    height: 22px;
}

.height24 {
    height: 24px;
}

.height26 {
    height: 26px;
}

.height28 {
    height: 28px;
}

.height30 {
    height: 30px;
}

.height35 {
    height: 35px;
}

.height40 {
    height: 40px;
}

/* Height */

/* Back Button Box */
.backBox {
    position: absolute;
    top: 0;
    left: 0;
    height: 75px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0 10px;
    z-index: 9;
}

.backInnerBox {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: auto;
}

.backInnerBox.skipInnerBox {
    justify-content: flex-end;
}

.backBoxIcon {
    position: relative;
    top: -2px;
}

.modalHeaderRightSide .backBoxIcon {
    border: 1px solid;
    border-top-color: currentcolor;
    border-right-color: currentcolor;
    border-bottom-color: currentcolor;
    border-left-color: currentcolor;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
    border-radius: 16px;
    top: 0;
}

/* Back Button Box */

/* Loading Screen */
.loadingScreenMain {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 85px;
    padding-bottom: 85px;
    position: absolute;
    background: var(--blackBg) !important;
    z-index: 999;
    top: 0;
    width: 100%;
    left: 0;
}
.loadingScreenMain-Light {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 85px;
    padding-bottom: 85px;
    position: absolute;
    background: var(--white) !important;
    z-index: 999;
    top: 0;
    width: 100%;
    left: 0;
}

.loadingScreenInner {
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.loadingScreenMain .loaderCircle {
    width: 67px;
    height: 67px;
}
.loadingScreenMain-Light .loaderCircle {
    width: 67px;
    height: 67px;
}

.loaderCircle.spinner-border {
    border-width: 0.3rem;
    color: var(--brightGreen);
    animation: rotate 1s linear infinite;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

/* Loading Screen */

/* Custom Header */
.headerMainCont {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 45px;
    padding: 10px 10px;
    border-bottom: 1px solid;
}

.headerLeftSide {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.headerLeftSide .buttonIconStyle {
    margin: 0 0 5px 0;
}

.appBreadcrum {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
}

.appBreadcrum p:nth-child(1) {
    position: relative;
    top: -1px;
}

.headerTitleSeperator {
    content: '';
    width: 0.09rem;
    display: flex;
    background: var(--seperatorTwo);
    margin-top: 0px;
    margin-bottom: 0px;
}

.posManagerName {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.buttonStyle.headerCustomerButton {
    background: transparent;
    width: 100px;
    height: 30px;
    border: none;
    border-radius: 0;
    box-shadow: none;
    outline: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* padding: 0 5px; */
    width: auto;
}

.buttonStyle.headerCustomerButton svg {
    margin-top: 6px;
}

.headerCustomerButton.buttonreverse {
    flex-direction: row-reverse;
}

.headerRigthSide {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

/* .LeftActionButton button {
    cursor: none;
} */

.headerRigthSide .buttonIconStyle {
    margin: 0px;
}

.headerRigthSide .buttonIconStyle:hover {
    cursor: default;
}

.headerRigthSide svg {
    margin-bottom: 3px;
}

.topBarseperator {
    width: 2px;
    background: var(--seperatorTwo);
    margin: 0 15px;
    border-radius: 20px;
}

/* Custom Header */

/* ======leftheaderbox======*/
.newposSearchBar {
    display: flex;
    flex-direction: row;
    height: 60px;
    width: 100%;
    border-bottom: 1px solid var(--inputBorder);
    caret-color: var(--barclaysBlue);
}

.inputFormGroupInner {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0 20px;
}

.inputFormGroupInner .inputPrefixIcon {
    margin-bottom: 5px;
}

.inputFormGroupInner .customInputFeild {
    border: none;
    padding: 0 10px;
    font-size: 14px;
    font-weight: bold;
    width: 100%;
    font-weight: 400;
    height: 100%;
    background-color: transparent;
    color: var(--white);
}

.inputFormGroupInner .customInputFeild::placeholder {
    color: var(--lightGrayTwo);
}

.inputFormGroupInner .customInputFeild:focus {
    outline: none;
}

/* ======leftheaderbox======*/

/* ======posHeaderBox======*/
.posHeaderBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 15px 5px;
    border-bottom: 1px var(--darkgrayBorderbg) solid;
}

.posHeaderBox .buttonPrimary {
    width: 137px;
    margin-left: 15px;
}

.restaurantPosHeaderBtn {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 15px 5px;
    border-bottom: 1px var(--darkgrayBorderbg) solid;
}

.restaurantPosHeaderBtn .buttonPrimary {
    width: 137px;
    margin-left: 15px;
}

/* ======posHeaderBox======*/

/* ====== Primary Button ====== */
.buttonPrimary {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 52px;
    border: 1px solid;
    border-color: var(--posRightBg);
    border-radius: 12px;
    box-shadow: none;
    /* width: 180px; */
}

/* ======Primary Button ====== */
/* ======posHeaderBox======*/
.posHeaderBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 15px 5px;
    border-bottom: 1px var(--darkgrayBorderbg) solid;
}

.posHeaderBox .buttonPrimary {
    width: 55% !important;
    margin-left: 15px;
}

/* ======posHeaderBox======*/

/* Top Bar */
.topBarMainCont {
    height: 90px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px 0px;
}

.topBarLeftSide {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
}

.topBarRightSide {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.topBarRightSide .buttonVertical {
    background: transparent;
    width: 45px;
    height: 55px;
    margin: 0 0px 0 15px;
}

.addCustomerButton {
    background: transparent !important;
    border: 1px solid var(--white) !important;
    height: 35px;
    width: 150px;
    border-radius: 10px;
}

.headerButtonRadius .headerRightCta {
    border-radius: 20px;
}

.headerRightCta {
    min-width: 170px;
    height: 50px !important;
    padding-left: 13px;
    padding-right: 13px;
}

.posCockpitHeaderRightCta {
    height: 43px;
    border-radius: 5px;
}

.topBarLeftSide .posSearchBar {
    height: 43px;
    width: 60%;
    justify-content: flex-start;
    padding: 0 0;
}

.headerButtonRadius .topBarLeftSide .posSearchBar .formGroupInner .MuiFilledInput-root {
    border-radius: 20px;
}

.topBarLeftSide .posSearchBar .formGroupInner .MuiFilledInput-root {
    border-radius: 12px;
    min-height: 43px;
}

.topBarLeftSide .posSearchBar .form-group {
    width: 100%;
}

.topBarLeftSide .posSearchBar .formGroupInner .MuiInputLabel-filled {
    transform: translate(50px, 12px) scale(1);
    font-size: 14px;
}

.topBarLeftSide .posSearchBar .formGroupInner .MuiInputLabel-filled.MuiInputLabel-shrink {
    transform: translate(50px, 4px) scale(0.8);
}

.topBarLeftSide .posSearchBar .formGroupInner .MuiFilledInput-input {
    padding: 15px 15px 5px 50px;
}

.topBarLeftSide .formGroupInner .MuiFormLabel-root {
    font-size: 14px;
}

.topBarLeftSide .posSearchBar .formGroupInner .prefixIcon {
    left: 20px;
    top: 8px;
}

.topBarRightSide .customDropDown {
    margin-left: 10px;
}

.filterHeaderLeft .topBarRightSide .customDropDown {
    margin-left: 15px;
}

.orderDatetme {
    position: relative;
    top: 1px;
}

.printBtn {
    min-width: 130px;
}

.topBarRightSide .buttonStyle {
    height: 43px;
}

/* Top Bar */

/* Pos Cockpit Header */
.posCockpitHeader .posSearchBar {
    width: 100%;
}

.posCockpitHeader .topBarLeftSide .posSearchBar .formGroupInner .MuiFilledInput-root {
    border-radius: 5px;
    background-color: var(--topBarBG);
}

/* Pos Cockpit Header */

/* Custom Tabs */
.posTabBox {
    width: 100%;
    padding: 5px 0px;
}

.posTabBox .MuiPaper-elevation1 {
    box-shadow: none;
    border-bottom: 1px solid var(--darkgrayBorderbg);
}

.posTabBox .MuiTab-root {
    min-width: 140px;
    padding: 8px 12px 15px;
    font-family: var(--outfitFont);
}

.posTabBox .MuiTab-wrapper {
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
    color: var(--white);
}

.PrivateTabIndicator-colorPrimary-10 {
    background-color: var(--brightGreen) !important;
}

.posTabBox .PrivateTabIndicator-colorPrimary-2 {
    background-color: var(--brightGreen) !important;
    height: 3px;
    border-radius: 20px;
    margin: 0 5px;
}

.posTabBox .MuiTabs-indicator {
    background-color: var(--brightGreen) !important;
    height: 3px;
    border-radius: 20px;
}

.posTabBox .PrivateTabIndicator-colorPrimary-58 {
    background-color: var(--brightGreen);
    height: 3px;
    border-radius: 20px;
}

/* Custom Tabs */

/* Breadcrums */
.breadCrumBox {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0 8px;
    min-height: 45px;
    margin: 0px 0 8px;
    border-bottom: 1px solid var(--darkgrayBorderbg);
}

.breadCrumBox ul {
    list-style: none;
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;
}

.breadCrumBox ul li {
    margin-right: 5px;
}

.breadCrumBox ul li a {
    color: var(--white);
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
    padding: 0 20px;
    text-transform: capitalize;
}

/* Breadcrums */

/* Custom Dropdown Menu */
.customDropDown .dropdown-toggle::after {
    display: none;
}

.customDropDown .dropdown-toggle {
    background: transparent;
    border: none;
    padding: 0;
    width: auto;
}

.customDropDown .dropdown-toggle:focus {
    background: transparent;
}

.customDropDown .dropdown-menu {
    border-radius: 10px;
    padding: 10px;
    background: var(--blackPearl);
    border: 1px solid var(--inputBorder);
    width: 100%;
    padding: 5px 5px;
    margin: 5px 0px;
}

.customDropDown .dropdown-menu .dropdown-item {
    border-color: var(--lightGray);
    padding: 12px 5px;
    font-size: 14px;
    font-weight: 400;
    color: var(--white);
    background: transparent;
}

.customDropDown .dropdown-menu .checkBoxMainBox label p {
    color: var(--white) !important;
}

.customDropDown .dropdown-menu .dropdown-item:hover {
    background-color: var(--inputBorder);
    cursor: pointer;
    border-radius: 5px;
}

.customDropDown .dropdown-menu .dropdown-item:last-child {
    border-bottom: none;
}

.topBarMainCont .customDropDown .dropdown-toggle {
    min-width: 155px;
    border: 1px solid var(--inputBorder);
    height: 50px;
    border-radius: 10px;
    padding: 0 15px;
    background-color: var(--topBarBG);
}

.filterHeaderLeft .customDropDown .dropdown-toggle {
    min-width: 110px;
}

.topBarMainCont.filterBoxMain .customDropDown .dropdown-toggle {
    height: 45px;
    border: 1px solid var(--seperatorTwo);
    border-radius: 20px;
    min-width: 100%;
}

/* Pos Cockpit dropdown */
.posCockpitHeader .customDropDown .dropdown-toggle {
    border: 1px solid var(--inputBorder);
    height: 43px;
    border-radius: 5px;
    background-color: var(--topBarBG);
    padding: 0 15px;
}

.posCockpitHeader .customDropDown .dropdown-toggle {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.posCockpitHeader .customDropDown .dropdown-menu {
    width: 100%;
    background: var(--topBarBG);
    border: 1px solid var(--inputBorder);
    border-radius: 5px;
    transform: translate(0px, 45px) !important;
}

.posCockpitHeader .customDropDown .dropdown-menu .checkBoxMainBox label p {
    color: var(--white) !important;
}

.posCockpitHeader .customDropDown input[type='checkbox'] {
    border: 1px solid var(--borderColor);
}

.posCockpitHeader .customDropDown .dropdown-menu .dropdown-item {
    border-bottom: none;
}

.posCockpitHeader .customDropDown .dropdown-menu .dropdown-item:hover {
    background-color: var(--topBarBG);
    cursor: pointer;
}

.filterBoxMain {
    width: 75%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.filterBoxMain .customDropDown {
    width: 100%;
}

.filterBoxMain button {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
}

.customDropDown .checkBoxMainBox {
    margin-top: 0;
    margin-bottom: 0;
}

.customDropDown input[type='checkbox'] {
    border: 1px solid var(--borderColor);
    width: 20px;
    height: 20px;
}

.customDropDoswn .dropdown-item:focus,
.customDropDown .dropdown-item:active {
    background: transparent !important;
}

/* Custom Dropdown Menu */

/* Side panel */
.sidePaneNav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.navigationSiderbar.offcanvas.offcanvas-start {
    top: 45px;
    width: 230px;
}

.navigationSiderbar {}

.navigationSiderbar .offcanvas-body {
    padding: 10px 0;
    overflow-x: visible;
}

.navigationSiderbar .sidePaneNav .nav-item {
    width: 100%;
    padding: 3px 10px 0px;
    margin-bottom: 5px;
}

.navigationSiderbar .sidePaneNav .nav-item .nav-link {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 6px 10px 9px;
    position: relative;
    border-left: 2px solid var(--blackBg);
}

.navigationSiderbar .sidePaneNav .nav-item>.nav-link.active,
.navigationSiderbar .sidePaneNav .nav-item>.nav-link:hover {
    border-left: 2px solid var(--brightGreen) !important;
    background: var(--blackPearl);
    position: relative;
    border-radius: 0.375rem;
}

.menuIcon {
    width: 20px;
}

.navigationSiderbar .nav-link.active::before {
    /* content: '';
    display: block;
    width: 3px;
    height: 65%;
    background: var(--brightGreen);
    position: absolute;
    left: 0;
    border-radius: 4px; */
}

.navigationSiderbar .accordion {
    min-height: min-content;
    padding: 5px 0px;
    background: var(--blackPearl);
    border-radius: 0 0 0.375rem 0.375rem;
}

.navigationSiderbar .accordion .accordion-collapse {
    margin-bottom: 5px;
    border-radius: 0;
}

.navigationSiderbar .sidePaneNav .accordion-collapse .nav-item {
    margin-bottom: 0;
    padding: 0;
}

.navigationSiderbar .sidePaneNav .accordion-collapse .nav-item .nav-link {
    padding: 10px 8px !important;
}

.navigationSiderbar .sidePaneNav .nav-item .navSubmenuWrapper .nav-item .nav-link {
    border-left: 0;
    padding: 11px 15px 11px 43px !important;
    border-bottom: 1px solid var(--darkgrayBorderbg);
    border-radius: 0;
}

.navigationSiderbar .sidePaneNav .nav-item .navSubmenuWrapper .nav-item .nav-link:hover {
    border-left: 0 !important;
}

.navSubmenuWrapper {
    transition-timing-function: ease-in-out;
}

.navigationSiderbar .accordion .accordion-item {
    border: none !important;
    background-color: var(--blackPearl);
}

.navigationSiderbar .accordion .accordion-button {
    background: var(--blackBg) !important;
    padding: 10px 18px !important;
    box-shadow: none !important;
}

.navigationSiderbar .accordion .accordion-body {
    background: var(--blackPearl) !important;
    color: #ffff !important;
    padding: 0 0 !important;
    font-size: 14px !important;
}

.navigationSiderbar .offcanvas-body::-webkit-scrollbar {
    width: 5px;
}

.navigationSiderbar .offcanvas-body::-webkit-scrollbar-track {
    background: var(--blackBg);
}

.navigationSiderbar .offcanvas-body::-webkit-scrollbar-thumb {
    background-color: var(--blackBg);
    border-radius: 6px;
    border: 3px solid var(--darkGrayTwo);
}

.subMenuArrowIcon {
    position: absolute;
    right: 10px;
    top: 10px;
}

.menuTitle {
    width: calc(100% - 36px);
    position: relative;
    top: 1px;
}

.sidebarNavSeperator {
    border-top: 4px solid var(--blackPearl);
    opacity: 1;
    width: 100%;
    margin: 8px 0 14px;
}

.navigationSiderbar .sidePaneNav .nav-item>.nav-link.active.listOpen,
.navigationSiderbar .sidePaneNav .nav-item>.nav-link.active.listOpen:hover {
    border-radius: 0.375rem 0.375rem 0 0;
}

/* Side panel */

/* No Padding */
.noPadding {
    padding-left: 0;
    padding-right: 0;
}

.padRight0 {
    padding-right: 0;
}

.padLeft0 {
    padding-left: 0;
}

.padLeft10 {
    padding-left: 10px;
}

.padLeft20 {
    padding-left: 20px;
}

/* No Padding */
/*  Padding */
.pad10 {
    padding: 10px 10px;
}

.pad20 {
    padding: 20px 20px;
}

.pad30 {
    padding: 30px 30px;
}

/*left  Padding */

/* No Margin */
.noMargin {
    margin-left: 0;
    margin-right: 0;
}

/* No Padding */

/* Login Page */
.loginPageWrapper {
    background-image: url('./assets/images/authBg.png');
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mainCont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.logoBox {
    position: absolute;
    left: 30px;
    top: 30px;
}

.loginFormBox {
    border-radius: 20px;
    padding: 40px 50px;
    background: rgba(19, 23, 33, 0.5);
}

.loginFormBox .formGroupInner .MuiFilledInput-input {
    padding: 17px 15px 0px 15px;
}

.loginFormBox .form-group {
    width: 100%;
}

.forgotPasswordBox {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 90%;
    margin: 3px 0 25px;
}

.signinLoginButton {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

/* Login Page */

/* Signup Up Page */
.signupCont {
    background-image: url('./assets/images/authBg.png');
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/*! Other Browser */
.signupCont {
    --scrollbarBG: var(--blackBg);
    --thumbBG: var(--darkGrayTwo);
}

.signupCont::-webkit-scrollbar {
    width: 5px;
}

.signupCont::-webkit-scrollbar-track {
    background: var(--blackBg);
}

.signupCont::-webkit-scrollbar-thumb {
    background-color: var(--blackBg);
    border-radius: 6px;
    border: 3px solid var(--darkGrayTwo);
}

.signupTextBox {
    width: 90%;
}

.singupFields {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.singupFields .form-group:nth-child(1) {
    margin-right: 7px;
}

.singupFields .form-group:nth-child(2) {
    margin-left: 7px;
}

.signinLoginButton.marTop15 {
    margin-top: 50px;
}

.sigupFooterLogo {
    position: absolute;
    left: 0;
    bottom: 25px;
    padding: 15px 20px;
    height: 75px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.signupBottomText {
    width: 90%;
    margin-top: 20px;
}

.signupBottomText a {
    text-decoration: none;
}

.signupBottomText .checkBoxMainBox {
    margin-bottom: 0px !important;
}

.signupCont .signinLoginButton {
    margin: 12px auto 25px;
}

/* Signup Up Page */

/* Forgot password flow */

.emailVerifyOtp {
    width: 100%;
    /* margin-top: 20px; */
}

.emailVerifyOtp input {
    width: 100% !important;
    margin-left: 10px;
    background-color: transparent;
    color: var(--white);
    border: 0 solid var(--white);
    font-size: 16px !important;
    border-bottom-width: 1px;
    height: 50px !important;
    font-weight: 400 !important;
}

.emailVerifyOtpErrors input {
    border-bottom-color: var(--red) !important;
}

.emailVerifyOtpWithoutErrors input {
    border-bottom-color: var(--white) !important;
}

.emailVerifyOtp input:first-child {
    margin-left: 0;
}

.emailVerifyOtp input {
    caret-color: var(--barclaysBlue);
}

.emailVerifyOtp input:focus {
    outline: none;
}

.resendCodeWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

/* Forgot password flow */

/* Non Ideal Screen */
.allScreenNonIdealBox {
    height: calc(100vh - 45px);
    padding: 20px;
    background-color: var(--topBarBG);
}

.listNonIdealBox {
    height: calc(100vh - 171px) !important;
}

.tableNonIdealBox {
    height: 100% !important;
}

.allScreenNonIdealInner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: var(--blackBg);
    border-radius: 10px;
}

.allScreenNonIdealInner .buttonPrimary {
    width: 180px;
}

.allScreenNonIdealInner.onboardingNonideal {
    justify-content: flex-start;
    padding-top: 30px;
    height: 80vh;
}

.allScreenNonIdealBox .progressbarMainBox {
    position: relative;
}

.allScreenNonIdealBox .progressbarMainBoxInner {
    width: 80%;
    padding: 0 0;
}

.nonIdealHeadingBox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.nonIdealIconBox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    min-height: 100px;
}

.nonIdealImageBox {
    margin: 10px 0 30px;
}

.nonIdealSubHeadingBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 0 15px;
    width: 100%;
    text-align: center;
}

.nonIdealActionBox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.nonIdealActionBox .buttonStyle {
    width: 180px;
}

.cardConatiner {
    padding: 20px;
    border-radius: 10px;
}

.selectCustomerModal .allScreenNonIdealBox {
    background: transparent !important;
    height: 100% !important;
}

.selectCustomerModal .allScreenNonIdealInner {
    height: calc(94vh - 150px);
}

/* Non Ideal Screen */

/* Pos Non Ideal Screen */
.nonIdealBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.posNonIdealIcon {
    content: '';
    width: 190px;
    height: 126px;
    display: block;
    background: url('../src/assets/images/barCodeNonIdeal.png');
}

/* Pos Non Ideal Screen */

/*=======PosRightHeader=======*/

.posRightHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
    height: 60px;
    border-bottom: 1px var(--inputBorder) solid;
}

/* Add Customer button */
.posRightHeader .addCustomerBtn {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: none;
    background: none;
    /* padding: 0px 10px; */
}

.posRightHeader .addCustomerBtn .verticalButtonIcon {
    margin-top: 7px;
    /* margin-right: 5px; */
}

/* Add Customer button */

/* More Icon Modal */
.posRightHeaderDropdown {
    height: 23px;
    width: 35px;
    margin-left: 10px;
}

.posRightHeaderDropdown .customDropDown .dropdown-toggle:hover {
    background-color: var(--blackPearl);
    border-radius: 5px;
}

.posRightHeaderDropdown .customDropDown span svg {
    margin-bottom: 5px;
    margin-left: 3px;
}

.posRightHeaderDropdown .customDropDown .dropdown-menu {
    border-color: var(--inputBorder);
    min-width: 260px;
    height: max-content;
}

.posRightHeaderDropdown .customDropDown .dropdown-menu .dropdown-item {
    color: var(--white);
    padding: 6px;
    margin: 0px;
}

/* More Icon Modal */

/*=======PosRightHeader=======*/

/* Business Creation Screen */
.progressbarMainBox {
    height: 20vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* width: auto; */
    position: absolute;
    top: 0;
    z-index: 10;
}

.progressbarMainBoxInner {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 65%;
    padding: 0px 2px;
}

.progressbarMainBoxInner .progress {
    width: 30%;
    height: 3px;
    border-radius: 20px;
    background-color: var(--posRightBg);
    cursor: pointer;
}

.progressbarMainBoxInner .progress .progress-bar {
    background-color: var(--brightGreen);
    border-radius: 20px;
}

.loginFormBox .formGroupInner .MuiAutocomplete-inputRoot[class*='MuiFilledInput-root'] .MuiAutocomplete-input {
    padding: 17px 15px 0px 15px !important;
}

.singupFields.businessCreationFields {
    justify-content: center;
    flex-direction: column;
}

.loginFormBox .signupTextBox {
    width: 100%;
    padding: 0 12px;
}

.loginFormBox .signinLoginButton {
    width: 100%;
}

.singupFields.businessCreationFields .form-group:nth-child(1) {
    margin-right: 0;
}

.singupFields.businessCreationFields .form-group:nth-child(2) {
    margin-left: 0px;
}

/* Business Creation Screen */

/* Top View for add */
.addScreenTopBar {
    height: 65px;
    border-bottom: 1px solid;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 10px;
    margin-top: 10px;
}

.closeButtonBox {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.closeButtonBox .headerbuttonStyle {
    height: 40px;
    min-width: 120px;
    padding: 5px;
    font-size: 16px;
    font-weight: 400;
    position: relative;
    top: -1px;
    border-radius: 5px;
}

.closeButtonBox .backBoxIcon {
    border: 1px solid;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
    border-radius: 16px;
    top: 0;
}

.addScreenMainBox {
    /* height: calc(100% - 110px); */
    float: left;
    width: 100%;
    overflow: auto;
    padding-bottom: 20px;
    /* padding-bottom: 165px; */
    scrollbar-width: 5px;
    scrollbar-color: var(--darkGrayTwo) var(--blackBg);
    overflow-x: hidden;
}

/*! Other Browser */
.addScreenMainBox {
    --scrollbarBG: var(--blackBg);
    --thumbBG: var(--darkGrayTwo);
}

.addScreenMainBox::-webkit-scrollbar {
    width: 5px;
}

.addScreenMainBox::-webkit-scrollbar-track {
    background: var(--blackBg);
}

.addScreenMainBox::-webkit-scrollbar-thumb {
    background-color: var(--blackBg);
    border-radius: 6px;
    border: 3px solid var(--darkGrayTwo);
}

.addScreenMainBox .loginFormBox {
    overflow: hidden;
    height: 100%;
}

/* Top View for add */

/* Three column Input Fields */
.FormGridThree {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}

.threeGridInput {
    width: 33.33%;
}

.FormGridThree .threeGridInput:nth-child(2) {
    margin-left: 15px;
    margin-right: 15px;
}

.threeGridInput .form-group {
    width: 100%;
}

/* Three column Input Fields */

/* Item Inventory */
.inventoryTop {}

.conversionRateSectionRight {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 10px;
    width: 50%;
    gap: 20px;
}

.primaryRateSection {
    width: 30%;
}

.intventoryFormGridTwo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.intventoryFormGridLeft {
    width: 70%;
    margin-right: 15px;
}

.loginFormBox .intventoryFormGridLeft .form-group {
    width: 95%;
}

.intventoryFormGridRight {
    width: 30%;
    position: relative;
    top: 5px;
}

.profileImageUpload {
    width: 100%;
    height: 150px;
    margin-bottom: 10px;
    padding: 0px;
    border-radius: 50%;
    background: transparent;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.profileImageUpload .imageUploadLebel {
    display: flex;
    flex-direction: column;
}

.profileImageUpload input {
    display: none;
}

.profileImageUpload .imageConatiner {
    display: block;
    width: 100%;
    height: 100%;
    text-align: center;
    background: transparent;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px dashed var(--white);
    border-radius: 12px;
}

.createPO label {
    color: var(--inputText) !important;
}

.itemFields {
    display: block;
    width: 100%;
    margin: 0 auto;
    padding: 0 12px;
}

.profileImageUpload.imageLibrary {
    height: 50px;
    width: 100%;
    margin: 15px 0 37px;
}

.profileImageUpload.imageLibrary label {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 18px;
}

.dragImagePara {
    font-size: 14px;
    margin-bottom: 0;
}

.itemSeperator {
    content: '';
    height: 1px;
    width: 100%;
    display: flex;
    background: var(--topBarBG);
    margin: 20px 0px;
    border-radius: 20px;
}

.priceSectionInputLeft {
    width: 50%;
    margin-right: 10px;
}

.priceSectionInputLeft .form-group {
    width: 100%;
}

.priceSectionInputRight {
    margin-left: 10px;
    width: 50%;
}

.tooltipFeild {
    margin-bottom: 18px;
}

.tooltipFeild .formGroupInner .MuiFilledInput-root.Mui-disabled,
.formGroupInner .MuioutlinedInput-root.Mui-disabled {
    background-color: var(--seperatorTwo);
}

.posPinInput {
    display: flex;
    width: 100%;
    margin-left: 106px;
}

.priceSectionInputRight .form-group {
    width: 100%;
}

.itemScreenCheckbox {
    width: 90%;
}

.itemScreenCheckbox .checkBoxMainBox {
    margin-top: 5px;
    margin-bottom: 25px;
}

.refundScreenCheckbox .checkBoxMainBox {
    margin-top: 0px;
    margin-bottom: 0px;
}

.costItem {
    width: 50%;
    margin-right: 0px;
}

.costItem .form-group {
    width: 100%;
}

.profit {
    width: 25%;
    margin-left: 15px;
}

.profit .form-group {
    width: 100%;
}

.itemScreenCheckbox.customSpace .checkBoxMainBox {
    margin-bottom: 20px;
}

/* Item Inventory */

/* Pos Left Section */
.positioRelative {
    position: relative;
}

.posleftSection {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px 15px 10px;
    /* height: 89vh ; */
    height: 100%;
    position: relative;
    width: 100%;
}

.posSectionRow {
    padding: 0 0;
    margin: 0;
}

.posSectionRow .verticalButtonIcon {
    display: none;
}

.leftPaneMainBox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
}

.leftPaneItemsBox {
    /* display: grid;
    grid-template-columns: repeat(auto-fill, minmax(137px, 1fr));
    grid-template-rows: repeat(auto-fill, 92px); */
    grid-gap: 15px;
    grid-gap: 35px 15px;
    gap: 35px 15px;
    margin: 0;
    padding: 3px 20px 10px 10px;
    box-sizing: border-box;
    width: 100%;
    height: 90%;
    overflow: auto;
    scrollbar-width: 5px;
    scrollbar-color: var(--darkGrayTwo) var(--blackBg);
}

.leftPaneBottomPagination {
    height: 14%;
    width: 100%;
}

/*! Other Browser */
.leftPaneItemsBox {
    --scrollbarBG: var(--blackBg);
    --thumbBG: var(--darkGrayTwo);
}

.leftPaneItemsBox::-webkit-scrollbar {
    width: 5px;
}

.leftPaneItemsBox::-webkit-scrollbar-track {
    background: var(--blackBg);
}

.leftPaneItemsBox::-webkit-scrollbar-thumb {
    background-color: var(--blackBg);
    border-radius: 6px;
    border: 3px solid var(--darkGrayTwo);
}

.leftpaneBottom {
    height: 10%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-top: 1px solid var(--inputBorder);
}

.leftpaneBottom p {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.posBottomSeperator {
    content: '';
    width: 0.09rem;
    display: flex;
    background: var(--white);
    margin: 0 15px;
}

.posleftSection .form-group {
    width: 99%;
}

.posBottomSeperator {
    content: '';
    width: 0.09rem;
    display: flex;
    background: var(--white);
    margin: 0 15px;
}

.posSearchBar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 55px;
    width: 100%;
    padding: 0 7px;
}

.posSearchBar .inputError {
    display: none;
}

.posSearchBar .formGroupInner {
    margin: 0;
}

.posSearchBar .formGroupInner .MuiFilledInput-root {
    border-radius: 40px;
}

.posSearchBar .formGroupInner .prefixIcon {
    left: 25px;
    top: 13px;
}

.posSearchBar .formGroupInner .MuiFilledInput-input {
    padding: 17px 15px 0px 50px;
}

.posSearchBar .formGroupInner .MuiInputLabel-filled {
    transform: translate(50px, 14px) scale(1);
}

.posSearchBar .formGroupInner .MuiInputLabel-filled.MuiInputLabel-shrink {
    transform: translate(50px, 10px) scale(0.8);
}

/* Pos Left Section */

/* Pos Items Box */
.posItemsBox {
    border: 0.13rem solid;
    border-radius: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 110px;
    padding: 10px 8px;
    text-align: center;
    text-transform: capitalize;
    cursor: pointer;
}

.itemtabsBox {
    border-color: var(--brown) !important;
}

.itemPrice {
    position: absolute;
    top: 4px;
    right: 8px;
    font-size: 14px;
    font-weight: 600;
    margin: 0;
}

.itemQuantity {
    position: absolute;
    top: 6px;
    left: 8px;
    margin: 0;
    min-width: 27px;
    height: 15px;
    border-radius: 4px;
    font-size: 10px;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.posItemsBox .twoLineTruncate {
    word-break: break-word;
}

/* Pos Items Box */

/* Pos Right Section */
.posrightSectionInner {
    width: 100%;
    height: 100%;
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    background-color: var(--newposRightBg);
    align-content: flex-start;
}

.posItemListHeading {
    height: 42px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}

.posRightpaneBottomSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 35%;
}

.posRightpaneBottomSection .calculatorMainBox {
    padding: 5px 0px 0px 0px;
}

.posPaymentButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 6.9vh;
    position: relative;
    top: 2px;
}

.posPaymentButton button {
    height: 95%;
}

/* Pos Right Section */

/*---------- pos non ideal----------- */
.posNonidealBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 90%;
}

.posSearchNonidealBox {
    width: 100%;
    height: 90%;
}

/*---------- pos non ideal----------- */

/* Pos Items Box */
.posItemsBox {
    border: 0.13rem solid;
    border-radius: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 110px;
    padding: 10px 8px;
    text-align: center;
    text-transform: capitalize;
    cursor: pointer;
}

.itemPrice {
    position: absolute;
    top: 4px;
    right: 8px;
    font-size: 14px;
    font-weight: 600;
    margin: 0;
}

.itemQuantity {
    position: absolute;
    top: 6px;
    left: 8px;
    margin: 0;
    min-width: 27px;
    height: 15px;
    border-radius: 4px;
    font-size: 10px;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.posItemsBox .twoLineTruncate {
    word-break: break-word;
}

/* Pos Items Box */

/* Cart Item List */
.cartItemList {
    width: 100%;
    overflow: auto;
    background-color: var(--topBarBG);
    scrollbar-width: 5px;
    --scrollbarBG: var(--blackBg);
    --thumbBG: var(--darkGrayTwo);
    flex-grow: 1;
}

/*! Other Browser */
.cartItemList {
    --scrollbarBG: var(--blackBg);
    --thumbBG: var(--darkGrayTwo);
}

.cartItemList::-webkit-scrollbar {
    width: 5px;
}

.cartItemList::-webkit-scrollbar-track {
    background: var(--blackBg);
}

.cartItemList::-webkit-scrollbar-thumb {
    background-color: var(--blackBg);
    border-radius: 6px;
    border: 3px solid var(--darkGrayTwo);
}

.cartItemListInner {
    height: 50px;
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
    margin-top: 5px;
    height: 60px;
    background: var(--topBarBG);
}

.disableCartItem {
    height: 50px;
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
    margin-top: 5px;
    height: 60px;
    background: var(--darkBluishGray);
}

.cartItemNameImage {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 50%;
    padding-left: 10px;
}

.cartItemQtyBox {
    width: 20%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.cartItemQuantityBox {
    width: 70px;
    position: relative;
    margin-right: 10px;
}

.cartItemQuantityBox .formGroupInner .MuiFilledInput-root {
    min-height: 35px !important;
    padding-right: 0px !important;
    background: rgb(37, 42, 61);
    border: 1px solid var(--white);
}

.cartItemQuantityBox .formGroupInner .MuiFilledInput-input {
    color: var(--white);
    padding: 5px 10px;
}

.cartItemQuantityBox .formGroupInner .MuiInputLabel-filled.MuiInputLabel-shrink {
    transform: translate(8px, -20px) scale(0.8);
    color: var(--white) !important;
}

.cartItemQuantityBox .MuiFormLabel-root .MuiInputBase-input {
    font-size: 10px !important;
    color: var(--white);
}

.cartItemAmountBox {
    width: 100px;
    position: relative;
    margin-right: 0px;
}

.cartItemAmountBox .formGroupInner .MuiFilledInput-root {
    min-height: 35px !important;
    padding-right: 0px !important;
    background: rgb(37, 42, 61);
    border: 1px solid var(--white);
}

.cartItemAmountBox .formGroupInner .MuiFilledInput-input {
    color: var(--white);
    padding: 5px 10px;
}

.cartItemAmountBox .formGroupInner .MuiInputLabel-filled.MuiInputLabel-shrink {
    transform: translate(8px, -20px) scale(0.8);
    color: var(--white) !important;
}

.cartItemAmountBox .MuiFormLabel-root .MuiInputBase-input {
    font-size: 10px !important;
    color: var(--white);
}

.cartItemUnit {
    width: 140px;
    position: relative;
}

.cartItemUnit .formGroupInner .MuiFilledInput-root {
    min-height: 35px !important;
    padding-right: 0px !important;
}

.cartItemUnit .formGroupInner .MuiInputLabel-filled.MuiInputLabel-shrink {
    transform: translate(8px, -20px) scale(0.8);
    color: var(--white) !important;
}

.cartItemUnit .MuiFormLabel-root .MuiInputBase-input {
    font-size: 12px !important;
}

.cartItemUnit .formGroupInner .MuiFilledInput-root .MuiFilledInput-input {
    padding: 5px 10px !important;
}

.cartItemName {
    color: white;
    height: 40px;
    border: 1px var(--inputBorder) solid;
    border-radius: 10px;
    font-size: 14px;
    width: 80%;
}

.cartItemName .inputFormGroupInner .customInputFeild {
    padding: 0px;
}

.cartItemInputFeild {
    color: white;
    height: 40px;
    border: 1px var(--inputBorder) solid;
    border-radius: 10px;
    font-size: 14px;
    width: 100%;
}

.cartItemName .formGroupInner .MuiFilledInput-root {
    min-height: 35px !important;
    padding-right: 0px !important;
    background: rgb(37, 42, 61);
    border: 1px solid var(--white);
}

.cartItemName .formGroupInner .MuiFilledInput-input {
    color: var(--white);
    padding: 5px 10px;
}

.cartItemInputFeild .inputFormGroupInner .customInputFeild {
    padding: 5px;
}

.cartItemInputFeild .inputFormGroupInner .customInputFeild:focus {
    caret-color: var(--barclaysBlue);
}

.cartItemName .formGroupInner .MuiInputLabel-filled.MuiInputLabel-shrink {
    transform: translate(8px, -20px) scale(0.8);
    color: var(--white) !important;
}

.cartItemName .MuiFormLabel-root .MuiInputBase-input {
    font-size: 12px !important;
}

.cartItemPrice {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    position: relative;
}

.cartItemAction {
    width: 3%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-left: 5px;
}

.cartItemAction .dropdown-menu a:nth-child(1) {
    color: var(--barclaysBlue);
}

.cartItemAction .dropdown-menu a:nth-child(3) {
    color: var(--red);
}

/* Cart Item List */

/* -------Item Leve Discount Modal -------*/

.itemLevelPopupMain {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 8px;
}

.bottomButton .buttonStyle {
    width: 100px;
    border-radius: 5px;
    height: 40px;
    font-size: 14px;
}

.discoountButtonInputBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.selectDiscountButton {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.rupeesDiscount {
    width: 48%;
}

.percentDiscount {
    width: 48%;
}

.discountTextFeildBox {
    margin: 20px 0px;
}

.discountTextFeildBox .formGroupInner {
    border: 1px solid var(--barclaysBlue);
    border-radius: 10px;
}

.discountErrorTextFeildBox .formGroupInner {
    border: 1px solid var(--red);
    border-radius: 10px;
}

.discountTextFeildBox .formGroupInner .MuiFilledInput-root.Mui-focused {
    border: none;
}

.discountTextFeildBox .formGroupInner .MuiFormLabel-root {
    color: var(--darkGray);
}

.itemLevelPopupMain .billScreenCalculator {
    height: 60%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--inputBorder);
    padding: 10px;
    border-radius: 10px;
}

/* -------Item Leve Discount Modal -------*/

/* Pos Billing Screen */
.posBillingMain {
    height: 100%;
}

.posBillingMain .posleftSection {
    height: calc(100vh - 45px);
    padding-top: 0;
}

.billScreenBack {
    width: 100%;
    height: 65px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0 8px;
}

.billScreenBillAmount {
    height: 50px;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 25px;
}

.posBillingMain .posrightSection {
    height: calc(100vh - 45px) !important;
    padding-top: 0;
    align-items: center;
}

.billScreenCashAmount {
    height: 30%;
    margin: 15px 0 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 95%;
    text-align: center;
}

.billScreenCashAmountInner {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px 0;
}

.billScreenCalculator {
    width: 95%;
    height: 50%;
}

.billScreenCalculator .calculatorInnerBoxColumn button {
    height: 22.5%;
}

.billScreenChangeButton {
    height: 15%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.billScreenChangeButton button {
    height: 55px;
}

.billScreenActionBox {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 55%;
    padding: 0 6px;
}

.billScreenActionBoxList {
    height: 95%;
    width: 100%;
    overflow: auto;
    scrollbar-width: 5px;
    scrollbar-color: var(--darkGrayTwo) var(--blackBg);
}

/* Transaction Successful Screen */

/*! Other Browser */
.billScreenActionBoxList {
    --scrollbarBG: var(--blackBg);
    --thumbBG: var(--darkGrayTwo);
}

.billScreenActionBoxList::-webkit-scrollbar {
    width: 5px;
}

.billScreenActionBoxList::-webkit-scrollbar-track {
    background: var(--blackBg);
}

.billScreenActionBoxList::-webkit-scrollbar-thumb {
    background-color: var(--blackBg);
    border-radius: 6px;
    border: 3px solid var(--darkGrayTwo);
}

.billScreenActionBoxList button {
    height: 50px;
    margin-bottom: 15px;
}

.actionCustomer .verticalButtonIcon {
    position: absolute;
    left: 20px;
    height: auto;
    width: auto;
    border: none;
}

.buttonStyle.actionCustomer {
    border: 1px solid var(--seperatorTwo);
    position: relative;
}

.buttonStyle.actionCustomer span {
    font-size: 18px;
}

.threeColumnBtn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
}

.btnIconBox {
    width: 30%;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.btnDetail {
    width: 70%;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    color: var(--white);
}

.btnText {
    width: 60%;
    text-align: center;
}

.btnValue {
    width: 40%;
    text-align: right;
    font-size: 22px;
}

.billScreenPaymentBox {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 32%;
    padding: 0 6px;
}

.paymentButtonBoxList {
    height: 78%;
    width: 100%;
    overflow: auto;
    scrollbar-width: 5px;
    scrollbar-color: var(--darkGrayTwo) var(--blackBg);
}

/*! Other Browser */
.paymentButtonBoxList {
    --scrollbarBG: var(--blackBg);
    --thumbBG: var(--darkGrayTwo);
}

.paymentButtonBoxList::-webkit-scrollbar {
    width: 5px;
}

.paymentButtonBoxList::-webkit-scrollbar-track {
    background: var(--blackBg);
}

.paymentButtonBoxList::-webkit-scrollbar-thumb {
    background-color: var(--blackBg);
    border-radius: 6px;
    border: 3px solid var(--darkGrayTwo);
}

.paymentButtonBox {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    align-content: flex-start;
}

.paymentButtonBox .buttonVertical {
    width: 31.5%;
    height: 100px;
    margin-bottom: 15px;
}

.paymentButtonBox .buttonVertical span {
    font-size: 14px;
    font-weight: 500;
}

.paymentButtonBox .verticalButtonIcon {
    width: auto;
    height: auto;
    border: none;
    border-radius: 0;
    margin-bottom: 15px;
}

.paymentButtonBox .buttonVertical:nth-child(2) {
    margin-left: 2.5%;
    margin-right: 2.5%;
}

.paymentButtonBox .buttonVertical:nth-child(4) {
    margin-bottom: 10px;
}

.billCashInput {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
}

.billCashInput p {
    text-align: center;
}

.billCashInput .form-group {}

.billCashInput .formGroupInner .MuiFilledInput-input {
    padding: 10px 10px;
    font-size: 70px;
    color: var(--white);
    font-weight: 500;
    height: 55px;
}

.billCashInput.billInputColor .formGroupInner .MuiFilledInput-input {
    color: var(--topBarBG);
}

.billCashInput .formGroupInner .MuiFilledInput-input:focus {
    border: none;
    outline: 0;
}

.billCashInput .formGroupInner .MuiFilledInput-root {
    background: transparent;
}

.billCashInput .formGroupInner .MuiFilledInput-root.Mui-focused {
    border: none;
}

.billCashInput .formGroupInner {
    margin: 0;
}

.billCashInput .inputError {
    display: none;
}

.billCashInput .formGroupInner .MuiFormControl-root {}

.cardContainer {
    height: 100%;
    width: 100%;
}

.billCardmount {
    height: 85%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.walletMainContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.walletRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid var(--posRightBg);
    width: 100%;
    padding: 15px;
}

.twoColumnBtn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
}

.twoColumnBtn .btnIconBox {
    width: 60%;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.twoColumnBtn .btnDetail {
    width: 100%;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    color: var(--white);
}

.btnText {
    width: 60%;
    text-align: center;
}

.btnValue {
    width: 40%;
    text-align: right;
    font-size: 22px;
}

.cashMethodOrderline {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
}

.cashMethodOrderlineInnerScroll {
    width: 100%;
    height: 73%;
    overflow: auto;
    scrollbar-width: 5px;
    scrollbar-color: var(--darkGrayTwo) var(--blackBg);
}

/*! Other Browser */
.cashMethodOrderlineInnerScroll {
    --scrollbarBG: var(--blackBg);
    --thumbBG: var(--darkGrayTwo);
}

.cashMethodOrderlineInnerScroll::-webkit-scrollbar {
    width: 5px;
}

.cashMethodOrderlineInnerScroll::-webkit-scrollbar-track {
    background: var(--blackBg);
}

.cashMethodOrderlineInnerScroll::-webkit-scrollbar-thumb {
    background-color: var(--blackBg);
    border-radius: 6px;
    border: 3px solid var(--darkGrayTwo);
}

.cashMethodOrderlineInner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 25px;
    height: 33%;
    border-bottom: 1px solid;
}

.cashMethodCashAmount {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 25px;
    height: 30%;
}

.cashMethodCashAmountInput {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.cashMethodCashAmountInput .formGroupInner {
    margin: 0;
}

.cashMethodCashAmountInput .inputError {
    display: none;
}

.cashMethodCashAmountInput .formGroupInner .MuiFilledInput-root {
    min-height: 48px;
    border-radius: 0;
    border: none;
    background: transparent;
}

.cashMethodCashAmountInput .formGroupInner .MuiFilledInput-root:focus {
    border: none;
    outline: 0;
}

.cashMethodCashAmountInput .formGroupInner .MuiFilledInput-input {
    font-size: 34px;
    text-align: right;
    padding: 0;
    color: var(--white);
}

/* Pos Billing Screen */

/* Calculator Component */

.paymentRightHead {
    height: 13%;
    padding: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.paymentRightBody {
    height: 87%;
    padding: 10px 15px 5px 15px;
}

.paymentRightBody .billScreenCalculator {
    margin: 0 auto;
    background-color: var(--blackBg);
    width: 100%;
    height: 65%;
    border-radius: 10px;
    padding: 17px;
}

.cashMainContainer {
    width: 100%;
    height: 100%;
}

.calculatorMainBox {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 5px 0px 5px 0px;
}

.calculatorInnerBoxColumn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;
    padding: 0px 1.5% 0px 0;
}

.calcThreeColumn {
    width: 33%;
}

.calcFourColumn {
    width: 25%;
}

.calculatorInnerBoxColumn button {
    height: 73px;
}

.calculatorMainBox {}

.calculatorInnerBoxColumn button.width100 {
    width: 100%;
}

.calculatorMainBox .calculatorInnerBoxColumn:nth-child(3) {
    padding: 0 0;
}

.calculatorMainBox .calculatorInnerBoxColumn.calcFourColumn:nth-child(3) {
    padding: 0px 1.5% 0px 0;
}

.calculatorMainBox .calcFourColumn.calculatorInnerBoxColumn:nth-child(4) {
    padding: 0 0;
}

.buttonIconStyle.buttonStyle {
    background: var(--grayBg);
    margin: 0;
}

.calculatorInnerBoxColumn .buttonIconStyle.buttonStyle {
    border-radius: 10px;
}

/* Calculator Component */

/* PaymentmethodBox */
.paymentmethodBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.paymentmethodBox .paymentmethodButton {
    width: 23%;
    padding: 10px !important;
}

.paymentmethodButton .verticalButtonIcon {
    margin: 5px;
}

.paymentmethodButton .buttonIcon {
    margin-right: 10px;
}

/* PaymentmethodBox */

.amountInputBox {
    height: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.amountInputBox .newposSearchBar {
    height: 60px;
    border: 1px solid var(--barclaysBlue);
    border-radius: 10px;
}

.amountInputBox .newposSearchBar .customInputFeild {
    font-size: 24px;
    caret-color: var(--barclaysBlue);
}

.amountInputBox .newposSearchBar .customInputFeild::placeholder {
    color: var(--lightgrayfour);
    font-weight: 500;
    margin-bottom: 0;
}

/* Bill Amount Section */
.billAmountBoxMain {
    height: 74%;
    border-radius: 10px;
}

.rightPaneBottomRight {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 5px 0px 0px 15px;
}

.billAmountInnerRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 31%;
    padding: 5px 15px;
}

.billAmountInnerRowRight {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.billAmountInnerRowRight .headerTitleSeperator {
    margin: 0 10px;
}

.billAmountTopRow {
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.billAmountBottomRow {
    height: 30%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-top: 1px dashed;
    padding: 5px 20px;
}

/* Bill Amount Section */

/* Toast Box */
.toastMainBox {
    border: 1px solid;
    position: absolute;
    bottom: 20px;
    left: 20px;
}

.toastMainBox .toast-header button.btn-close {
    background: transparent;
}

.toastMainBox .toast-header button.btn-close::after {
    content: '';
    display: block;
    opacity: 1;
    background: url('../src/assets/icons/closeIcon.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 14px;
    width: 14px;
    position: relative;
    right: 3px;
}

.toastMainBox .toast-body {
    border-radius: 0 0 5px 5px;
}

.addSuccessfullyToast {
    width: max-content;
    background: #000000 !important;
    position: absolute;
    bottom: 30px !important;
    left: 1% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 2px solid var(--barclaysBlue);
}

.errorToastMessage {
    border-bottom: 2px solid var(--red);
}

.addSuccessfullyToast .toast-body {
    background: black;
    height: 40px !important;
    min-width: 320px;
    width: max-content;
    border-radius: 2px;
}

.addSuccessfullyToast .toast-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.toastHeadingBox {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.addSuccessfullyToast .toast-body p {
    font-weight: 400;
    font-size: 14px;
    margin-right: 10px;
}

.kdsToastBox {
    width: max-content;
    background: #000000 !important;
    position: absolute;
    bottom: 20px !important;
    left: 20px !important;
}

.kdsToastBox .toast-body {
    padding: 10px 20px;
    background: #000000 !important;
    border-radius: 12px;
}

.kdsToastBox .toast-body p {
    font-weight: 600;
}

.orderStatusToastBox {
    width: max-content;
    background: #000000 !important;
    position: absolute;
    top: 120px !important;
    right: 1% !important;
    height: min-content !important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
}

.orderStatusToastBox .toast-body {
    display: flex;
    gap: 15px;
    padding: 10px 10px;
    background: #000000 !important;
    border-radius: 2px;
    border-bottom: 1px solid var(--brightGreen);
}

.orderStatusToastBox .toast-body p {
    font-weight: 400;
}

/* Toast Box */

/* Image Uploader */
.profileImageView {
    border: none;
    width: 100%;
    height: 150px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.profileImageView img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
}

.imageEditBox {
    position: absolute;
    width: 100%;
    height: 40px;
    background: rgba(0, 0, 0, 0.678);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    bottom: 0px;
    margin: 0 auto;
    border-radius: 0 0 11px 11px;
    padding: 0 10px;
}

.imageEditBox p svg {
    position: relative;
    top: -2px;
}

/* Image Uploader */

/* Varaint Flow */
.optionPara {}

.optionPara a {
    text-decoration: none;
}

.optionFieldBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.optionFieldBox .form-group {
    width: 90%;
}

.addOptionButton {
    border: 1px solid var(--barclaysBlue);
    height: 60px;
    width: 60px;
    position: relative;
    top: -8px;
}

.optionListInner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: var(--blackBg);
    border: 1px solid;
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 12px 15px;
    width: 100%;
    height: auto;
}

.optionList {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.optionList::-webkit-scrollbar {
    width: 5px;
}

.optionList::-webkit-scrollbar-track {
    background: var(--blackBg);
}

.optionList::-webkit-scrollbar-thumb {
    background-color: var(--blackBg);
    border-radius: 6px;
    border: 3px solid var(--darkGrayTwo);
}

.optionListInner p {
    text-transform: capitalize;
}

.optionList .optionListInner:last-child {
    border-bottom: 1px solid;
}

.optionListInnerLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.colorBox {
    border: 1px solid;
    width: 25px;
    height: 20px;
    margin-right: 12px;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.variantsText {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 8px;
}

.variantsTextRight {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: 5px;
}

.variantButton {
    height: 30px;
    width: 60px;
    border-radius: 7px;
    font-weight: 500;
}

.vairantModalPopup .modal-dialog {
    max-width: 35%;
}

.modal-content .variantOptionBody {
    padding: 15px 25px;
    min-height: 300px;
}

.variantOptionFooter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
}

.variantOptionFooter button {
    width: 150px;
    text-transform: capitalize;
}

.optionActionBox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
}

.addOptioButton span {
    color: var(--barclaysBlue) !important;
    font-size: 16px;
}

.addOptioButton.buttonStyle {
    justify-content: flex-start;
}

.addOptioButton .verticalButtonIcon {
    border: none;
    border-radius: 0;
    margin-bottom: 0;
}

.variantOptionHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 25px;
    min-height: 60px;
}

.selectoptionListInner {
    border: 1px solid;
    margin: 20px 0 30px;
    padding: 10px;
    border-radius: 13px;
}

.selectoptionListHeader {
    min-height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0 15px;
}

.selectoptionListBody {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.allVaritionsBox {
    border-bottom: 1px solid;
    padding: 0px 15px;
}

.variantPopupCheckbox {}

.variantPopupCheckbox .checkBoxMainBox {
    margin-top: 15px;
    margin-bottom: 15px;
}

.variantsCheckboxList {}

.variantsCheckboxList .checkBoxMainBox {
    padding: 10px 15px;
    border-bottom: 1px solid var(--tableBorder);
}

.variationListTableHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    border: 1px solid;
}

.variationsHeaderColFirst.variationsHeaderColFirstLeft {
    justify-content: flex-start;
}

.variationsHeaderColFirst {
    width: 16%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: 47px;
}

.variationTableHeaderCol {
    padding: 10px 10px;
    border-right: 1px solid;
    text-align: center;
}

.variationListTableHeader .variationTableHeaderCol:last-child {
    border: none;
}

.tableBodyMain {
    width: 100%;
    overflow-y: auto;
    padding-bottom: 15px;
}

.tableBodyMain .variationListTableHeader {
    border-top: none;
}

.tableBodyMain .variationsHeaderColFirst {}

.variationListTableHeader .formGroupInner {
    margin: 0;
}

.variationListTableHeader .form-group .inputError {
    display: none;
}

.barcodeDivWrapper {
    display: flex;
}

.aesterisk {
    color: red;
    position: relative;
    left: 20px;
    margin: 0px;
}

.variationListTableHeader .formGroupInner .MuiFilledInput-root {
    min-height: 20px;
    height: auto;
    border-radius: 0;
    background: transparent;
    border: none;
}

.variationListTableHeader .formGroupInner .MuiFilledInput-input {
    padding: 2px 10px;
    text-align: center;
    color: var(--white);
}

.variantListBottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 70%;
}

.variantListBottomLeft {
    width: 50%;
    margin-right: 40px;
}

.variantListBottomRight {
    width: 50%;
    margin-left: 40px;
}

.selectedVariantsList {
    overflow: auto;
    scrollbar-width: 5px;
    scrollbar-color: var(--darkGrayTwo) var(--blackBg);
}

/*! Other Browser */
.selectedVariantsList {
    --scrollbarBG: var(--blackBg);
    --thumbBG: var(--darkGrayTwo);
}

.selectedVariantsList::-webkit-scrollbar {
    width: 5px;
}

.selectedVariantsList::-webkit-scrollbar-track {
    background: var(--blackBg);
}

.selectedVariantsList::-webkit-scrollbar-thumb {
    background-color: var(--blackBg);
    border-radius: 6px;
    border: 3px solid var(--darkGrayTwo);
}

.selectedVariantsListCol {
    width: 19%;
    padding: 12px 10px;
}

.selectedVariantsListLast.selectedVariantsListCol,
.selectedVariantsListLast {
    width: 5%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.selectedVariantsListHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    border-top: 1px solid;
    border-bottom: 1px solid;
}

.selectedVariantsListHeader .selectedVariantsListCol:first-child,
.variationListTableHeader .selectedVariantsListCol:first-child {
    text-align: left;
}

.selectedVariantsListHeader .selectedVariantsListCol,
.variationListTableHeader .selectedVariantsListCol {
    text-align: center;
}

.selectedVariantsList .variationListTableHeader {
    border-right: 0;
    border-left: 0;
    border-top: 0;
}

/* Varaint Flow */

/* Image Modal */
.imageModalMain .modal-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--white);
}

.imageModalMain .modalHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 65px;
    padding: 12px 15px;
}

.imageModalMain .modal-footer .buttonStyle {
    background: var(--red) !important;
    border: 1px solid var(--red) !important;
}

.imageModaImage {
    width: 400px;
    height: 400px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.imageModaImage img {
    width: 350px;
    height: 350px;
    object-fit: cover;
}

.uploadFIleCont {
    position: relative;
}

.imageModalMain .modal-footer .buttonStyle.uploadFIleCont {
    background: var(--brightGreen) !important;
    color: var(--white);
    border: 1px solid var(--brightGreen) !important;
}

.uploadFIleCont input[type='file'] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
}

/* Image Modal */

/* Modal Styling */
.modal-backdrop.show {
    opacity: 0.75;
}

.modal-content {
    background: var(--blackBg);
    border: 1px solid var(--white);
    border-radius: 30px;
}

.head-bottom-border {
    width: 92%;
    border-bottom: 1px solid var(--tableBorder);
}

.modal-head-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 25px;
    min-height: 80px;
}

.modalTitleName::before {
    display: inline-block;
    content: '';
    width: 8px;
    height: 2px;
    background: var(--white);
    position: relative;
    top: -5px;
    margin: 0 6px;
}

.modalHeaderRightSide {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 90%;
}

.modalHeaderRightSide .buttonStyle {
    min-width: 120px;
    height: 40px;
    padding: 0 15px;
    border-radius: 5px;
}

.modalHeaderCloseIcon {}

.modalHeaderCloseIcon svg {
    position: relative;
    top: -1px;
}

.modal-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    border: none;
    border-radius: 0 0 30px 30px;
}

.modal-footer button {
    width: 150px;
}

.secondaryCta {
    background: var(--white) !important;
    color: var(--brightGreen) !important;
    border-color: var(--white) !important;
}

.disableCta {
    background: var(--white) !important;
    color: var(--red) !important;
    border-color: var(--red) !important;
}

.grayColoredDisableCta {
    background: var(--white) !important;
    color: var(--lightGray) !important;
    border-color: var(--lightGray) !important;
}

.createOptionModal .modal-dialog {
    max-width: 100%;
    margin: 0;
}

.createOptionModal .modal-content {
    border: none;
    border-radius: 0;
    height: 100%;
}

.createOptionModal .modal-dialog-centered {
    min-height: 100%;
}

.selectCustomerModal {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.selectCustomerModal .modal-dialog {
    max-width: 80%;
    margin: 0 auto;
}

.modal-header {
    border-bottom: 1px solid var(--inputBorder);
}

.selectCustomerModal .modal-content {
    border: none;
    height: 90%;
    background-color: var(--blackBg);
    border-radius: 20px;
}

.selectCustomerModal .modal-dialog-centered {
    min-height: 100%;
}

.selectCustomerModal .modal-body {
    padding: 0 0;
}

.selectCustomerModal .topBarLeftSide .posSearchBar {
    margin-left: 0;
    width: 100%;
}

.selectCustomerModal .topBarMainCont {
    background: transparent !important;
}

.createCustomerModal .modal-dialog {
    max-width: 100%;
    margin: 0;
}

.createCustomerModal .modal-content {
    border: none;
    border-radius: 0;
    height: 100%;
}

.createCustomerModal .modal-dialog-centered {
    min-height: 100%;
}

.createCustomerModal .modal-body {
    padding: 0 0;
}

.createCustomerModal .topBarLeftSide .posSearchBar {
    margin-left: 0;
    width: 100%;
}

.createCustomerModal .topBarMainCont {
    background: transparent !important;
}

.createCustomerModal .modal-body .itemFields {
    height: 100% !important;
}

.refundModal .modal-header {
    padding: 20px 0px !important;
}

.refundModal .modal-header .modalHeaderLeftSide {
    padding: 0px !important;
}

.refundModal .modal-content {
    padding: 10px 30px !important;
}

.vairantModalPopup .loginFormBox .itemFields {
    height: auto !important;
}

.vairantModalPopup.categoryModal .modal-body .col-md-6 {
    width: 100%;
}

.messageModalBody {
    width: 100%;
    padding: 25px;
}

/*  Message Model new design */

/*  Message Model new design */
.messageModal {}

.messageModal .modal-content {
    border: none;
    border-radius: 10px;
    min-width: 540px;
}

.messageModal .modal-footer button {
    width: 100px;
    height: 40px;
    border-radius: 5px;
    font-size: 14px;
}

/* Modal Styling */

/* New Modal Styling */

.modal-dialog-scrollable .modal-body {
    padding: 0px;
}

.modal-dialog-scrollable .modal-body {
    overflow: hidden;
}

.modalHeaderLeftSide {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0 15px;
}

.moddalheaderleftIconBox {
    height: 30px;
    width: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
}

.modalHeaderRightSide {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0 15px;
}

.modalHeaderRightSide .addCustomerBtn {
    box-shadow: none;
}

.modalHeaderRightSide .addCustomerBtn .verticalButtonIcon {
    margin-top: 6px;
    justify-content: center;
}

.modal-footer {
    border: 0px;
    padding: 0px 20px 20px 0px;
}

.primaryCtaButton .buttonStyle {
    width: 100px;
    border-radius: 5px;
    height: 40px;
}

.cancelCtaButton .buttonStyle {
    width: 100px;
    border-radius: 5px;
    height: 40px;
}

.secondaryCtaButton .buttonStyle {
    width: 100px;
    border-radius: 5px;
    height: 40px;
    color: var(--barclaysBlue) !important;
}

.posAppmodalBottom {
    display: flex;
    flex-direction: row;
}

.selectCustomerModal .newposSearchBar {
    background-color: var(--darkgrayBorderbg);
    height: 60px;
}

.selectCustomerModal .newposSearchBar .customInputFeild::placeholder {
    color: var(--lightgrayfour);
    font-size: 16px;
    font-weight: 400;
}

.deliveryFloorModal {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.deliveryFloorModal .modal-content {
    border: none;
    height: 90%;
    background-color: var(--blackBg);
    border-radius: 20px;
    padding: 0px 30px;
}

.deliveryFloorModal .modal-content .modal-header {
    padding: 20px 0px;
}

.deliveryFloorModal .modal-head-wrapper {
    min-height: 50px;
    padding: 0px;
}

.deliveryFloorModal .modal-head-wrapper .modalHeaderLeftSide {
    padding: 0px;
}

.deliveryFloorModal .modal-body {
    margin-top: 40px;
}

.deliveryFloorModal .modal-dialog {
    max-width: 80%;
    margin: 0 auto;
}

.deliveryFloorModal .modal-dialog-scrollable .modal-body {
    overflow-y: auto;
}

.deliveryFloorModal .modal-dialog-centered {
    min-height: 100%;
}

.modal-backdrop-custom {
    background-color: rgba(0, 0, 0, 0.5);
}

/* New Modal Styling */

/* Session Screen */

.calanderfeild {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-top: 1px solid var(--inputBorder);
    padding: 25px 0px;
    margin: 25px 0px;
}

/* Session Screen */

/* Pos Pin Screen */
.pinModalContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.posPinField .formGroupInner .MuiFilledInput-input {
    caret-color: var(--barclaysBlue);
    font-size: 14px;
}

.pinCodeCalculatorBox {
    padding: 0px 0px;
}

.pinCodeCalculatorBox .calculatorInnerBoxColumn button {
    height: 7.5vh;
}

/* Pos Pin Screen */

/* Item List Screen */
.itemListContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 10px;
}

.itemListContainer.orderDetailList {
    padding: 2px 0px 20px;
}

.paginationContainer {
    display: flex;
    justify-content: flex-end;
}

.paginationContainer button {
    color: #ffff;
}

.paginationContainer .MuiPaginationItem-root {
    color: #ffff !important;
}

.paginationContainer .MuiPaginationItem-root.Mui-selected {
    background-color: var(--barclaysBlue) !important;
}

/* Item List Screen */

/* Select Customer Modal */
.customerDetail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 90%;
}

.selectCustomerListBox {
    overflow: auto;
    scrollbar-width: 3px;
    /* scrollbar-color: var(--blueFont) var(--red); */
    padding: 10px;
}

.selectCustomerListBox .selectCustomerModal .modal-dialog-scrollable .modal-body {
    overflow-y: hidden;
}

.selectCustomerListBox {
    --scrollbarBG: var(--blackBg);
    --thumbBG: var(--darkGrayTwo);
}

.selectCustomerListBox::-webkit-scrollbar {
    width: 5px;
}

.selectCustomerListBox::-webkit-scrollbar-track {
    background: var(--posRightBg) !important;
}

.selectCustomerListBox::-webkit-scrollbar-thumb {
    background-color: var(--blackBg);
    border-radius: 6px;
    border: 3px solid var(--darkGrayTwo);
}

/*! Other Browser */
.tableListMainBox {
    --scrollbarBG: var(--blackBg);
    --thumbBG: var(--darkGrayTwo);
}

.tableListMainBox::-webkit-scrollbar {
    width: 5px;
}

.tableListMainBox::-webkit-scrollbar-track {
    background: var(--blackBg);
}

.tableListMainBox::-webkit-scrollbar-thumb {
    background-color: var(--blackBg);
    border-radius: 6px;
    border: 3px solid var(--darkGrayTwo);
}

.selectCustomerInnerRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid;
    margin: 5px 0px;
    padding: 12px 10px;
}

.selectCustomerInnerRow:hover {
    background-color: var(--darkBluishGray);
    cursor: pointer;
}

.customerInitials {
    width: 70px;
}

.customerInitials p {
    margin: 0;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    color: var(--white);
    font-size: 17px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.customerDetailsBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.customerDetailsBox span:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
}

.customeraction {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 5%;
}

/* Select Customer Modal */

/* Table */
.tableHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid;
    border-top: 0;
    border-left: 0;
    border-right: 0;
}

.threeColumn .BoxWidth {
    width: 50%;
}

.fourColumn .BoxWidth {
    width: 40%;
}

.fiveColumn .BoxWidth {
    width: 30%;
}

.sixColumn .BoxWidth {
    width: 22.5%;
}

.sevenColumn .BoxWidth {
    width: 18%;
}

.eightColumn .BoxWidth {
    width: 15%;
}

.nineColumn .BoxWidth {
    width: 12.5%;
}

.nineColumn .quickitemName {
    width: 12.5%;
}

.tenColumn .BoxWidth {
    width: 11.25%;
}

.tenColumn .quickitemName {
    width: 11.25%;
}

.fiveColumn .BoxWidth {
    width: 23%;
}

.eighteenColumn .BoxWidth {
    width: 5.555%;
}

.detailTbleColumn {
    padding: 0 6px;
}

.detailTbleColumn .BoxWidth {
    width: 20%;
}

.detailTbleColumn .BoxWidth:first-child {
    width: 40%;
}

.detailTbleColumn .itemListImage {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: var(--white);
    padding: 5px;
}

.detailTbleColumn .itemListBox p {
    text-transform: capitalize;
}

.BoxWidth {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 18px 10px;
}

.BoxWidth p {
    margin: 0;
}

.BoxActionColumn p {
    margin-left: 15px;
    margin-right: 15px;
}

.justifyStart {
    justify-content: flex-start;
}

.justifyCenter {
    justify-content: center;
}

.justifyEnd {
    justify-content: flex-end;
}

.justifySpacebetween {
    justify-content: space-between;
}

.justifyevenly {
    justify-content: space-evenly;
}

.rowConatainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.alignCenter {
    align-items: center;
}

.checkboxCol {
    width: 15%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 18px 10px;
}

.actionCol {
    width: 5%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 9px 8px 9px 8px;
}

.actionCol p {
    width: 20px;
    height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}

.checkboxCol .checkBoxMainBox {
    margin-top: 0;
    margin-bottom: 0;
}

.checkboxCol .checkBoxMainBox input[type='checkbox'] {
    width: 20px;
    height: 20px;
}

.checkboxCol .checkBoxMainBox input[type='checkbox']:checked::before {
    transform: scale(0.87);
    position: relative;
    left: 1px;
    top: 0px;
}

.tableBody {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid;
    border-top: 0;
    border-left: 0;
    border-right: 0;
}

.actionCol .dropdown-menu a:nth-child(1) {
    color: var(--barclaysBlue);
}

.actionCol .dropdown-menu a:nth-child(3) {
    color: var(--red);
}

.quickItemBox {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid;
    border-top: 0;
    border-left: 0;
    border-right: 0;
}

.qucikItemPlus {
    width: 5%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 9px 8px 9px 0px;
}

.qucikItemPlus p {
    width: 24px;
    height: 24px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
}

.quickitemName {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 18px 10px;
    width: 18%;
}

.tableListMainBox {
    padding-bottom: 10px;
    overflow: auto;
    scrollbar-width: 5px;
    scrollbar-color: var(--darkGrayTwo) var(--blackBg);
}

/*! Other Browser */
.tableListMainBox {
    --scrollbarBG: var(--blackBg);
    --thumbBG: var(--darkGrayTwo);
}

.tableListMainBox::-webkit-scrollbar {
    width: 5px;
}

.tableListMainBox::-webkit-scrollbar-track {
    background: var(--blackBg);
}

.tableListMainBox::-webkit-scrollbar-thumb {
    background-color: var(--blackBg);
    border-radius: 6px;
    border: 3px solid var(--darkGrayTwo);
}

.itemListBox p {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.itemListImage {
    width: 40px;
    height: 40px;
    background: var(--white);
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
}

.itemListBox p span:nth-child(2) {
    margin-left: 10px;
}

.imageLabelLineTruncate {
    display: -webkit-box;
    max-width: 70%;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
    white-space: initial;
}

.amountIconContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 30%;
}

.cartItemAmountandIconBox {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.cartItemAmountandIconBox .amountBox {
    text-align: end;
    width: 70%;
}

.amountIconContainer .cartItemAmountandIconBox .iconConatiner {
    width: 40%;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: transparent;
    justify-content: space-evenly;
}

.modifierFieldWrapper {
    padding: 5px 20px;
    margin: 0px 20px;
    border-radius: 5px;
    border: 1px solid var(--posRightBg);
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.disabledModifierFieldWrapper {
    padding: 5px 15px 5px 30px;
    background: var(--darkBluishGray);
    display: flex;
    justify-content: space-between;
}

/* ================Qty Increament and Decrement Component================ */
.qtyIdealConatiner {
    background-color: var(--newposRightBg);
    border: 1px var(--darkgrayBorderbg) solid;
    height: 30px;
    width: 120px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.qtyIdealConatiner .buttonIconStyle {
    margin: 0px;
}

.updateQtyinput {
    margin-bottom: 0px;
    color: var(--white);
    background-color: transparent;
    border: none;
    outline: none;
    width: 40px;
    text-align: center;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: textfield;
    caret-color: var(--barclaysBlue);
}

.updateQtyinput::-webkit-outer-spin-button,
.updateQtyinput::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.updateQtyinput::-moz-focus-inner {
    border: 0;
}

/* ================Qty Increament and Decrement Component================ */

/*====Pos Payment Section==== */
.posPaymentContainer {
    display: flex;
    flex-direction: column;
    padding: 30px 30px 0px 30px;
}

.pospaymentScreenAmountBox {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 30px 30px;
}

.orderNoteWrapper {
    background: var(--topBarBG);
    border: 1px solid var(--posRightBg);
    border-radius: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    padding: 10px 10px;
}

.orderNoteWrapper svg {
    cursor: pointer;
    width: 3%;
}

.orderNoteWrapper p {
    width: 95%;
}

.billAmountBoxMain {
    padding: 10px 0;
    border: 1px var(--darkgrayBorderbg) solid;
    border-radius: 10px;
}

/*====POS BIll AmountBox==== */

.billAmountTotal {
    border-bottom: 1px var(--darkgrayBorderbg) solid;
    padding: 10px 20px;
}

.billAmountRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.posPaymentBtnContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 4px;
    padding-right: 4px;
    justify-content: space-between;
    min-height: 60px;
    padding: 20px 0;
}

.amountContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.posPaymentContainer .posPaymentBtnContainer span {
    width: 49%;
}

.posPaymentBtnContainer .buttonPrimary {
    border: 1px var(--barclaysBlue) solid !important;
}

/*====POS BIll AmountBox==== */

/*====Pos Payment Section==== */

/* Table */

/* Customer Detail Modal */
.customerDetailTabs .MuiPaper-root {
    background: var(--blackBg) !important;
}

.customerDetailTabs .posTabBox .MuiPaper-elevation1 {
    border-bottom: 1px solid var(--posRightBg);
}

.customerTabBody {
    padding: 10px 20px;
    margin: 0 20px;
    height: calc(100vh - 180px);
}

.customerDetailTabs .posTabBox {
    padding: 10px 10px;
}

.customerDetailTabs .posTabBox .MuiTab-root {
    width: 200px;
}

.customerDetailBotom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.namePhoneCol {
    margin: 0px 0 45px;
}

.detailBotCol {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 10px 0;
}

.detailBotCol:nth-child(1) {
    width: 28%;
}

.detailBotCol:nth-child(2) {
    width: 25%;
}

.detailBotCol:nth-child(3) {
    width: 34%;
}

/* Customer Detail Modal */

/* Transaction Successful Screen */
.transactionSuccessfulModal .modal-dialog {
    max-width: 100%;
    margin: 0;
}

.transactionSuccessfulModal .modal-content {
    border: none;
    border-radius: 0;
    height: 100%;
}

.transactionSuccessfulModal .modal-dialog-centered {
    min-height: 100%;
}

.transactionSuccessfulModal .modal-header {
    display: none;
}

.transactionSuccessfulModal .modal-body {
    padding: 0 0;
}

.transactionScreenMain {
    height: 100%;
    padding: 50px 15px 40px;
}

.transactionScreenInner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: calc(100vh - 80px);
}

.transactionScreenInner .inputFormGroupInner {
    padding: 0 10px;
}

.headerIcon {
    padding: 0 15px;
    height: 10%;
    width: 100%;
    position: absolute;
    top: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.transactionTopBox {
    height: 29%;
    width: 100%;
    padding-top: 25px;
}

.transactionMainConatiner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.transactionMainConatiner .card {
    background-color: white;
    min-width: 500px;
    min-height: 464px;
    padding: 45px 40px;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.transactionBottomBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
}

.transactionBottomBox .buttonStyle {
    height: 60px;
    width: 400px;
}

.receiptBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    align-self: center;
    height: 60px;
    margin-top: 10px;
}

.receiptBox button {
    width: 30%;
    height: 100%;
}

.receiptBox .buttonStyle {
    border-radius: 12px;
}

.receiptBox .newposSearchBar {
    width: 100%;
    height: 100%;
    border: 1px solid var(--blackBg);
    border-radius: 10px;
    margin-right: 10px;
}

.receiptBox .newposSearchBar .customInputFeild {
    color: var(--blackFont);
    font-size: 14px;
    font-weight: 500;
}

.receiptBox .formGroupInner .MuiFilledInput-root {
    min-height: 65px;
    border-radius: 12px 0 0 12px;
    background: transparent;
    border: 1px solid var(--white);
}

.receiptBox .formGroupInner {
    width: 100%;
}

.receiptBox .formGroupInner .MuiFormControl-root {
    margin-top: 5px;
}

.receiptBox .formGroupInner .MuiFilledInput-input {
    color: var(--white);
}

.receiptBox .form-group {
    width: 65%;
}

.receiptBox .formGroupInner .MuiInputLabel-filled {
    color: var(--white);
}

/* Transaction Successful Screen */

/* Discount Popup */
.discountPopupButtonMain {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 15px 0;
}

.discountPopupButton {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
}

/* Discount Popup */

/* Order Detail Screen */
.orderDetailMainBox {
    overflow-y: scroll;
    --scrollbarBG: var(--blackBg);
    --thumbBG: var(--darkGrayTwo);
}

.orderDetailMainBox::-webkit-scrollbar {
    width: 5px;
}

.orderDetailMainBox::-webkit-scrollbar-track {
    background: var(--topBarBG);
}

.orderDetailMainBox::-webkit-scrollbar-thumb {
    background-color: var(--blackBg);
    border-radius: 6px;
    border: 3px solid var(--darkGrayTwo);
}

.orderDetailMainBox .tableListMainBox {
    /* height: 52%; */
}

.orderDetailBottomLeft {
    height: 40%;
    width: 100%;
    padding: 12px 6px;
}

.orderDetailRightSide {
    height: 100%;
    border: 1px solid var(--inputBorder);
    border-right: 0px;
    border-bottom: 0px;
    border-top: 0px;
}

.orderDetailRightSide .tableListMainBox {
    height: 100%;
}

.billHeading {
    padding: 22px 25px;
    margin: 0;
}

.orderDetailCustomerBox {
    width: 100%;
    padding: 0px 25px 10px;
    overflow: auto;
    scrollbar-width: 5px;
    scrollbar-color: var(--darkGrayTwo) var(--blackBg);
}

/*! Other Browser */
.orderDetailCustomerBox {
    --scrollbarBG: var(--blackBg);
    --thumbBG: var(--darkGrayTwo);
}

.orderDetailCustomerBox::-webkit-scrollbar {
    width: 5px;
}

.orderDetailCustomerBox::-webkit-scrollbar-track {
    background: var(--blackBg);
}

.orderDetailCustomerBox::-webkit-scrollbar-thumb {
    background-color: var(--blackBg);
    border-radius: 6px;
    border: 3px solid var(--darkGrayTwo);
}

.orderDetailCustomerBox .customerDetailBotom {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.orderDetailCustomerBox .detailBotCol {
    width: 100%;
}

.orderDetailCustomerBox .namePhoneCol {
    margin: 5px 0 10px;
}

.amountDetailsBox {
    background-color: var(--newposRightBg);
    width: 100%;
    margin: 0 auto;
    border-radius: 10px;
    margin-top: 40px;
    margin-bottom: 15px;
    padding: 20px;
}

.amountDetailsRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.amountDetailsRow p {
    margin-bottom: 0px;
    font-size: 14px;
    font-weight: 400px;
    color: var(--white);
}

.orderDetailsLoyalityBox {
    display: flex;
    flex-direction: row;
    padding: 5px;
    margin: 5px 0px;
    border-bottom: 1px solid;
    border-color: var(--inputBorder);
}

.loyaltySeperator {
    display: block;
    content: '';
    height: 2px;
    width: 100%;
    background: var(--seperatorBg);
    margin: 15px 0 15px;
}

.orderPaymentStatus {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.tickIcon {
    width: 24px;
    height: 24px;
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

.orderPaymentStatusLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.refundBtn.buttonStyle {
    background: transparent !important;
    color: var(--barclaysBlue) !important;
    width: 130px;
    height: 45px;
    border-color: var(--white) !important;
}

.orderNoteField {
    margin: 18px 0 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.orderDetailHistory {
    margin-top: 20px;
}

.orderDetailHistoryList {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.orderDetailHistoryListInner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-top: 1px solid;
    padding: 15px 0;
}

/* Order Detail Screen */

/* ---------------  Employee -------------------- */

/* Cnic upload  */

.cnicImageUpload {
    width: 100%;
    height: 147px;
    padding: 0px;
    border-radius: 50%;
    background: transparent;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

/* Cnic upload  */
.cnicUploadConatiner {
    width: 100%;
    height: 50px;
    border-radius: 12px;
    background: var(--blackPearl);
    border: 1px solid var(--inputBorder);
    cursor: pointer;
}

.cnicUploadLeft {
    width: 40%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.cnicUploadRight {
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.cnicUploadConatiner input {
    display: none;
}

.cnicUploadConatiner label {
    width: 100%;
    height: 100%;
    cursor: pointer;
    padding: 0 14px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.tickIconBg {
    height: 14px;
    width: 14px;
    border-radius: 7px;
    background: var(--brightGreen);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 15px;
    bottom: -2px;
}

/* team member permision tiles */
.teamFirstBox.intventoryFormGridTwo {
    align-items: flex-start;
}

.teamFirstInput {
    position: relative;
    top: -15px;
}

.cnicUploadBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
}

.cnicUploadBox .cnicUploadConatiner:nth-child(1) {
    margin-right: 15px;
}

.teamPermissioncContainer {
    display: flex;
    flex-direction: row;
    padding: 30px 5px;
}

.teamPermissionleft {
    display: flex;
    flex-direction: row;
    width: 15%;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
}

.teamPermissionRight {
    display: flex;
    flex-direction: column;
    width: 75%;
    margin-left: 20px;
}

.dashBorder {
    border: 1px dashed white;
    border-radius: 12px;
    padding: 10px;
}

.itemSeperator2 {
    content: '';
    height: 2px;
    width: 100%;
    display: flex;
    background: var(--topBarBG);
    margin: 0px 0px 0;
    border-radius: 20px;
}

.selectAccess {
    margin: 10px;
    display: flex;
    align-items: center;
    gap: 100px;
    color: var(--white);
    margin-left: 106px;
}

.permissionTilesfooter {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.permissionTilesLeft {
    width: 42%;
}

.threeCheckBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

/* team member permision tiles */

/* switch button  */

.permissionOptionMain {
    border-radius: 12px;
    padding: 0 10px;
}

.permissionOptionBorder {
    border: 1px dashed white;
    margin-bottom: 25px;
}

.permissionOptionNoBorder {}

.dashBorder {}

.form-switch .form-check-input {
    width: 54px;
    height: 29px;
    background-color: var(--darkGray);
    border-color: transparent;
}

.teamPermissioncContainer input[type='checkbox']::before {
    display: none;
    outline: none;
}

.form-switch .form-check-input,
.form-switch .form-check-input:focus {
    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e');
}

.teamPermissioncContainer input.form-check-input:focus {
    outline: none;
    box-shadow: none;
}

.form-check-input:checked {
    background-color: var(--brightGreen);
}

/* switch button  */

/* ---------------  Employee -------------------- */
/* PO */
.itemDetailHeading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.itemSeperator.itemDetailListSep {
    margin: 15px 0 20px;
}

.potableColume .BoxWidth {
    padding: 0px 0px;
    margin-bottom: 5px;
    margin-top: 5px;
}

.potableColume .BoxWidth:nth-child(1) {
    width: 35%;
}

.potableColume .BoxWidth:nth-child(2) {
    width: 8%;
}

.potableColume .BoxWidth:nth-child(3) {
    width: 12%;
}

.potableColume .BoxWidth:nth-child(4) {
    width: 10%;
}

.potableColume .BoxWidth:nth-child(5) {
    width: 10%;
}

.potableColume .BoxWidth:nth-child(6) {
    width: 15%;
}

.potableColume .BoxWidth:nth-child(7) {
    width: 10%;
}

.potableColume .BoxWidth .formGroupInner .MuiFilledInput-root {
    border-radius: 0;
}

.potableColume .BoxWidth:nth-child(7) .formGroupInner .MuiFilledInput-root {
    border-radius: 0px 12px 12px 0px;
}

.potableColume .BoxWidth:nth-child(1) .formGroupInner .MuiFilledInput-root {
    border-radius: 12px 0px 0px 12px;
}

.potableColume .BoxWidth p.inputError {
    display: none;
}

.pofooterbtn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

/* .potableColume .formGroupInner .MuiFilledInput-root.Mui-disabled,
.potableColume .formGroupInner .MuioutlinedInput-root.Mui-disabled {
    min-height: 60px;
    background-color: var(--seperatorTwo);
    opacity: 1;
} */

.disableFields {
    background-color: var(--seperatorTwo);
}

/* PO */

/* Date Picker box */
.datePickerBox {
    position: relative;
}

.datePickerBox .MuiIconButton-root svg path {
    display: none;
}

.MuiDateCalendar-root {
    background-color: var(--blackPearl) !important;
    color: var(--white) !important;
    border-radius: 12px;
    max-height: 350px !important;
    width: 365px !important;
    border: 1px solid var(--inputBorder) !important;
}

.MuiPickersPopper-paper {
    background: none !important;
    margin: 10px 0px !important;
}

.MuiPickersArrowSwitcher-button,
.MuiPickersCalendarHeader-switchViewIcon,
.MuiDayCalendar-weekDayLabel,
.MuiPickersDay-dayWithMargin {
    color: var(--white) !important;
}

.MuiPickersDay-root.Mui-selected {
    background-color: var(--brightGreen) !important;
}

.MuiButtonBase-root.MuiPickersDay-root:not(.Mui-selected) {
    border-color: var(--white) !important;
    color: var(--white) !important;
}

.MuiButtonBase-root.MuiPickersDay-root.Mui-disabled {
    color: var(--darkGrayTwo) !important;
}

.datePickerIcon {
    position: absolute;
    right: 12px;
    pointer-events: none;
}

.MuiYearCalendar-root {
    margin-left: 20px;
}

.MuiYearCalendar-root {
    --scrollbarBG: var(--blackBg);
    --thumbBG: var(--darkGrayTwo);
}

.MuiYearCalendar-root::-webkit-scrollbar {
    width: 5px;
}

.MuiYearCalendar-root::-webkit-scrollbar-track {
    background: var(--blackBg);
}

.MuiYearCalendar-root::-webkit-scrollbar-thumb {
    background-color: var(--blackBg);
    border-radius: 6px;
    border: 3px solid var(--darkGrayTwo);
}

.MuiPickersYear-yearButton.Mui-selected {
    background-color: var(--blackBg) !important;
}

/* Date Picker box */
/* PO Amount Box */
.poAmountboxmain {
    height: 100%;
    border-radius: 10px;
    border: 1px solid;
    border-color: var(--inputBorder);
}

.poAmountboxmain .billAmountInnerRow {
    margin-top: 10px;
    margin-bottom: 0px;
}

.poAmountBottomRow {
    /* height: 30%; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-top: 1px solid;
    border-radius: 0px 0px 9px 9px;
    padding: 10px 10px;
    margin-top: 12px;
}

/* PO Amount Box */

/* ----------------send PO------------------  */
.inputRowConatiner {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.sendButtonBox {
    width: 140px;
    display: flex;
    flex-direction: row;
}

.poSendInput .priceSectionInputLeft {
    width: 35%;
}

.poSendInput .priceSectionInputRight {
    width: 65%;
}

/* ----------------send PO------------------  */

/* Container */
.rowContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

/* Container */

/* POS Tax Modal */
.posTaxModal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.posTaxModal .discountPopupButtonMain {
    width: 100%;
    justify-content: space-between;
}

.selectCustomerModal .addScreenMainBox {
    padding-bottom: 50px;
}

.modal-dialog-scrollable .modal-body {
    scrollbar-width: 5px;
    scrollbar-color: var(--darkGrayTwo) var(--blackBg);
}

/*! Other Browser */
.modal-body {
    --scrollbarBG: var(--blackBg);
    --thumbBG: var(--darkGrayTwo);
}

.modal-body::-webkit-scrollbar {
    width: 5px;
}

.modal-body::-webkit-scrollbar-track {
    background: var(--blackBg);
}

.modal-body::-webkit-scrollbar-thumb {
    background-color: var(--blackBg);
    border-radius: 6px;
    border: 3px solid var(--darkGrayTwo);
}

.modal-body .loginFormBox {
    overflow: hidden;
    height: 100%;
}

/* POS Tax Modal */

/* ---------------Upload CSV----------- */
.importLibararyMainBox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.fileNameBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 1px var(--inputBorder) solid;
    border-radius: 10px;
    padding: 15px;
}

.fileNameBox p {
    margin-bottom: 0px;
}

.csvSeletedBox {
    border: 1px dashed var(--barclaysBlue);
    border-radius: 16px;
    width: 400px;
    height: 375px;
    text-align: center;
    background-color: var(--blackPearl);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.csvSeletedInsideBox {
    width: 90%;
    padding: 10px;
    font-weight: 400;
}

.csvSeletedInsideBox p:nth-child(2) {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
}

.csvSeletedInsideBox p:nth-child(3) {
    font-size: 12px;
    font-weight: 400;
}

.csvSeletedInsideBox p:nth-child(4) {
    font-size: 12px;
    font-weight: 400;
}

.uploadphotoBtn {
    position: relative;
    overflow: hidden;
    display: inline-block;
    width: 80%;
    margin: 0 auto;
}

.footerButtonBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
}

.uploadphotoBtn .btn {
    background-color: var(--brightGreen);
    color: var(--white);
    padding: 10px 0;
    border-radius: 5px;
    font-size: 16px;
    font-weight: '500';
    width: 100%;
}

.uploadphotoBtn input[type='file'] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    -webkit-appearance: button;
    appearance: button;
    cursor: pointer;
}

[type='file']::-webkit-file-upload-button {
    -webkit-appearance: button;
    display: none;
}

.changefileBtn {
    position: relative;
    overflow: hidden;
    display: inline-block;
    width: 50%;
}

.changefileBtn .btn {
    background-color: var(--brightGreen);
    color: var(--white);
    padding: 10px 0;
    border-radius: 10px;
    font-size: 16px;
    font-weight: '500';
    width: 100%;
}

.changefileBtn .btn {
    background-color: transparent;
    color: var(--barclaysBlue);
    padding: 10px 0;
    border-radius: 10px;
    font-size: 16px;
    border-color: var(--barclaysBlue);
    font-weight: '500';
    width: 100%;
}

.changefileBtn input[type='file'] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    -webkit-appearance: button;
    appearance: button;
    cursor: pointer;
}

/* ---------------Upload CSV----------- */

/* image picker */
.imagePickerConatiner {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.imagePickerConatiner input {
    display: none;
}

.imagePickerConatiner label {
    padding: 3px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.OrderCalenderConatiner {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: 8px;
}

.orderCalender {
    position: absolute;
    z-index: 1;
    right: 0px;
}

.customerCalenderConatiner {
    position: relative;
}

.customerCalender {
    position: absolute;
    z-index: 1;
    right: 0px;
}

.PickersLayoutRoot {
    overflow: auto;
    display: grid;
    grid-template-columns: 24% 76%;
    color: #ffff;
}

/* KDS */
.headSeperator {
    display: block;
    content: '';
    height: 1px;
    width: 100%;
    background: var(--seperatorBg);
    margin: 15px 0 15px;
}

.kdsHeader .customDropDown .dropdown-toggle {
    height: 42px;
    margin: 0px 10px;
}

.notificationHeadSeperator {
    display: block;
    content: '';
    height: 1px;
    width: 100%;
    background: #31384f;
    margin: 15px 0 15px;
}

.addDeviceBox {
    height: 120px;
    width: 160px;
    border-radius: 12px;
    display: flex;
    background: #ffff;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 4px;
}

.createCustomerModal .loadingScreenMain {
    background: var(--blackBg) !important;
}
.createCustomerModal .loadingScreenMain-Light {
    background: var(--blackBg) !important;
}


.generateCodeLoader .loadingScreenMain {
    height: 600px;
}
.generateCodeLoader .loadingScreenMain-Light {
    height: 600px;
}

.createDeviceWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
}

.createDeviceFieldsWrapper {
    width: 35%;
}

.thirdPartIntegrationWrapper {
    width: 30%;
}

.deviceComponentWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.deviceBox {
    height: 550px;
    width: 750px;
    background: var(--topBarBG);
    padding: 40px 30px;
}

.deviceListWrapper {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    margin-bottom: 20px;
}

.kdsIconBox {
    height: 60px;
    width: 60px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #343843;
}

.copyCodeBox {
    height: 60px;
    width: 40%;
    background: #343843;
    border-radius: 12px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.pinWrapper {
    display: flex;
    justify-content: flex-end;
}

.copyPinCodeBox {
    height: 60px;
    width: 80%;
    background: #343843;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.eyeIconWrapper {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
}

/* KDS */

/* KDS Order Screen */
.kdsPageWrapper {
    height: 100vh;
}

.kdsPageWrapper::-webkit-scrollbar {
    width: 5px;
}

.kdsPageWrapper::-webkit-scrollbar-track {
    background: var(--orderItemsWrapper);
}

.kdsPageWrapper::-webkit-scrollbar-thumb {
    background-color: var(--blackBg);
    border-radius: 6px;
    border: 3px solid var(--darkGrayTwo);
}

/* OPT Component */
.otpWrapper {
    position: relative;
}

.otpWrapper div {
    display: flex;
    justify-content: center;
}

.otpWrapper div input:nth-child(n) {
    /* Styles for all inputs */
    border: none;
}

.otpWrapper div input:focus {
    /* Styles for all inputs */
    border: none;
    outline: none;
}

.otpWrapper div input:nth-child(1) {
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
}

.otpWrapper div input:nth-child(12) {
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}

.otpWrapper div input:nth-child(4),
.otpWrapper div input:nth-child(8) {
    border-right: 1px solid #c5c5c5 !important;
    border-image: linear-gradient(to bottom, white, #b46f6f, white) 1;
}

/* OTP Component */

.headerIconBox {
    height: 40px;
    padding: 0px 10px;
    background: #242a3b;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.notificationCount {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: var(--red);
    position: absolute;
    left: 30px;
    bottom: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Customer Detail Modal */

.kitchendDisplayTabs .posTabBox .MuiPaper-elevation1 {
    border-bottom: 2px solid var(--posRightBg);
}

.kitchendDisplayTabBody {
    margin: 0px;
}

.kitchenOrderListContainer::-webkit-scrollbar {
    width: 5px;
}

.kitchenOrderListContainer::-webkit-scrollbar-track {
    background: var(--blackBg);
}

.kitchenOrderListContainer::-webkit-scrollbar-thumb {
    background-color: var(--blackBg);
    border-radius: 6px;
    border: 3px solid var(--darkGrayTwo);
}

.kitchenOrderListWrapper {
    padding: 10px 20px;
}

.kitchenOrderListWrapper .allScreenNonIdealBox {
    background-color: var(--topBarBG) !important;
}

.kitchenOrderListWrapper .allScreenNonIdealInner {
    height: calc(100vh - 200px);
}

.orderItemsWrapper::-webkit-scrollbar {
    width: 5px;
}

.orderItemsWrapper::-webkit-scrollbar-track {
    background: var(--orderItemsWrapper);
}

.orderItemsWrapper::-webkit-scrollbar-thumb {
    background-color: var(--blackBg);
    border-radius: 6px;
    border: 3px solid var(--darkGrayTwo);
}

/* KDS Order Screen */

/* Kitched Order Card */
.orderCardWrapper {
    border-radius: 5px;
    width: 100%;
    background: #ffff;
}

.orderCardHeader {
    height: 80px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    padding: 15px;
}

.durationPill {
    padding: 5px 10px;
    border-radius: 5px;
}

.orderDelivery {
    height: 60px;
    padding: 0px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.orderId p {
    max-width: 150px;
}

.orderItemsWrapper {
    padding: 0px 15px;
    overflow: auto;
    scrollbar-width: 5px;
    scrollbar-color: var(--darkGrayTwo) var(--blackBg);
}

.orderItemsWrapper::-webkit-scrollbar {
    width: 5px;
}

.orderItemsWrapper::-webkit-scrollbar-track {
    background: var(--orderItemsWrapper);
}

.orderItemsWrapper::-webkit-scrollbar-thumb {
    background-color: var(--blackBg);
    border-radius: 6px;
    border: 3px solid var(--darkGrayTwo);
}

.cardFooter {
    margin-top: 10px;
    padding: 0px 15px;
    height: 60px;
}

/* Kitched Order Card */

/* New Dashboard Styling */
.dashboardBannerMain {
    /* background: var(--blueOne); */
    background-image: linear-gradient(to right, var(--blueOne), var(--blueTwo));
    min-height: 135px;
    border-radius: 14px;
    padding: 20px 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.dashBannerLeft {
    width: 55%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.dashBannerLeft.dashBannerLeft h1 {
    letter-spacing: 1.5px;
}

.dashBannerLeft .buttonStyle {
    min-width: 265px;
}

.dashBannerRight {
    width: 45%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
     position: absolute;
    right: 30px;
    top: 4px; 
}

.dashBannerRight img {
    min-width: 313px;
    min-height:140px;
}

.salesByPaymentBox {
    margin-bottom: 50px;
}

.salesByPaymentBoxHeadinBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.headingViewBtn {
    height: 40px;
    width: 150px;
    flex-direction: row-reverse;
    margin-bottom: 15px;
    margin-top: -10px;
}

.headingViewBtn .buttonIcon {
    margin-right: 0;
    margin-left: 10px;
}

.salesByPaymentBoxInner {
    background: var(--blackBg);
    border: 1px solid var(--blackPearlTwo);
    border-radius: 14px;
    min-height: 420px;
    padding: 20px 20px;
}

.pendingPaymentWrapper {
    overflow: auto;
}

.salesByPaymentBoxInner .buttonStyle {
    min-width: 250px;
}

.salesByPaymentBox h2 {
    margin-bottom: 25px;
}

.dashboardListIcon {
    content: '';
    width: 21px;
    height: 15px;
    background: url('../src/assets/images/bannerListIcon.png');
    display: inline-block;
    margin-right: 10px;
}

.dashboardListIconGreen {
    content: '';
    width: 24px;
    height: 24px;
    background: url('../src/assets/images/bannerListIconGreen.png');
    display: inline-block;
    margin-right: 10px;
}

.dashboardBannerListData {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.dashboardBannerListData ul {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
}

.dashboardBannerListData ul li {
    list-style: none;
    width: 50%;
    font-weight: 500;
    font-size: 14px;
    color: var(--white);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 14px;
}

.salesByPaymentBoxInner .tableHeader {
    background: var(--blackPearlTwo);
    border-radius: 12px;
}

.salesByPaymentBoxInner .tableHeader .BoxWidth {
    padding: 15px 20px;
}

.salesByPaymentBoxInner .tableBody {
    /* border: 0; */
}

.salesByPaymentBoxInner .tableBody:last-child {
    border: 0;
}

.salesByPaymentBoxInner .tableBody:last-child .BoxWidth {
    padding: 20px 20px 20px;
}

.salesByPaymentBoxInner .tableBody .BoxWidth {
    padding: 20px 20px;
}

.salesByPaymentBoxInner::-webkit-scrollbar {
    width: 5px;
}

.salesByPaymentBoxInner::-webkit-scrollbar-track {
    background: var(--orderItemsWrapper);
}

.salesByPaymentBoxInner::-webkit-scrollbar-thumb {
    background-color: var(--blackBg);
    border-radius: 6px;
    border: 3px solid var(--darkGrayTwo);
}

.onlinePresenceMainWrapper .salesByPaymentBoxInner {
    background: var(--blackPearl);
}

.onlinePresenceInner {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.onlinePresenceInner .imageBox {
    width: 100%;
}

.onlinePresenceInner .imageBox img {
    width: 100%;
    height: 100% !important;
}

.metricsBoxInner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    height: 50px;
    margin: 10px 0px;
    background-color: var(--topBarBG);
    border-radius: 6px;
}

.keyMetricsWrapperBox {
    display: flex;
    flex-direction: row;
    height: 100%;
}

.keyMetricsWrapper .metricsBoxInner {
    min-height: 60px;
    padding: 10px 10px 10px;
}

.keyMetricsWrapper .metricsBoxInner:last-child {
    border-bottom: 0;
}

.keyMetricsWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    padding-bottom: 0;
    height: 100%;
}

.UpgradedOscarPlan {}

.UpgradedOscarPlan .salesByPaymentBoxInner {
    background: var(--lightGreenTwo);
}

.Walletonboarding {}

.Walletonboarding .salesByPaymentBoxInner {
    background-image: url('./assets/images/authBg.png');
    background-size: cover;
    background-position: center;
}



.Walletonboarding .walletCard p {
    margin-top: 0px;
    
}

.viewDataModal {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.viewDataModal .modal-dialog {
    max-width: 100%;
    margin: 0 auto;
}

.viewDataModal .modal-header {
    border-bottom: 0;
    height: 45px;
    padding: 0 0;
}

.viewDataModal .modal-content {
    border: none;
    height: 100%;
    background-color: var(--topBarBG);
    border-radius: 0px;
}

.viewDataModal .modal-dialog-centered {
    min-height: 100%;
}

.viewDataModal .modal-body {
    padding: 0 0;
    /* background: var(--topBarBG); */
    margin: 20px 20px;
}

.viewDataModal .topBarLeftSide .posSearchBar {
    margin-left: 0;
    width: 100%;
}

.viewDataModal .topBarMainCont {
    background: transparent !important;
}

.ViewDataBox {
    border-radius: 10px 10px;
}

.unmacthedSalesBoxInner img {
    width: 70%;
}

.unmacthedSalesWrapper .salesByPaymentBoxInner {
    height: 450px;
    background: var(--blackPearl);
}

.unmacthedSalesBoxInner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 100%;
}

.donutTableBody {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 55%;
}

.donutTableBody .tableHeader,
.donutTableBody .tableBody {
    width: 100%;
}

.donutTableBody .tableHeader.fourColumn .BoxWidth:nth-child(1),
.donutTableBody .tableBody.fourColumn .BoxWidth:nth-child(1) {
    width: 10%;
    padding: 20px 5px;
}

.donutTableBody .tableHeader.fourColumn .BoxWidth,
.donutTableBody .tableBody.fourColumn .BoxWidth {
    width: 30%;
}

.donutTableBody .tableHeader {
    background: transparent;
    border-radius: 0;
}

.donutTableBodyInner {
    overflow-x: auto;
    max-height: 345px;
    width: 100%;
}

.circleBox {
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background: var(--white);
}

.chart {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 376px;
}

.BarGraphBox canvas {
 width: 100% !important;
 height: 100% !important;
    padding-top: 20px;
}

.chartDonutBox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 45%;
    position: relative;
}

.chartDonutBox canvas {
    width: 85% !important;
    height: 85% !important;
}

.chartText {
    position: absolute;
    width: 50%;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 500;
    text-transform: capitalize;
    color: var(--white);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
}

.salesByPaymentBoxInner .posTabBox .MuiPaper-root {
    background: transparent !important;
}

.salesHourWrapper .salesByPaymentBoxInner {
    padding: 20px 0;
}

.salesHourWrapper .BarGraphBox {
    padding: 0 20px;
    max-height: 320px !important;
}

.salesHourWrapper .graphBoxNonIdeal{
   min-height: 320px !important;
}


.paymentNonIdeal .allScreenNonIdealBox {
    height: calc(100vh - 145px);
    padding: 20px 0;
}

.paymentNonIdeal .allScreenNonIdealInner .nonIdealSubHeadingBox {
    width: 50%;
}

.paymentNonIdeal {
    width: 100%;
}

.onlineNonIdeal {
    width: 100%;
    margin-top: 20px;
}

.onlineNonIdeal .allScreenNonIdealBox {
    /* height: calc(100vh - 302px); */
    height: calc(100vh - 205px);
    padding: 0px 0 20px;
}

.onlineNonIdeal .allScreenNonIdealInner .nonIdealSubHeadingBox {
    width: 50%;
}

.onlineNonIdeal {
    width: 100%;
}

/* New Dashboard Styling */

/* Dashboard Filter Box */
.dashboardFilterBox {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 50px;
    margin-top: 20px;
}

.dashboardFilterBoxLeft {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
}

.dashboardFilterBoxRight {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
}

.dashboardFilterBoxRight .locationDropDown {
    margin: 0px 15px;
}

.dashboardFilterBox .dahboardViewBox .MuiButtonBase-root {
    border: 1px solid var(--posRightBg);
    border-radius: 10px;
    padding: 0px 0px;
}

.dashboardFilterBox .dahboardViewBox .Mui-selected {
    border: 1px solid var(--barclaysBlue) !important;
}

.dashboardFilterBox .MuiTabs-flexContainer {
    gap: 10px;
}

.dashboardFilterBox .MuiTabs-indicator {
    display: none;
}

.dashboardFilterBox .MuiPaper-elevation1 {
    border-bottom: none;
}

.dahboardViewBox {
    /* width: 33%; */
    margin-right: 20px;
}

.dahboardViewBox .posTabBox .MuiTab-wrapper {
    font-size: 16px;
    font-weight: 400;
    text-transform: capitalize;
    color: var(--white);
}

.dahboardViewBox .formGroupInner .MuiAutocomplete-inputRoot[class*='MuiFilledInput-root'] .MuiAutocomplete-input {
    min-width: 100%;
}

.dahboardViewBox .inputError {
    display: none;
}

.dahboardViewBoxTwo {
    width: 50%;
}

.dashboardFilterBoxRight .reportsDropwdown {
    min-width: 235px;
    margin: 0;
}

.dashboardFilterBoxRight .reportsDropwdown .formGroupInner .MuiFilledInput-root {
    border: 1px solid var(--newBorderColor);
}

.dashboardFilterBoxRight .OrderCalenderConatiner {
    min-width: 155px;
    margin: 0;
    height: 50px;
}

.dashboardFilterBoxRight .OrderCalenderConatiner .summaryDateBox {
    margin: 0;
    width: 100%;
    height: 100%;
}

.dashboardFilterBoxRight .OrderCalenderConatiner .DatePickerContainer {
    min-width: 100%;
    height: 100%;
    padding-left: 45px;
}

.dashboardFilterBoxRight .OrderCalenderConatiner .rangePickerWrapper {
    top: 56px;
}

.dashboardFilterBox .formGroupInner .MuiFilledInput-root,
.dashboardFilterBox .formGroupInner .MuiOutlinedInput-root {
    min-height: 50px;
}

.dashboardFilterBoxRight .OrderCalenderConatiner .DatePickerCompareContainer {
    display: none;
}

.locationDropDown {
    min-width: 235px;
    position: relative;
}

.locationDropDown .customDropDown .dropdown-toggle {
    height: 50px;
    border: 1px solid var(--newBorderColor);
    border-radius: 10px;
    padding: 0px 8px;
    font-size: 14px;
    font-weight: 500;
    width: 100%;
    text-align: left;
}

.locationDropIcon {
    position: absolute;
    left: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
}

.dateDropIcon {
    position: absolute;
    left: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
}

.locationDropIcon b {
    font-weight: 400;
    font-size: 14px;
    color: var(--lightGrayTwo);
    margin-left: 12px;
}

.locationDropDown .customDropDown .dropdown-toggle {
    padding-left: 104px;
}

.locationDropDown .customDropDown .dropdown-toggle span:nth-child(2) {
    display: none;
}

.locationDropDown .customDropDown .dropdown-toggle.show {
    border: 1px solid var(--barclaysBlue);
}

.locationDropDown .customDropDown .dropdown-toggle svg {
    /* height: 14px;
    width: 14px; */
}

.locationDropDown .customDropDown {
    margin: 0 5px;
}

.locationDropDown .customDropDown .dropdown-menu .dropdown-item {
    max-height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.locationDropDown .customDropDown .dropdown-menu {
    max-height: 280px;
    overflow-x: auto;
}

.locationDropDown .customDropDown input[type='checkbox'] {
    position: relative;
    top: 1px;
}

.dashboardFilterBoxRight .OrderCalenderConatiner .DatePickerContainer svg {
    display: none;
}

.reportsWrapper {
    height: calc(100vh - 215px);
    padding-bottom: 10px;
    width: 100%;
    margin-top: 20px;
}

.reportsWrapper .loadingScreenMain {
    height: 100% !important;
    background: transparent !important;
}
.reportsWrapper .loadingScreenMain-Light {
    height: 100% !important;
    background: transparent !important;
}

.reportsWrapperInner {
    background: var(--blackBg);
    border-radius: 14px;
    height: 100%;
    padding: 20px 20px;
    position: relative;
}

.reportsWrapperInner .tableHeader .BoxWidth,
.reportsWrapperInner .tableBody .BoxWidth {
    padding: 10px 10px;
}

.reportsWrapperInner .itemsReportHeader {
    background: var(--blackPearlTwo);
    border-radius: 14px;
}

.saleKeyMetrics .salesByPaymentBoxInner {
    min-height: 310px;
    height: 310px;
}

.cardBoxNonIdeal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.graphBoxNonIdeal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 380px;
    width: 100%;
}

.listBoxNonIdeal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;
    width: 100%;
}

.listBoxInneNonIdeal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 50px;
}

.listBoxNonIdeal .listBoxInneNonIdeal:nth-child(4) {
    margin-bottom: 30px;
}

.listLeftSide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 50%;
}

.listLeftSide p:nth-child(1) {
    width: 34px;
    height: 13px;
    border-radius: 5px;
    /* background: var(--nonIdealText); */
}

.listLeftSide p:nth-child(2) {
    width: 140px;
    height: 13px;
    border-radius: 5px;
    /* background: var(--nonIdealText); */
}

.listRightSide {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 50%;
}

.listRightSide p:nth-child(1) {
    width: 41px;
    height: 19px;
    border-radius: 5px;
    background: var(--nonIdealText);
}
.zreportConatiner .reportDashboardBox .allScreenNonIdealInner {
    border-radius: 0px;
}

.zreportConatiner .onlineNonIdeal {
    margin-top: 0px !important;
}

.zreportConatiner .onlineNonIdeal .allScreenNonIdealBox {
    padding: 0px 0 15px;
}

.reportDashboardBox .nonIdealIconBox svg {
    fill: transparent;
}

.reportsWrapperInner .tableListMainBox .infiniteScrollBar {
    min-height: 100%;
}

/* Dashboard Filter Box */

/* Payment Dashboard */
.paymentKeyMetrics .salesByPaymentBoxInner {
    min-height: 250px;
    height: 250px;
}

/* Payment Dashboard */

/* media queries for lapton max width 1400 and lower*/

@media only screen and (max-width: 1400px) {
    .cartItemList {
        height: 52%;
    }

    .posRightpaneBottomSection {
        height: 38%;
    }

    .billScreenCalculator {
        margin: 0 0;
    }
}

/* kitchend Order History */

/* Notification Sidebar */
.notificationSidebar {
    padding: 0px;
    background: var(--topBarBG) !important;
}

.notificationSidebar .offcanvas-body {
    padding: 0px !important;
}

.notificationSidebar.offcanvas.offcanvas-end {
    top: 170px;
    width: 530px;
}

.notificationsWrapper {
    overflow-y: auto;
    padding: 0px 20px 0px 20px;
}

.notificationsWrapper .notificationDate:not(:first-child) {
    margin-top: 20px;
}

.notificationsWrapper .allScreenNonIdealBox {
    background-color: var(--topBarBG) !important;
}

.notificationsWrapper .allScreenNonIdealInner {
    height: calc(100vh - 300px) !important;
}

.notificationsWrapper .nonIdealSubHeadingBox p {
    width: 100%;
}

.notificationsWrapper .nonIdealIconBox {
    min-height: 0px;
}

.notificationsWrapper::-webkit-scrollbar {
    margin-left: 20px !important;
    width: 5px;
}

.notificationsWrapper::-webkit-scrollbar-track {
    background: var(--orderItemsWrapper);
}

.notificationsWrapper::-webkit-scrollbar-thumb {
    background-color: var(--blackBg);
    border-radius: 6px;
    border: 3px solid var(--darkGrayTwo);
}

.notificationRow {
    height: 60px;
    padding: 10px;
    background: #222736;
    border-radius: 12px;
}

.notificationIconBox {
    height: 40px;
    width: 40px;
    background: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}

/* Notification Sidebar */

.kitchendOrderHistoryWrapper .allScreenNonIdealBox {
    background-color: var(--topBarBG) !important;
}

.kitchendOrderHistoryWrapper .allScreenNonIdealInner {
    height: calc(100vh - 200px);
}

/* kitchend Order History */

/* Recall Order Detail Modal */
.orderDetailWrapper {
    display: flex;
    overflow-y: scroll;
}

.recallModalleftSide {
    padding: 0px 20px;
    min-height: 100vh;
}

.recallModalRightSide {
    background: #222736;
    padding: 20px;
    min-height: 100vh;
}

.orderDetailWrapper::-webkit-scrollbar {
    width: 5px;
}

.orderDetailWrapper::-webkit-scrollbar-track {
    background: var(--orderItemsWrapper);
}

.orderDetailWrapper::-webkit-scrollbar-thumb {
    background-color: var(--blackBg);
    border-radius: 6px;
    border: 3px solid var(--darkGrayTwo);
}

/* Recall Order Detail Modal */

/* settings configuration */
.settingsConfigurationWrapper {
    padding: 30px;
    background: var(--posRightBg);
    height: 100vh;
}

.displaySizeIconBoxWrapper {
    display: flex;
    align-items: center;
    gap: 100px;
}

.displaySizeIconBoxWrapper .form-check {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.displaySizeIconBoxWrapper .form-check .form-check-input {
    margin-bottom: 30px;
}

.displaySizeIconBox {
    height: 100px;
    width: 150px;
    background: var(--topBarBG);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* settings configuration */

/* timer configuration */
.timerConfigurationWrapper {
    padding: 30px;
    background: var(--posRightBg);
    height: 100vh;
}

.timerColorBox {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    border: 1px solid var(--white);
}

.clockRow {
    height: 30px;
    width: 100%;
    color: #474b52;
    display: flex;
    justify-content: center;
    align-items: center;
}

.selectedclockRow {
    cursor: pointer;
    background: #2d313a;
    color: var(--white);
}

.clockRow:hover {
    cursor: pointer;
    background: #2d313a;
    color: var(--white);
    scale: 1.1;
}

.timerModal .modal-dialog {
    display: flex;
    justify-content: center;
    align-items: center;
}

.timerModal .modal-body {
    padding: 0px;
    overflow-x: hidden !important;
}

.timerModal .modal-content {
    padding: 0px 0px 30px 0px;
    height: 300px;
    width: 300px;
    border-radius: 15px;
}

.timerModal .modal-header {
    position: relative;
    right: -20px;
    top: 4px;
    min-height: 0px;
}

.timerModal .modal-header .backBoxIcon {
    border: none;
}

.timerModal .buttonStyle {
    min-width: 120px;
    padding: 0 15px;
}

.timerModal .modal-footer {
    height: 60px;
}

.timerModal .modal-footer button {
    width: 250px;
}

#play_sound_order_cancelled::before {
    display: none;
}

#play_sound_order_arrived::before {
    display: none;
}

/* timer configuration */

/* Order Receipt */
.orderReceipt {
    width: 300px;
    margin: 0;
    border: none;
}

.orderReceipt p {
    margin-bottom: 6px;
}

.account {
    margin-bottom: 0px !important;
}

/* Restaurant */
.pos-cockpit-tabs {
    padding: 0px;
    font-family: var(--outfitFont);
    width: 100%;
}

.pos-cockpit-tabs .MuiTab-root {
    flex-grow: 1 !important;
    min-width: min-content !important;
    max-width: none !important;
}

.pos-cockpit-tabs .MuiButtonBase-root {
    color: var(--white);
    opacity: 1;
    font-family: var(--outfitFont);
    text-transform: capitalize;
    border-right: 1px solid var(--inputBorder);
    border-bottom: 1px solid var(--inputBorder);
    font-size: 16px;
}

.pos-cockpit-tabs .MuiButtonBase-root[aria-selected='true'] {
    color: var(--barclaysBlue);
}

.pos-cockpit-tabs .MuiTabs-indicator {
    background-color: var(--barclaysBlue) !important;
}

.addTableIconWrapper {
    height: 48px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--midnightBlue);
    border-left: 1px solid var(--inputBorder);
    border-bottom: 1px solid var(--inputBorder);
    cursor: pointer;
}

.selectFloorPlan {
    margin-top: 20px;
    display: flex;
    align-items: center;
    gap: 100px;
    color: var(--white);
    margin-left: 5px;
}

.selectFloorPlan>div {
    margin-bottom: 0px !important;
}

.selectFloorPlan .form-check {
    padding-left: 10px;
}

.selectFloorPlan .form-check .form-check-input {
    display: none;
}

.addTableDivWrapper {
    display: flex;
    vertical-align: center;
    gap: 10px;
    border: 1px solid var(--posRightBg);
    background: var(--blackPearl);
    border-radius: 10px;
    padding: 15px;
    cursor: pointer;
}

.tablesDivWrapper {
    gap: 10px;
    border: 1px solid var(--posRightBg);
    border-radius: 10px;
    padding: 15px;
    margin-top: 20px;
}

/* Restaurant Cockpit Screen */

.restaurantOrderListContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 20px 0px;
}

.restaurantOrderListContainer .tableHeader {
    border-top: 1px solid var(--tableBorder);
    border-bottom: 1px solid var(--tableBorder);
}

.restaurantOrderListContainer .allScreenNonIdealBox {
    background-color: var(--blackBg) !important;
}

.restaurantOrderListContainer .allScreenNonIdealBox .allScreenNonIdealInner {
    height: 70vh;
}

.restaurantOrderListContainer .allScreenNonIdealBox .nonIdealSubHeadingBox>p {
    font-size: 16px;
    color: var(--posRightBg) !important;
}

.readyBtn {
    background-color: var(--brightOrange) !important;
    border: 1px solid var(--brightOrange) !important;
    height: 0px !important;
    padding: 18px 25px;
    border-radius: 5px !important;
}

.serveBtn {
    background-color: var(--barclaysBlue) !important;
    border: 1px solid var(--barclaysBlue) !important;
    height: 0px !important;
    padding: 18px 25px;
    border-radius: 5px !important;
}

.dispatchBtn {
    background-color: var(--barclaysBlue) !important;
    border: 1px solid var(--barclaysBlue) !important;
    height: 0px !important;
    padding: 18px 25px;
    border-radius: 5px !important;
}

.print_billBtn {
    background-color: var(--purple) !important;
    border: 1px solid var(--purple) !important;
    height: 0px !important;
    padding: 18px 30px;
    border-radius: 5px !important;
}

.paymentBtn {
    background-color: #f1fcd2 !important;
    border: 1px solid #f1fcd2 !important;
    height: 0px !important;
    padding: 18px 25px;
    color: var(--brightGreen) !important;
    border-radius: 5px !important;
}

.assignBtn {
    background-color: #f1fcd2 !important;
    border: 1px solid #f1fcd2 !important;
    height: 0px !important;
    padding: 18px 0px;
    width: 100px;
    color: var(--brightGreen) !important;
    border-radius: 5px !important;
}

.unAssignBtn {
    background-color: var(--brightOrange) !important;
    border: 1px solid var(--brightOrange) !important;
    height: 0px !important;
    padding: 18px 0px;
    width: 100px;
    border-radius: 5px !important;
}

/* Restaurant Cockpit Screen */

/* Dine in Floor */
.dineInFloorWrapper {
    display: flex;
    height: 100%;
    width: 100%;
}

.playGround {
    width: 90%;
    display: flex;
    justify-content: flex-end;
    margin-top: 17px;
    overflow: hidden;
}

.floorPlayground {
    width: 80vw;
    background: var(--topBarBG);
    border-radius: 10px;
    position: relative;
}

.floorTable {
    border-radius: 10px;
    background: #171a24;
    color: var(--darkGrayTwo);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    position: absolute;
}

.floorTable:hover {
    background: #232839;
    color: var(--white);
}

.selectedFloorTable {
    border-radius: 10px;
    background: var(--barclaysBlue);
    color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: grab;
    position: absolute;
}

.tablesActions {
    width: 10vw;
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    align-items: center;
}

.editTableBtn {
    width: min-content;
}

.tableEventsWrapper {
    height: min-content;
    width: 100px;
    border-radius: 5px;
    border: 1px solid var(--posRightBg);
    padding: 12px 20px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.editTableBtn {
    height: 30px;
    width: 100px;
    border-radius: 5px;
    background: var(--midnightBlue);
    border: 1px solid var(--darkSlateBlue);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.tableInputBox {
    height: 26px;
    width: 50px;
    background: var(--posRightBg);
    border-radius: 2px;
    border: 1px solid var(--inputBorder);
    display: flex;
    justify-content: center;
    align-items: center;
}

.editDelIconBox {
    height: 26px;
    width: 25px;
    background: var(--posRightBg);
    border-radius: 2px;
    border: 1px solid var(--inputBorder);
    display: flex;
    justify-content: center;
    align-items: center;
}

.tableInputBox input {
    width: 100%;
    background: var(--posRightBg);
    border: none;
    color: var(--white);
    font-size: 12px;
    text-align: center;
}

.tableInputBox input:focus {
    border: none;
    outline: none;
    box-shadow: none;
}

.duplicateIcon {
    border-right: 1px solid var(--inputBorder);
    height: 100%;
}

.rectangleShape {
    height: 70%;
    width: 80%;
    border: 1px solid var(--inputBorder);
}

.selectedRectangleShape {
    height: 70%;
    width: 80%;
    border: 1px solid var(--barclaysBlue);
}

.ovalShape {
    height: 70%;
    width: 80%;
    border: 1px solid var(--inputBorder);
    border-radius: 50px;
}

.selectedOvalShape {
    height: 70%;
    width: 80%;
    border: 1px solid var(--barclaysBlue);
    border-radius: 50px;
}

/* Dine in Floor */

/* Create Table Modal */

.tableFieldsWrapper {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.tableFieldsWrapper .form-group {
    width: 100%;
}

/* Create Table Modal */

/* createFloorPlan */

.createFloorPlanContainer .container {
    max-width: 100%;
}

.createFloorPlanButtonWrapper {
    width: 10%;
}

.createFloorPlanButtonWrapper button {
    border-radius: 5px !important;
}

/* createFloorPlan */

/* Modifiers */

.createModifierContainer .container {
    max-width: 100%;
    padding: 30px;
}

.modifiersScreen .allScreenNonIdealBox {
    background-color: var(--topBarBG) !important;
}

.modifiersDivWrapper {
    gap: 10px;
    border-radius: 10px;
    padding: 15px;
    margin-top: 20px;
}

.addModifier {
    display: flex;
    align-items: center;
    gap: 20px;
}

.addModifier input {
    font-size: 14px;
    color: var(--white);
}

.addModifier .formGroupInner {
    margin: 0px !important;
}

.addModifier .MuiInputLabel-shrink {
    display: none;
}

.addModifier .MuiFormLabel-root {
    color: var(--inputText);
    transform: translate(0px, 20px) scale(1) !important;
}

.addModifier .Mui-focused {
    color: var(--inputText);
}

.addModifier .MuiInputBase-root::before {
    border-bottom: none !important;
}

#stock_available::before {
    display: none;
}

.selectedItemsWrapper {
    margin-top: 20px;
    border: 1px solid var(--posRightBg);
    background: #141925;
    border-radius: 10px;
    padding: 15px;
    cursor: pointer;
}

.addModifierIcon>svg {
    margin-bottom: 10px;
}

.itemChip {
    display: flex;
    align-items: center;
    padding: 5px 5px;
    gap: 10px;
    background: var(--posRightBg);
    width: fit-content;
    border-radius: 5px;
}

.itemsListWrapper {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 15px 20px;
    border-bottom: 1px solid var(--tableBorder);
}

.itemsListWrapper .checkBoxMainBox {
    margin: 0px;
}

/* ModifierModal */

.modifierModalRightPanel {
    border-left: 2px solid var(--posRightBg);
    padding-left: 30px;
}

.modifierBox {
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 1px solid var(--posRightBg);
    height: 50px;
    min-width: 130px;
    border-radius: 10px;
    white-space: nowrap;
    overflow: hidden;
}

.selectedModifierBox {
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 1px solid var(--barclaysBlue);
    height: 50px;
    min-width: 130px;
    border-radius: 10px;
    white-space: nowrap;
    overflow: hidden;
}

.selectedModifierBox>p {
    color: var(--barclaysBlue);
}

/* ModifierModal */

/* Modifiers */

/* Void Reason Modal */
.voidNotesWrapper {
    min-height: 150px;
    height: min-content;
    border: 1px solid var(--posRightBg);
    border-radius: 10px;
    padding: 20px;
}

.voidNotesPill {
    padding: 5px;
    background: var(--posRightBg);
    border-radius: 5px;
    width: min-content;
    display: flex;
    align-items: center;
    gap: 10px;
    white-space: nowrap;
}

.selectedReason {
    border: 1px solid var(--barclaysBlue) !important;
}

.selectedReason>p {
    color: var(--barclaysBlue) !important;
}

/* Void Reason Modal */

/* Select Floor Plan Modal */
.selectFloorPlanWrapper {
    width: 100% !important;
    border: 1px solid var(--blackPearlTwo);
    background: var(--blackPearl);
    border-radius: 10px;
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.floorPlansWrapper {
    width: 100%;
}

.radioOuterCircleBefore {
    height: 25px;
    width: 25px;
    background: var(--white);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    left: 0%;
}

.radioOuterCircleAfter {
    height: 25px;
    width: 25px;
    background: var(--white);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    right: 7%;
}

.radioInnerCircle {
    height: 15px;
    width: 15px;
    background: var(--barclaysBlue);
    border-radius: 50%;
}

.floorPlansWrapper .form-check {
    width: 100%;
}

.floorPlansWrapper .form-check .form-check-input {
    display: none;
}

.floorPlansWrapper .form-check .form-check-label {
    width: 100%;
}

/* Select Floor Plan Modal */

/* Bill Types Modal */
.splitBillWrapper {
    width: 100% !important;
    border: 1px solid var(--blackPearlTwo);
    background: var(--blackPearl);
    border-radius: 10px;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.billTypesWrapper {
    width: 100%;
}

.billTypesWrapper .form-check {
    width: 100%;
    padding-left: 0px;
}

.billTypesWrapper .form-check .form-check-input {
    display: none;
}

.billTypesWrapper .form-check .form-check-label {
    width: 100%;
}

.totalBillAmountWrapper {
    padding: 10px 20px;
    border: 1px solid var(--posRightBg);
    border-radius: 10px;
}

.addAmountButton {
    border: 1px solid var(--barclaysBlue);
    height: 60px;
    width: 60px;
    position: relative;
    top: 5px;
}

.splitAmountWrapper {
    border: 1px solid var(--posRightBg);
    border-radius: 10px;
    padding: 15px 15px;
}

.splitAmountList:not(:first-child) {
    margin-top: 10px;
}

.equalPayBox {
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid var(--posRightBg);
    cursor: pointer;
}

.splitDishCard {
    border-radius: 10px;
    background: var(--newposRightBg);
    padding: 0px 0px 15px 0px;
}

.splitDishCardHead {
    background: var(--topBarBG);
    height: 50px;
    border-bottom: 2px solid var(--inputBorder);
    padding: 15px 15px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    justify-content: space-between;
}

.splitDishCardList {
    padding: 15px 15px;
}

.splitDishCardList .modifierList {
    background: var(--topBarBG);
    padding: 3px 10px;
    border: 1px solid var(--posRightBg);
    border-radius: 3px;
}

.splitDishCardList .checkBoxMainBox {
    margin-top: 0px;
    margin-bottom: 0px;
}

.splitDishCardAmountBox {
    background: var(--topBarBG);
    border-radius: 5px;
    margin: 0px 15px;
    padding: 15px 0px;
}

/* Bill Types Modal */

/* Deals */

.addItemWrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 15px 20px;
    border-radius: 10px;
    background: var(--blackPearl);
}

.dealsItemBox {
    background: #141925;
    border-radius: 10px 10px 0px 0px;
    padding: 20px 20px;
}

.totalCost {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--topBarBG);
    border-radius: 0px 0px 10px 10px;
    padding: 15px 20px;
}

/* Deals */

/* Restaurant */
.accordion-button::after {
    position: relative;
    top: 6px;
    background-image: url('data:image/svg+xml,<svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.25452 1.78041C4.76583 2.27063 4.27715 2.76085 3.78897 3.25209L3.66386 3.37822L3.53825 3.25311C2.93211 2.64493 2.32496 2.03727 1.71831 1.43011L1.05856 0.769848C0.985537 0.695805 0.916089 0.624315 0.842046 0.558442C0.705704 0.436908 0.528 0.391972 0.367147 0.438951C0.201698 0.486951 0.0730162 0.621251 0.0209306 0.799977C-0.032687 0.988404 0.0158242 1.14824 0.176677 1.30858L2.9602 4.09005L2.95764 4.09261L3.66386 4.79883C4.11068 4.34895 4.55749 3.89958 5.00533 3.45175L6.19002 2.26144C6.21198 2.23999 6.24568 2.20731 6.28857 2.17157L6.43615 2.0245C6.6833 1.77735 6.93096 1.52969 7.17812 1.28202C7.27769 1.18041 7.33182 1.05785 7.33182 0.926105C7.33182 0.867381 7.32161 0.807636 7.30067 0.746358C7.23173 0.54108 7.08518 0.4221 6.87377 0.402185C6.64756 0.380227 6.49692 0.532909 6.43972 0.589591L5.25452 1.78041Z" fill="white"/></svg>') !important;
}

.accordion-button:not(.collapsed)::after {
    top: -8px !important;
}

@media print {
    body {
        margin: 0 !important;
        padding: 0 !important;
        overflow: hidden;
    }

    @page {
        size: 300px auto !important;
        margin: 0mm !important;
        padding: 0px 0px !important;
    }
}

/* -------------------Dashboard---------------- */
.dashboardBox {
    /* padding: 10px 10px; */
    overflow: auto !important;
}

.dashboardBox::-webkit-scrollbar {
    width: 5px;
}

.dashboardBox::-webkit-scrollbar-track {
    background: var(--blackBg);
}

.dashboardBox::-webkit-scrollbar-thumb {
    background-color: var(--blackBg);
    border-radius: 6px;
    border: 3px solid var(--darkGrayTwo);
}

/* total title */
.totaltitlecontainer {
    background-color: var(--blackBg);
    height: min-content;
    width: 100%;
    border: 1px solid var(--blackPearlTwo);
    border-radius: 10px;
    padding: 15px 15px;
    margin: 5px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.titlecontainerSub {
    padding: 13px 15px 15px;
}

.tileContTop {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 30px;
    width: 100%;
}

.tileContTop .iconBox {
    height: 34px;
    width: 34px;
}

.tileContBottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 20px;
    width: 100%;
}

.tileContBottom.Bottomsubtitle {
    /* margin-top: 10px; */
}

.tileContBottomLeft {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

/* 
.tileContBottomsubtitle {
    margin-top: 5px;
} */

.titlecontainer {
    width: 20%;
}

.iconBox {
    height: 30px;
    width: 30px;
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.flexRow {
    display: flex;
    flex-direction: row;
}

.amountNonIdeal {
    height: 20px;
    min-width: 25px;
    background-color: var(--grayBgTwo);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    padding: 0 8px;
}

.amountIdeal {
    height: 20px;
    min-width: 71px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    padding: 0 8px;
}

.amountIncrease {
    background: var(--lightGreenNew);
}

.amountDecrease {
    background: var(--lightRed);
}

/* total title */

/* ****** Summary ****** */
.summaryConatiner {
    width: 100%;
    min-height: 400px;
    background-color: var(--blackBg);
    border: 1px solid var(--blackPearlTwo);
    border-radius: 15px;
    margin-top: 30px;
    padding: 20px;
}

.summaryBoxHeading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 32px;
    margin-bottom: 10px;
}

.summaryDateBox {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    position: relative;
}

.customerSummaryRow {
    min-height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 27px;
}

.customerSummaryRow:nth-child(7) {
    margin: 15px 0 0px;
}

.lineChartMainContainer .summaryDateBox {
    margin-bottom: 10px;
}

.DatePickerContainer {
    background-color: transparent;
    border: 1px solid var(--newBorderColor);
    min-width: 131px;
    height: 38px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    cursor: pointer;
    padding: 10px 15px;
    box-sizing: border-box;
}

.DatePickerCompareContainer {
    background-color: transparent;
    border: 1px solid var(--newBorderColorTwo);
    min-width: 144px;
    height: 38px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    cursor: pointer;
    margin-left: 10px;
    padding: 10px 16px;
    box-sizing: border-box;
}

/* ****** Summary ****** */

/* -------------------Dashboard---------------- */

.PickersLayoutRoot .contentWrapper {
    grid-column: 2;
}

.contentWrapper .content {
    height: 372px;
}

.contentWrapper .content .MuiPickersCalendarHeader-root {
    border-radius: 12px;
    background: var(--topBarBG);
    border: 1px solid var(--topBarBG);
    margin-bottom: 12px;
    padding: 20px 5px 20px 20px;
    margin-top: 0px;
}

.contentWrapper .content .MuiDayCalendar-root {
    border-radius: 12px;
    background: var(--topBarBG);
    border: 1px solid var(--topBarBG);
    width: 360px;
    padding: 0 6px;
}

.contentWrapper .content .css-1a4q4r2-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected,
.contentWrapper .content .MuiPickersDay-root.Mui-selected.Mui-selected {
    background-color: var(--blackBg);
}

.contentWrapper .content .MuiPickersDay-today {
    border: 1px solid var(--blackBg) !important;
}

.contentWrapper .content .css-grqin-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected {
    background-color: var(--blackBg);
}

.contentWrapper .content .css-rhmlg1-MuiTypography-root-MuiDayCalendar-weekDayLabel {
    font-size: 14px;
    font-weight: 500;
}

.contentWrapper .content .css-i5q14k-MuiDayCalendar-header {
    justify-content: space-evenly;
    border-bottom: 1px solid #222737;
    margin-bottom: 12px;
}

.contentWrapper .content .MuiDateRangePickerDay-rangeIntervalDayHighlight {
    background: #1f232f !important;
}

.contentWrapper .content .css-i5q14k-MuiDayCalendar-header span {
    font-weight: bold;
}

.contentWrapper .content .css-flbe84-MuiDayCalendar-weekContainer {
    justify-content: space-evenly;
    margin-bottom: 8px;
}

.contentWrapper .content button {
    color: #ffff;
    padding-top: 5px;
}

.contentWrapper .content .MuiDateRangeCalendar-root>div:nth-child(1) {
    display: none !important;
}

.contentWrapper .content span {
    color: #ffff;
}

.contentWrapper .content .css-1kex3oi-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day:not(.Mui-selected) {
    border: 1px solid #ffff;
}

.contentWrapper .toolbar {
    display: flex;
    align-items: center;
    margin-top: 13px;
}

.contentWrapper .toolbar .MuiPickersToolbar-root {
    padding: 0px;
}

.contentWrapper .toolbar .MuiPickersToolbar-root .MuiTypography-overline {
    display: none !important;
}

.contentWrapper .toolbar button {
    background: #ffff;
    width: 165px;
    height: 40px;
    border-radius: 10px;
}

.contentWrapper .toolbar span {
    font-size: 14px;
}

.contentWrapper .MuiDateRangePickerDay-rangeIntervalPreview {
    padding: 0px 4.8px;
}

.rangePickerWrapper {
    height: min-content;
    width: 500px;
    padding: 10px;
    border-radius: 16px;
    border: 1px solid #3b3f4e;
    background: var(--blackBg);
    position: absolute;
    z-index: 1;
    top: 41px;
    left: auto;
}

.rangePickerWrapper .MuiPickersCalendarHeader-root {
    position: relative;
    padding: 20px 20px 20px 20px !important;
}

.rangePickerWrapper .MuiPickersCalendarHeader-labelContainer {
    margin: 0 auto;
    text-align: center;
    position: relative;
    z-index: 3;
}

.rangePickerWrapper .MuiPickersCalendarHeader-label {}

.rangePickerWrapper .MuiPickersArrowSwitcher-button .MuiSvgIcon-root path {
    display: none;
}

.rangePickerWrapper .MuiPickersArrowSwitcher-button .MuiSvgIcon-root {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_772_491)'%3E%3Cpath d='M4.1665 10H15.8332' stroke='white' stroke-width='1.25' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M4.1665 10L9.1665 15' stroke='white' stroke-width='1.25' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M4.1665 10L9.1665 5' stroke='white' stroke-width='1.25' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_772_491'%3E%3Crect width='20' height='20' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
    background-repeat: no-repeat no-repeat;
    background-position: center center;
    background-size: cover;
}

.rangePickerWrapper .MuiPickersArrowSwitcher-root {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}

.rangePickerWrapper .MuiPickersArrowSwitcher-root button:nth-child(3) {
    transform: rotate(180deg);
}

.PickersLayoutRoot .shortcuts {
    grid-column: 1;
}

.PickersLayoutRoot .shortcuts ul {
    max-height: 100%;
    padding-top: 0px;
}

.PickersLayoutRoot .shortcuts ul li {
    padding-right: 15px;
    padding-left: 0px;
}

.PickersLayoutRoot .shortcuts ul :first-child {
    padding-top: 0px;
}

.PickersLayoutRoot .shortcuts .MuiChip-root {
    height: 44px;
    width: 120px;
    background: var(--topBarBG);
    color: #ffff;
    border-radius: 16px;
}

/* ------------Percentage Bar---------------- */
.percentageBarContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
}

.percentageBarContainer .baritem {
    height: 33px;
    border-radius: 5px;
    margin-right: 5px;
    position: relative;
}

.percentageBarContainer .baritem:last-child {
    margin-right: 0;
}

.percentageBarContainer .baritem .baritemText {
    font-size: 12px;
    font-weight: 400;
    color: var(--newBorderColorTwo);
    position: absolute;
    top: 38px;
    left: 40%;
    margin-bottom: 0;
}

.paymentBoxRowMain {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.paymentBoxRowMain .paymentBoxRow:last-child {
    margin: 15px 0 0px;
}

.paymentBoxRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 20px;
    width: 100%;
    margin: 10px 0 11px;
}

.paymentBoxRow p {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.paymentBoxRow p span:nth-child(1) {
    position: relative;
    top: -2px;
}

.cardIcon svg,
.creditIcon svg,
.walletIcon svg {
    fill: transparent;
}

.rsIcon {
    fill: var(--brightGreen);
}

/* ------------Percentage Bar---------------- */

/*-------- lineChart -----------*/
.lineChartMainContainer {
    background-color: var(--blackBg);
    margin-top: 20px;
    border: 1px solid var(--blackPearlTwo);
    border-radius: 15px;
    height: 278px;
    padding: 20px;
}

.graphBox {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.graphboxLeft {
    min-height: 66px;
    min-width: 140px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.lineChartContainer {
    width: calc(100% - 140px);
}

.lineChart .css-1la267r-MuiAreaElement-root {
    fill: #5fa6c0 !important;
}

/*----------- lineChart------------------ */

/* ============BarGraph============== */
.barGraphConatiner {
    position: relative;
    height: 150px;
}

.barGraphImage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.barGraph {
    position: absolute;
    top: -60px;
    width: 100%;
    right: 60px;
}

.chart-container {
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.chart-container canvas {
    width: 100% !important;
    height: 100% !important;
}

.lowestText {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 0px;
    width: 100%;
}

.lowestTextNonIdeal {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 0px;
    width: 100%;
}

.topItemInnerMain {
    display: flex;
    flex-direction: row;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
    width: 100%;
}

.topItemInnerMain .topItemInnerRow:last-child {
    margin-bottom: 0;
}

.topItemInnerRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 0px;
    margin-bottom: 20px;
    width: 100%;
}

.topItemInnerRow p {
    text-transform: capitalize;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.itmeFirstPara {
    content: '';
    width: 5px;
    height: 5px;
    border-radius: 3px;
    margin-right: 10px;
    display: inline-block;
}

.chartNonIdealButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.chartButton {
    min-width: 325px;
    height: 46px;
}

/* ============BarGraph============== */

.dashboardHeading {
    margin-bottom: 20px;
}

/* ================ToolTip Style================== */
.tooltipConatiner {
    padding: 5px;
}

.MuiTooltip-popper .MuiTooltip-tooltip {
    background-color: var(--blackBg) !important;
    font-family: var(--outfitFont) !important;
    border-radius: 10px;
    border: 1px solid var(--inputBorder) !important;
}

.tooltipButtonBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.tooltipButtonBox button {
    margin-left: 10px;
    height: 25px;
    width: 45px;
    border-radius: 5px;
}

.MuiTooltip-arrow::before {
    background-color: var(--blackBg) !important;
    border: 1px solid var(--inputBorder) !important;
}

.MuiTooltip-popper .MuiTooltip-tooltip {
    font-size: 1.6rem;
}

/* ================ToolTip Style================== */

/* ----Order level discount Modal------ */
.orderDiscountModalList {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    margin-top: 10px;
    border-bottom: 1px solid var(--darkSlateBlue2);
}

.orderDiscountModalList:hover {
    background-color: var(--darkBluishGray);
    cursor: pointer;
}

.orderDiscountModalList .iconBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #007aff;
}

/* ----Order level discount Modal----- */

/* Location Modal */
.locationModalList {
    display: flex;
    align-items: center;
    padding: 5px;
    border-bottom: 1px solid var(--darkSlateBlue2);
    min-width: 100%;
}

.locationListCheck {
    margin-top: 12px;
    margin-bottom: 12px;
}

/* Location Modal */

@media only screen and (max-width: 640px) and (min-width: 320px) {
    .headerMainCont .headerLeftSide .buttonIconStyle {
        display: none;
    }

    .appBreadcrum {
        margin-left: 0;
    }

    .headerMainCont .headerRigthSide {
        display: none;
    }

    .OrderCalenderConatiner {
        margin-top: 0;
        margin-bottom: 5px;
    }

    .OrderCalenderConatiner .summaryDateBox {
        width: 100%;
    }

    .lineChartMainContainer {
        min-height: 278px;
        height: auto;
        margin-top: 7px;
    }

    .lineChartMainContainer .flexRow {
        flex-wrap: wrap;
    }

    .summaryDateBox {
        flex-wrap: wrap;
    }

    .titlecontainer {
        width: 100%;
    }

    .DatePickerContainer {
        min-width: 100%;
    }

    .DatePickerCompareContainer {
        width: 100%;
        margin-left: 0;
        margin-top: 18px;
    }

    .dashboardHeading {
        margin-bottom: 15px;
    }

    .totaltitlecontainer {
        margin: 5px 0 23px;
    }

    .lineChartMainContainer .titlecontainer {
        margin-bottom: 15px;
    }

    .lineChartMainContainer .titlecontainer:last-child {
        margin-bottom: 0;
    }
}

/* ========verifiySpinerModal========== */

.verifiySpinerModal .modal-content {
    background-color: var(--blackBg) !important;
    border: none;
    border-radius: 10px;
}

.verifiySpinerModal .modal-body {
    height: 78px;
    width: 542px;
    padding: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.modalSpinner {
    color: var(--brightGreen);
    height: 36px;
    width: 36px;
    margin-left: 20px;
    margin-bottom: 0px;
}

.modal-backdrop {
    opacity: 0.8 !important;
}

/* ========verifiySpinerModal========== */

/* category Component */

.categoryTileContainer {
    width: 100%;
    height: 40%;
    border: 1px solid var(--inputBorder);
    border-radius: 10px;
    position: relative;
}

.categoryBox {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.categoryTile {
    height: 60%;
    width: 30%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 2px solid var(--white);
    border-radius: 10px;
    margin-bottom: 10px;
}

.colorPickerWrapper {
    position: absolute;
    bottom: -100px;
    z-index: 99;
}

.colorPalette {
    margin-top: 10px;
    width: 290px;
    background: var(--white);
    padding: 5px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    border-radius: 10px;
}

.colorBox {
    height: 45px;
    width: 45px;
    border-radius: 0px;
    cursor: pointer;
    margin: 5px;
}

/* category Component */

/* Settings */

/* App Settings */
.appSettingsWrapper {
    padding-bottom: 10px;
    overflow: auto;
    border-radius: 10px;
    scrollbar-width: 5px;
    scrollbar-color: var(--darkGrayTwo) var(--blackBg);
}

/*! Other Browser */
.appSettingsWrapper {
    --scrollbarBG: var(--blackBg);
    --thumbBG: var(--darkGrayTwo);
}

.appSettingsWrapper::-webkit-scrollbar {
    width: 5px;
}

.appSettingsWrapper::-webkit-scrollbar-track {
    background: var(--blackBg);
}

.appSettingsWrapper::-webkit-scrollbar-thumb {
    background-color: var(--blackBg);
    border-radius: 6px;
    border: 3px solid var(--darkGrayTwo);
}

.appSettingSwitchWrapper input[type='checkbox']::before {
    display: none;
    outline: none;
}

.appSettingSwitchWrapper input.form-check-input:focus {
    outline: none;
    box-shadow: none;
}

.selectItemService {
    margin-top: 20px;
    display: flex;
    align-items: center;
    gap: 100px;
    color: var(--white);
}

.selectItemService>div {
    margin-bottom: 0px !important;
}

/* App Settings */

/* Settings */

.modal-footer .addSuccessfullyToast {
    bottom: 15px !important;
}

.paymentStepRow {
    border-bottom: 1px solid var(--posRightBg);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
}

.verifiedAccountTageline {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

/* =======otpverification========= */
.otpverification {
    width: 100%;
    margin-top: 30px;
}

.otpverification input {
    width: 23% !important;
    margin-left: 10px;
    background-color: transparent;
    color: var(--white);
    font-size: 16px !important;
    border: 0 solid var(--posRightBg);
    border-bottom-width: 1px;
}

.otpverification input {
    caret-color: var(--barclaysBlue);
}

.otpverification input:focus {
    outline: none;
}

.otpverificationErrorState input {
    border: 0 solid var(--red);
    border-bottom-width: 1px;
}

.otpverificationTimer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.otpverificationTimer .otpSpiner {
    height: 20px;
    width: 20px;
    margin-left: 10px;
}

.spinner-border {
    --bs-spinner-border-width: 0.2em;
}

.callAndSmsBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.mpinConatiner .mpinImageBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

/* =======otpverification========= */
/* =======Customer Features========= */

/* create Cutomer and Customer Listing Component */
.createCustomerContainer .container {
    max-width: 100%;
}

.topBarRightSide .newposSearchBar {
    height: 50px;
    border: 1px solid var(--inputBorder);
    border-radius: 10px;
    width: 300px;
    background-color: var(--topBarBG);
}

.listActionBox {
    height: 23px;
    width: 35px;
}

.listActionBox .customDropDown .dropdown-toggle:hover {
    background-color: var(--darkBluishGray);
    border-radius: 50px;
}

.listActionBox .customDropDown span svg {
    margin-bottom: 5px;
    margin-left: 3px;
}

.listActionBox .customDropDown .dropdown-menu {
    border-color: var(--inputBorder);
}

.listActionBox .customDropDown .dropdown-menu .dropdown-item {
    color: var(--barclaysBlue);
}

.deleteOption .customDropDown .dropdown-menu .dropdown-item {
    color: var(--red);
}

/* create Cutomer and Customer Listing Component */

/* create Details Component */
.orderSummaryContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

/* create Details Component */

/* =======Customer Features========= */

/* =======New Layout Main Box ========= */
.layoutMainBox {
    height: 100%;
    padding: 15px 20px;
}

.layoutMainInnerBox {
    height: calc(100% - 40px);
    background: var(--blackBg);
    border-radius: 10px 10px;
    padding: 10px 10px;
    width: 100%;
}

.layoutMainInnerBox .topBarMainCont .topBarLeftSide p.marLeft10 {
    margin-left: 0;
}

.topCardsBox {
    padding: 0 0;
    width: 100%;
    margin: 0px 0 10px 0;
}

/* =======New Layout Main Box ========= */
/* =======New Layout Text with Tag ========= */
.textWithTag {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.textWithTag p:nth-child(1) {
    min-width: 85px;
}

.textWithTagInner {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 2px solid;
    border-radius: 5px;
    min-width: 120px;
}

.textWithTagInner p {
    margin: 0px 5px;
}

/* =======New Layout Text with Tag ========= */
/* =======New Layout Filter Box ========= */
.filterMainBox {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

/* =======New Layout Filter Box ========= */
/* ---------variation List component--------- */
.variationsListButton {
    height: 50px;
    width: '100%';
    border-radius: 10px;
    border: 1px solid;
    padding: 13px 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: var(--blackPearl);
    margin: 10px 0px;
}

.variationsList {
    height: 50px;
    width: '100%';
    border-radius: 10px;
    border: 1px solid;
    padding: 13px 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: var(--blackPearl);
    margin: 10px 0px;
}

.deleteIconWrapper {
    height: 50px;
    width: 50px;
    border-radius: 10px;
    border: 1px solid var(--posRightBg);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

/* ---------variation List component--------- */

/* --------- Wallet Dashboard --------- */
.walletCardBox .col-md-3:nth-child(2) .totaltitlecontainer .iconBox,
.walletCardBox .col-md-3:nth-child(3) .totaltitlecontainer .iconBox,
.walletCardBox .col-md-3:nth-child(4) .totaltitlecontainer .iconBox {
    border: 1px solid var(--newBorderColor);
}

.walletFilterBox {
    position: relative;
}

.walletFilterBox .orderCalender {
    right: auto;
}

.walletFilterBox .summaryDateBox {
    margin-bottom: 0;
    margin-right: 5px;
}

.walletFilterBox .summaryDateBox .DatePickerContainer {
    min-width: 100px;
    height: 35px;
    border-radius: 30px;
}

.walletFilterBox .customDropDown .dropdown-toggle {
    border: 1px solid var(--newBorderColor);
    font-size: 14px;
    font-weight: 500;
}

.walletFilterBox .customDropDown .dropdown-toggle.show {
    /* border: 1px solid var(--barclaysBlue)  */
}

.walletFilterBox .customDropDown .dropdown-toggle svg {
    height: 14px;
    width: 14px;
}

.walletFilterBox .customDropDown {
    /* margin: 0 10px; */
}

.walletCardBox .infoTooltip .tooltipChildren>svg {
    fill: var(--lightGrayTwo);
}

.walletCardBox .infoTooltip .tooltipChildren>svg:hover {
    fill: var(--barclaysBlue);
}

.fundsJourneyList .infoTooltip .tooltipChildren>svg {
    fill: var(--lightGrayTwo);
    position: relative;
    bottom: 3px;
}

.fundsJourneyList .infoTooltip .tooltipChildren>svg:hover {
    fill: var(--barclaysBlue);
}

.transactionDetail .posRightHeader {
    margin-top: 10px;
    height: 65px;
}

.transactionDetailWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 30px 10px;
}

.transactionDetailAmountSection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    padding: 0 15px;
}

.transactionDetailAmountTop {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
}

.transactionDetailAmountStatus {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.transactionDetailAmountStatus .locationTileContainer {
    margin-left: 10px;
}

.transactionDetailAmountBottom {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-top: 20px;
}

.transactionDetailAmountBotInner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    min-width: 140px;
    margin-bottom: 15px;
    width: auto;
    margin-right: 20px;
}

.transactionDetailAmountBotInner i {
    font-style: normal;
}

.transactionDetailItemPurchased {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    padding: 0 15px;
}

.transactionDetailItemPurchased h3 {
    margin-bottom: 20px;
}

.detailItemPurchasedInner {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.detailItemPurchasedInner p {
    min-width: 180px;
    margin-bottom: 15px;
}

.detailSeperator {
    width: 100%;
    opacity: 1;
    border-top: 1px solid var(--newBorderColor);
    margin: 10px 0 30px;
}

.masterCard {
    content: '';
    display: block;
    width: 26px;
    height: 16px;
    background: url('../src/assets/images/MasterCard.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.visaCard {
    content: '';
    display: block;
    width: 37px;
    height: 13px;
    background: url('../src/assets/images/visa.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.easyPaisa {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background: url('../src/assets/images/easy.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.jazzCash {
    content: '';
    display: block;
    width: 25px;
    height: 13px;
    background: url('../src/assets/images/jazzcash.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.customerSeperator i {
    font-style: normal;
}

.ListCardBox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.walletFilterBox .customDropDown .dropdown-menu {
    min-width: 180px;
    min-height: 135px;
    padding: 5px 14px;
    box-sizing: border-box;
}

.walletFilterBox .customDropDown .dropdown-menu::before {
    content: '';
    background: url('../src/assets/icons/DropdownArrowIconBorder.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    display: block;
    width: 11px;
    height: 6px;
    position: absolute;
    z-index: -1;
    top: -6px;
}

.walletFilterBox .filterMainBox:nth-child(1) .customDropDown .dropdown-menu .dropdown-item:hover {
    background: transparent;
}

.walletFilterBox .customDropDown .dropdown-menu button {
    width: 100%;
    height: 35px;
    border-radius: 5px;
    margin-bottom: 5px;
}

.walletFilterBox .customDropDown .dropdown-menu button .fontSize16 {
    font-size: 13px;
}

.walletFilterBox .customDropDown .dropdown-menu .dropdown-item {
    padding: 0 0;
    margin-bottom: 7px;
}

.walletFilterBox .customDropDown .dropdown-menu .dropdown-item .form-group {}

.walletFilterBox .customDropDown .dropdown-menu .dropdown-item .form-group .inputError {
    display: none;
}

.walletFilterBox .customDropDown .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-clttge-MuiAutocomplete-root .MuiFilledInput-root {
    padding-right: 15px;
}

.walletFilterBox .customDropDown .formGroupInner .MuiFilledInput-root {
    min-height: 40px;
    border-radius: 8px;
}

.walletFilterBox .customDropDown .formGroupInner .MuiOutlinedInput-root {
    min-height: 35px;
}

.walletFilterBox .customDropDown .formGroupInner .MuiFilledInput-input,
.walletFilterBox .customDropDown .formGroupInner .MuiOutlinedInput-input {
    padding: 15px 15px 0px 15px;
}

.walletFilterBox .customDropDown .formGroupInner .MuiAutocomplete-inputRoot[class*='MuiFilledInput-root'] .MuiAutocomplete-input {
    padding: 15px 15px 0px 15px !important;
}

.walletFilterBox .customDropDown .formGroupInner .MuiInputLabel-filled,
.formGroupInner .MuiInputLabel-outlined {
    transform: translate(15px, 11px) scale(1);
    font-size: 12px;
}

.walletFilterBox .customDropDown .formGroupInner .MuiInputLabel-filled.MuiInputLabel-shrink,
.walletFilterBox .customDropDown .formGroupInner .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(15px, 3px) scale(0.8);
}

.walletFilterBox .customDropDown .formGroupInner .sufixIcon {
    right: 10px;
    pointer-events: none;
    -moz-user-select: inherit;
}

.walletFilterBox .customDropDown .MuiInputBase-input {
    font-size: 12px;
}

.walletFilterBox .customDropDown .sufixIcon svg {
    width: 16px;
    height: 16px;
}

.walletFilterBox .customDropDown .checkBoxMainBox label {
    width: 100%;
    cursor: pointer;
}

.walletFilterBox .customDropDown input[type='checkbox'] {
    border: 1px solid var(--borderColor);
    width: 16px;
    height: 16px;
    transform: translateY(0.1em);
}

.walletFilterBox .customDropDown input[type='checkbox']:checked::before {
    transform: scale(0.8);
    left: 1px;
    top: -1px;
}

/* --------- Wallet Dashboard --------- */
.createRoleError {
    font-size: 16px;
    min-height: 18px;
}

/* Report Header */
.reportHeaderWrapper {
    border-bottom: 1px solid var(--blackPearlTwo);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 5px 0 25px;
    margin-bottom: 15px;
}

.reportHeaderWrapper h2 {
    margin: 0;
}

.reportHeaderWrapper .buttonPrimary {
    background: var(--brightGreen) !important;
    color: var(--white) !important;
    height: 40px;
    min-width: 100px;
    border-radius: 5px;
    border: 1px solid var(--brightGreen) !important;
}

.reportHeaderWrapper .buttonPrimary:hover {
    background: rgba(74, 198, 0, 0.5) !important;
}

.reportHeaderWrapper .buttonPrimary span {
    color: var(--white) !important;
}

.reportHeaderRightWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 50%;
}

.reportHeaderRightWrapper .customDropDown {
    margin-right: 20px;
}

.reportHeaderRightWrapper .customDropDown .dropdown-toggle {
    min-width: 155px;
    border: 1px solid var(--inputBorder);
    height: 40px;
    border-radius: 10px;
    padding: 0 15px;
    background-color: var(--topBarBG);
}

/* Report Header */

/* Report Card Box */
.ReportCardBoxWrapper {
    /* display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center; */
    width: 100%;
    margin-bottom: 20px;
}

.ReportCardBox {
    width: 100%;
    min-height: 115px;
    border: 1px solid var(--blackPearlTwo);
    border-radius: 15px;
    background: var(--midnightBlue);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cardAmountBox {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 10px;
}

.cardTitleBox {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0;
}

/* Report Card Box */

/* Table Footer */
.tableFooterWrapper {
    background: var(--midnightBlue);
    border-top: 1px solid var(--blackPearlTwo);
    border-radius: 0 0 14px 14px;
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0 20px;
}

/* Table Footer */
.offcanvas-backdrop.show {
    opacity: 0;
}

/* =======note Modal Input======= */

.noteModalFeild .formGroupInner textarea.MuiFilledInput-input.MuiInputBase-inputMultiline {
    padding: 25px 15px;
}

/* =======note Modal Input======= */

/*============ Marign break Down ============ */

.UploadMarginConatiner {
    border: 1px solid var(--posRightBg);
    border-radius: 10px;
    padding: 20px;
}

.UploadMarginConatiner .fileUploder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80px;
    width: 230px;
    border: 1px dashed var(--seperatorBg);
    border-radius: 10px;
}

.UploadMarginConatiner .submitButton {
    height: 40px;
    width: 100px;
}

.DownloadMarginReportContainer {
    margin-top: 30px;
    border: 1px solid var(--posRightBg);
    border-radius: 10px;
    padding: 20px;
}

.DownloadMarginReportContainer .DownloadReportButton {
    height: 40px;
    width: 215px;
}

/*============Marign break Down============ */

.saleSummaryListConatiner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid var(--blackPearlTwo);
    padding: 10px 10px;
}

/* ===============  Pending Payment  =============== */

.pendigPaymentListConatiner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 10px;
    border-bottom: var(--blackPearl) solid 1px;
}

.listLeftBox {
    width: 50%;
}

.listRightBox {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.listRightBox .listlebal {
    text-align: center;
    background-color: var(--extralightYellow);
    height: 21px;
    width: 70px;
    border: 1px solid #f2bc1d;
    border-radius: 6px;
}

/* ===============  Pending Payment  =============== */

/* ===============  Pagination Loader  =============== */

.paginationLoader {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 20px 0px;
}

/* ===============  Pagination Loader  =============== */

/* ==============Location tile============== */
.locationTileContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: var(--white);
    border: 1px solid var(--blackPearl);
    border-radius: 5px;
    padding: 5px 10px;
}

/* ==============Location tile============== */
/* =============Stepper component============== */

.stepperConatiner {
    margin: 10px 0px;
}

.stepperConatiner .stepIcon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.MuiStepLabel-root.MuiStepLabel-vertical {
    padding: 3px 0px !important;
}

.MuiStepConnector-line.MuiStepConnector-lineVertical {
    min-height: 15px !important;
}

.stepperConatiner .MuiStepConnector-root.MuiStepConnector-vertical {
    margin-left: 8px !important;
}

.MuiStepConnector-line.MuiStepConnector-lineVertical {
    border-color: var(--seperatorTwo) !important;
}

.stepperConatiner .label {
    font-family: var(--outfitFont) !important;
}

.stepperConatiner .label p {
    width: 170px;
}

.transactionBreaKDownConatiner {}

/* =============Stepper component============== */

/* ==============Trasnsaction Details============== */

.transactiontInnerBox {
    margin-top: 20px;
    background: var(--blackBg);
    border-radius: 10px 10px 0px 0px;
    padding: 10px 10px;
    width: 100%;
}

.breakdowntotalBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: var(--blueGray);
    min-height: 60px;
    width: 100%;
    padding: 20px 30px;
    border-radius: 0px 0px 10px 10px;
}

.transactionIconConatiner {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.transactionIconConatiner img {
    margin-right: 10px;
}

.textwithLebal {}

.textwithLebal> :nth-child(2) {
    width: 110px;
    margin-left: 5px;
}

/* Beneficiary Modal */

.beneficiaryModal input[type='checkbox'] {
    margin-bottom: 8px;
}

/* Beneficiary Modal */

/* Payout */
.addMoreBeneficiaries {
    width: 100%;
    background: var(--blackPearl);
    border-radius: 10px;
    padding: 15px 20px;
    border: 1px solid var(--posRightBg);
    display: flex;
    vertical-align: center;
    gap: 10px;
    cursor: pointer;
}

/* Payout */

/* Review Payments */
.reviewPaymentCardWrapper {
    padding: 15px 15px;
    background: var(--blackPearl);
    border: 1px solid var(--posRightBg);
    border-radius: 10px;
}

/* Review Payments */

/* Payout Receipt */
.payoutReceiptWrapper {
    background: var(--white);
    border-radius: 30px;
    padding: 50px 40px;
}

.payeeDeatilsCard {
    border: 1px solid var(--lightGray);
    border-radius: 10px;
    padding: 15px 15px;
    background: var(--white);
}

/* payout Receipt */

/* Activate Wallet */
.activateWalletWrapper {
    background: var(--blackBg);
    border-radius: 10px;
    padding: 40px 40px;
}

.walletCard {
    padding: 20px 20px;
    background: var(--topBarBG);
    border: 1px solid #2f3651;
    border-radius: 10px;
}

.activateWalletRightSection {
    background: var(--topBarBG);
    border-radius: 10px;
    border: 1px solid var(--blackPearlTwo);
    padding: 30px 30px;
}

/* Activate Wallet */
/* ==============Trasnsaction ============== */
.transactionList {}

.transactionList .allScreenNonIdealBox {
    background-color: var(--blackBg) !important;
}

/* ==============Trasnsaction ============== */

/* ==============Activity List ============== */
.walletactivitylist {}

.walletactivitylist .allScreenNonIdealBox {
    background-color: var(--blackBg) !important;
}

.activityConatiner .allScreenNonIdealBox {
    padding: 0px;
}

/* ==============Activity List ============== */

/* ============== Settings ================= */

.settingsWrapper {
    padding-bottom: 10px;
    overflow: auto;
    border-radius: 10px;
    scrollbar-width: 5px;
    scrollbar-color: var(--darkGrayTwo) var(--blackBg);
}

/*! Other Browser */
.settingsWrapper {
    --scrollbarBG: var(--blackBg);
    --thumbBG: var(--darkGrayTwo);
}

.settingsWrapper::-webkit-scrollbar {
    width: 5px;
}

.settingsWrapper::-webkit-scrollbar-track {
    background: var(--blackBg);
}

.settingsWrapper::-webkit-scrollbar-thumb {
    background-color: var(--blackBg);
    border-radius: 6px;
    border: 3px solid var(--darkGrayTwo);
}

.changeEmailBtn {
    background-color: var(--whitishGray) !important;
    border: 1px solid var(--whitishGray) !important;
    color: #2b343e !important;
    height: 0px !important;
    padding: 15px 10px;
    border-radius: 4px !important;
}

.settingsChip .textWithTagInner {
    min-width: max-content;
    padding: 0px 6px !important;
}

.aboutBusiness {
    width: 100%;
    background: var(--midnightBlue);
    border: 1px solid var(--blackPearlTwo);
    padding: 20px 20px;
    border-radius: 8px;
    margin-top: 20px;
}

.loyaltyConversionCard {
    width: 550px;
    background: var(--midnightBlue);
    border: 1px solid var(--blackPearlTwo);
    padding: 20px 20px 10px 20px;
    border-radius: 10px;
    margin-top: 20px;
}

.receiptWrapper {
    background: var(--topBarBG);
    border-radius: 10px;
    padding: 20px 20px;
    margin: 20px 20px 0px 20px;
    height: min-content;
}

.printReceiptBtn {
    background-color: var(--brightGreen) !important;
    border: 1px solid var(--brightGreen) !important;
    height: 0px !important;
    padding: 15px 20px;
    border-radius: 5px !important;
}

.printReceiptOrderBox {
    border: 1px dashed var(--lightGray);
    height: 30px;
    margin-top: 15px;
    border-radius: 5px;
    width: 100%;
    padding-top: 10px;
}

.printReceiptBoxHeader {
    width: 100px;
    position: relative;
    left: 35%;
    top: 20px;
    background-color: var(--white);
}

.imageWrapper {
    padding: 6px 10px;
    background: var(--midnightBlue);
    border: 1px solid var(--blackPearlTwo);
    border-radius: 5px;
}

.addLogoBtn {
    background-color: var(--midnightBlue) !important;
    border: 1px solid var(--barclaysBlue) !important;
    height: 0px !important;
    padding: 15px 20px;
    border-radius: 8px !important;
}

.saveBtn {
    background-color: var(--brightGreen) !important;
    border: 1px solid var(--brightGreen) !important;
    height: 0px !important;
    padding: 15px 15px;
    border-radius: 5px !important;
}

.cancelBtn {
    background-color: var(--midnightBlue) !important;
    border: 1px solid var(--darkSlateBlue) !important;
    height: 0px !important;
    padding: 15px 15px;
    border-radius: 5px !important;
}

.setupIntegrationBtn {
    background-color: var(--whitishGray) !important;
    border: 1px solid var(--whitishGray) !important;
    color: #2b343e !important;
    height: 0px !important;
    padding: 15px 15px;
    border-radius: 4px !important;
}

.integrationsModalWrapper {
    width: 100% !important;
    border: 1px solid var(--blackPearlTwo);
    background: var(--blackPearl);
    border-radius: 10px;
    padding: 20px 20px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.integrationsPopover {
    width: 160px;
    height: 160px;
    border: 1px solid var(--blackPearlTwo);
    background: var(--blackPearl);
    border-radius: 10px;
    padding: 0px 10px;
}

.resetBtn {
    background-color: var(--whitishGray) !important;
    border: 1px solid var(--red) !important;
    color: var(--red) !important;
    height: 0px !important;
    padding: 15px 10px;
    border-radius: 6px !important;
    width: 100% !important;
    margin-top: 5px;
}

/* ============== Settings ================= */

/* ================= Refund Flow ================ */

.restockWrapper .checkBoxMainBox {
    margin-top: 20px;
}

/* ================= Refund Flow ================ */

/* ============== Verified email ================= */

.verifiedEmailWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    flex-direction: column;
}

/* ============== Verified email ================= */

/* ====== TOAST BOX =========== */
.Toastify__toast-theme--light {
    color: var(--white) !important;
    background: var(--plainBlack) !important;
    width: auto !important;
}

.Toastify__toast {
    margin-bottom: 0.5rem !important;
    font-family: var(--outfitFont) !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    border-radius: 5px !important;
    min-height: 50px !important;
}

.Toastify__toast-container {
    min-width: 320px !important ;
    width: auto !important;
}

.Toastify__close-button--light {
    color: var(--grayBgTwo) !important;
    opacity: 1 !important;
}

.Toastify__progress-bar--success {
    background-color: var(--barclaysBlue) !important;
}

.Toastify__progress-bar--info {
    background-color: var(--barclaysBlue) !important;
}

.Toastify__progress-bar--warning {
    background-color: var(--brightOrange) !important;
}

.Toastify__progress-bar--error {
    background-color: var(--red) !important;
}

.navBarbtn {
    height: 22px !important;
    width: 100px !important;
    border-radius: 5px !important;
}

/* ====== TOAST BOX =========== */

/* ====== Z Report =========== */

.reportDetailsBox {
    min-height: 430px;
    width: 100%;
    border: 1px solid var(--blackPearlTwo);
    border-radius: 15px;
    padding: 20px 40px;
    margin: 20px 0px;
    background-color: var(--blackBg);
}

.zreportContainer {
    overflow: auto !important;
    border-top: 1px solid var(--blackPearlTwo);
}

.zreportContainer::-webkit-scrollbar {
    width: 5px;
}

.zreportContainer::-webkit-scrollbar-track {
    background: var(--blackBg);
}

.zreportContainer::-webkit-scrollbar-thumb {
    background-color: var(--blackBg);
    border-radius: 6px;
    border: 3px solid var(--darkGrayTwo);
}

.repotDetails {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid var(--blackPearl);
    padding: 15px 0px;
}

.zReceicpetBox {
    border: 1px dashed var(--blackBg);
    height: 30px;
    margin-top: 15px;
    border-radius: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
}

.zReceicpetBoxUserName {
    width: 100px;
    position: relative;
    left: 32%;
    top: 25px;
    background-color: var(--white);
}

/* ====== Z Report =========== */

/* Dashboard Revamp */
.keyMetricMainConatiner {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

/* Dashboard Revamp */


/* ====== Price chacker =========== */


.shell-logo{
    position: absolute;
    top: 15px;
    bottom: 0px;
}

.refresh-logo{
    position: absolute;
    top: 15px;
    right: 15px;
}


.container-price-checker {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.container-price-checker > h2{
    font-size: 24px;
    font-family: var(--outfitFont);
    font-weight: 500;
    padding-bottom: 5px;


  }
  .container-price-checker > p{
    font-size: 16px;
    font-family: var(--outfitFont);
    color: #7D7D7D;
    font-weight: 400;
    padding-bottom: 25px;

  }

/* ====== Price chacker =========== */



/* ====== Custom inputfield =========== */


.custom-textfield {
    display: flex;
    flex-direction: column;
    width: 35%;
    position: relative;
    margin-bottom: 16px;
}

.input-container {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
}

.custom-input {
    width: 100%;
    height: 150%;
    padding: 15px 20px;
    border: 1px solid #ccc;
    border-radius: 12px;
    font-size: 16px;
    outline: none;
    transition: border-color 0.3s ease-in-out;
    background-color: #fff;
    color: #414042;
}

.custom-input:focus {
    border-color: #0094ff;
    box-shadow: 0 0 5px rgba(0, 148, 255, 0.5);
}

/* Floating Label */
.floating-label {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
    color: #888;
    transition: all 0.3s ease-in-out;
    background: white;
    padding: 0 5px;
    pointer-events: none;
}

/* Move label when focused or has value */
.focused .floating-label {
    top: 5px;
    font-size: 12px;
    color: #0094ff;
}

/* Error state */
.error .custom-input {
    border-color: red;
}

.error .floating-label {
    color: red;
}

.helper-text {
    font-size: 12px;
    color: red;
    margin-top: 4px;
}
/* ====== Custom inputfield =========== */


/* ====== Price Chacker =========== */
.card-price-chacker {
    width: 100%;
    height: 100vh;
    background-color: var(--white)
  }


h2 {
  font-size: 22px;
  font-weight: bold;
}

p {
  font-size: 14px;
  color: #7D7D7D;
  margin-bottom: 20px;
}

.container-price-checker > button {
  background: #32cd32;
  color: var(--white);
  padding: 15px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  width: 11%;
  margin-top: 20px;
}

.scanner-footer {
    width: 100%;
    text-align: center;
    color:var(--textColor);
    padding: 10px 0;
    position: absolute;
    bottom: 20px;
  }

 /* ====== Price Chacker =========== */


/* ============== Barcode scanner ============== */

.scanner-main {
    background-color: var(--white);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .scanner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
    text-align: center;
  }
  .logo {
    width: 120px;
    margin-bottom: 20px;
  }
  
  .scanner-container >h2 {
    font-size: 30px;
    font-weight: 500;
    font-family: var(--outfitFont);
    padding-bottom: 10px;
  }

   
  .scanner-container > p {
    font-size: 20px;
    color: gray;
    margin-bottom: 40px;
  }
  
  .scanner-box {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .scanner {
    width: 370px;

  }
  
  .product {
    position: absolute;
    bottom: -20px;
    right: -20px;
    width: 80px;
  }

  
  /*  */


/* Main container */
.price-display-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    text-align: center;
    padding: 20px;
    justify-content: center;
  }
  
  /* Product Card */
  .product-card {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background: white;
    padding: 80px;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    width: 50%;
    height: 50%;
  }
  
  /* Product Image */
  .product-image {
    width: 120px;
    margin-bottom: 10px;
  }
  
  /* Product Info */
  .product-info  {
    text-align: start;
  }
  
  .product-info h3 {
    font-size: 28px;
    font-weight: 500;
    margin: 5px 0;
  }
  
  .product-info h2 {
    font-size: 45px;
    font-weight: bold;
    margin: 5px 0;
  }
  .product-info-without-img{
    text-align: start;
  }
  .product-info-without-img h3 {
    font-size: 40px;
    font-weight: 500;
    margin: 5px 0;
  }
  
  .product-info-without-img h2 {
    font-size: 80px;
    font-weight: bold;
    margin: 5px 0;
    text-align: center;
    padding-top: 10px;
  }
  
  /* Scan Button */
 .scan-barcode-div {
    display: flex;
     align-items: center;
     justify-content: center;
    font-size: 16px;
    color: var(--textColor);
    width: 50%;
    margin-top: 25px;
  }
  .scan-barcode-div > p {
    padding-left: 20px;
    color: #4AC600;
    font-size: 26px;
    font-family: var(--outfitFont);
    padding-top: 25px;
  }
  
  .scan-barcode-text:hover {
    background-color: #218838;
  }
  
  /* ============== Barcode scanner ============== */
