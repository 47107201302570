.generalReportTable {
    width: 100%;
    overflow-x: auto;
}

.generalReportTable table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Table Header */
.generalReportTable thead {
    width: 100%;
    border-bottom: 1px solid rgb(47, 54, 81);
}

.generalReportTable th {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 18px 10px;
    text-align: left;
    font-weight: 400;
    /* border-bottom: 2px solid #ddd; */
    width: 180px;
}

/* Table Body */
.generalReportTable tbody {
    width: 100%;
}
.generalReportTable tr {
    display: flex;
    width: 100%;
    border-bottom: 1px solid rgb(47, 54, 81);
}
/* Table Cells */

.generalReportTable td {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 18px 10px;
    text-align: left;
    font-weight: 400;
    width: 180px;
}
.generalReportTable td p {
    text-align: center;
    width: 100%;
}
.generalReportTable th p {
    text-align: center;
    width: 100%;
}

.generalReportTable table {
    width: 100%;
}
.generalReportTable .infinite-scroll-component__outerdiv {
    width: 100%;
}
.generalReportTable .infiniteScrollBar {
    width: 100%;
}
.generalReportTable .infiniteScrollBar > div {
    width: 100%;
}
.generalReportTable .tr {
    width: 100%;
}
